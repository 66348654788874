import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import GlobalStyles from './styles';
import { ROUTE_TYPES } from './constants';
import { authData, generateRoutes } from './utils';

const App = () => {
  const routesList = generateRoutes();
  const { ALLOW_IF_LOGGEDIN, ALLOW_IF_NOT_LOGGEDIN } = ROUTE_TYPES;
  const { isLoggedIn, accountsPermissions } = authData.get();
  const [appData, setAppData] = useState({
    appName: window.location.href.includes('remitsarathi') || window.location.href.includes('localhost') ? 'RemitSarathi' : 'API Exchange',
    appLogo: window.location.href.includes('remitsarathi') || window.location.href.includes('localhost') ? "/remitsarathi.png" : '/logo.png',
    appFavicon: '',
  });
  const theme = {
    colors: {
      backgroundColor: appData.appBackgroundColor
        ? appData.appBackgroundColor
        : '#ffffff',
      primaryColor: appData.appPrimaryColor
        ? appData.appPrimaryColor
        : '#0095ff',
      secondaryColor: appData.appSecondaryColor
        ? appData.appSecondaryColor
        : '#0078ce',
      tertiaryColor: appData.appTertiaryColor
        ? appData.appTertiaryColor
        : '#0067b0',
      tableHeaderColor: appData.appTableHeaderColor
        ? appData.appTableHeaderColor
        : '#545454',
    },
  };

  const ProcessedRoute = (props) => {
    const {
      component: Component,
      routeType,
      moduleName,
      permissionRequired,
      appData,
      ...rest
    } = props;
    let ProcessedComponent;

    if (!isEmpty(routeType)) {
      if (isLoggedIn && accountsPermissions === null) {
        authData.clear();
        window.location.href = '/login';
      } else {
        if (
          (routeType === ALLOW_IF_NOT_LOGGEDIN && !isLoggedIn) ||
          (routeType === ALLOW_IF_LOGGEDIN && isLoggedIn)
        ) {
          if (
            isEmpty(moduleName) ||
            (!isEmpty(moduleName) &&
              !isEmpty(accountsPermissions[moduleName]) &&
              accountsPermissions[moduleName].includes(permissionRequired))
          ) {
            ProcessedComponent = (
              <Component
                accountsPermissions={
                  !isEmpty(moduleName) &&
                  accountsPermissions !== null &&
                  accountsPermissions[moduleName]
                }
                routeType={!isEmpty(routeType) && routeType}
                moduleName={!isEmpty(moduleName) && moduleName}
                permissionRequired={
                  !isEmpty(permissionRequired) && permissionRequired
                }
                appData={appData}
                {...rest}
              />
            );
          } else {
            ProcessedComponent = <Navigate to='/' />;
          }
        } else {
          ProcessedComponent = (
            <Navigate to={routeType === ALLOW_IF_LOGGEDIN ? '/login' : '/'} />
          );
        }
      }
    } else {
      ProcessedComponent = <Component {...rest} appData={appData} />;
    }

    return ProcessedComponent;
  };

  return (
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Router>
          <Routes>
            {routesList.map(
              (
                item,
                index
              ) =>
              {
                const {
                  path,
                  ...rest
                } = item;
                return path && (
                  <Route
                    path={path}
                    element={
                      <ProcessedRoute
                        appData={appData}
                        {...rest}
                      />
                    }
                    key={index}
                  />
                )
              }
                
            )}
          </Routes>
        </Router>
      </ThemeProvider>
  );
};

export default App;
