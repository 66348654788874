import React, { useState, useEffect, useCallback } from "react";
import {
  faCode,
  faEdit,
  faIndianRupeeSign,
  faRotateRight,
} from "@fortawesome/free-solid-svg-icons";
import JSONPretty from "react-json-pretty";
import { withAppHOC } from "../../hoc";
import PageHeading from "../../components/PageHeader/PageHeader";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import NoData from "../../components/NoData/NoData";
import StatusHighliter from "../../components/StatusHighliter/StatusHighliter";
import {
  MessageBox,
  CustomButton,
  Label,
  Fieldset,
  TextInput,
  Button,
  Form,
  ResetButton,
  Dropdown,
  CustomConfirmButton,
  UpdateButton,
  FormContainer,
} from "../../components/FormElements";
import { TableBuilder } from "../../components/TableElements";
import Modal from "../../components/Modal";
import {
  StyledFiltersContainer,
  StyledModalContainer,
  StyledBodyContainer,
  StyledHeading,
  StyledContent,
  StyledApiCallLogsContainer,
  StyledSpan,
} from "../../components/Styled";
import Pagination from "../../components/Pagination/Pagination";
import API from "../../api";
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from "../../constants";
import {
  getSearchParams,
  formatCurrency,
  shouldRedirect,
  redirectToPageOne,
} from "../../utils";
import FiltersButton from "../../components/FiltersButton/FiltersButton";
import Accordination from "../../components/Accordination/Accordination";
import { Grid, GridItem } from "../../components/Grid";
import moment from "moment";
import exportFromJSON from "export-from-json";
import OverlayLoader from "../../components/OverlayLoader/OverlayLoader";

const SellerRechargeTransactionsList = (props) => {
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams("pageNumber") || 1,
    recordsPerPage: getSearchParams("recordsPerPage") || 100,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [users, setUsers] = useState([]);
  const [operators, setOperators] = useState([]);
  const [usersId, setUsersId] = useState("");
  const [operatorsId, setOperatorsId] = useState("");
  const [fromDate, setFromDate] = useState(moment().format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
  const [rechargeNumber, setRechargeNumber] = useState("");
  const [transactionsId, setTransactionsId] = useState("");
  const [transactions, setTransactions] = useState([]);
  const [modalData, setModalData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [modalTransactionsId, setModalTransactionsId] = useState("");
  const [apiTransactionsId, setApiTransactionsId] = useState("");
  const [apiOperatorsId, setApiOperatorsId] = useState("");

  const readLogs = (id) => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.get(`/transactions/logs/${id}`)
      .then((response) => {
        const { status, data } = response.data;
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setModalData(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILED);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const resetModalForm = () => {
    setModalTransactionsId("");
    setApiTransactionsId("");
    setApiOperatorsId("");
  };

  const toggleModal = (id) => {
    setShowModal(true);
    setModalType("LOGS");
    readLogs(id);
  };

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus("");
    setResponseMessage("");
  };

  const resetForm = () => {
    setUsersId("");
    setOperatorsId("");
    setFromDate("");
    setToDate("");
    setRechargeNumber("");
    setTransactionsId("");
    readData();
  };

  const readUsers = () => {
    API.get("/accounts-management/users").then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setUsers(data);
      }
    });
  };

  const readOperators = () => {
    API.get("/recharge-configurations/operators").then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setOperators(data);
      }
    });
  };

  const readData = useCallback(
    (
      usersId = "",
      operatorsId = "",
      fromDate = "",
      toDate = "",
      rechargeNumber = "",
      transactionsId = ""
    ) => {
      setIsLoading(true);
      setResponseStatus("");
      setResponseMessage("");
      API.get(
        `/transactions/seller?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&transactionsId=${transactionsId}&usersId=${usersId}&operatorsId=${operatorsId}&fromDate=${fromDate}&toDate=${toDate}&rechargeNumber=${rechargeNumber}`
      )
        .then((response) => {
          const { status, data, pageInfo } = response.data;
          if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
            redirectToPageOne();
          } else {
            if (status === API_RESPONSE_TYPES.SUCCESS) {
              setTotalRecords(pageInfo.totalRecords);
            }
            setTransactions(data);
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILED);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [paginationData]
  );

  const refundTransaction = (id) => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.put(`/transactions/refund/${id}`)
      .then((response) => {
        const { status, message } = response.data;
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          reloadData();
        } else {
          setResponseStatus(status);
          setResponseMessage(message);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
        setTimeout(() => {
          resetResponseData();
        }, 3000);
      });
  };

  const updateTransaction = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.put(`/transactions/update/${modalTransactionsId}`, {
      apiTransactionsId,
      apiOperatorsId,
    })
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          reloadData();
          setTimeout(() => {
            setShowModal(false);
            setModalTransactionsId("");
            resetResponseData();
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const reloadTransaction = (id) => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.get(`/transactions/reload/${id}`)
      .then((response) => {
        const { status, message } = response.data;
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          reloadData();
        } else {
          setResponseStatus(status);
          setResponseMessage(message);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
        setTimeout(() => {
          resetResponseData();
        }, 3000);
      });
  };

  const onClickExport = () => {
    exportFromJSON({
      data: transactions,
      fileName: "seller-transactions",
      exportType: exportFromJSON.types.csv,
    });
  };

  const reloadData = () => {
    readData(
      usersId,
      operatorsId,
      fromDate,
      toDate,
      rechargeNumber,
      transactionsId
    );
  };

  const filterData = (e) => {
    e.preventDefault();
    setIsFiltering(true);
  };

  useEffect(() => {
    if (isFiltering) {
      setPaginationData((paginationData) => ({
        pageNumber: 1,
        recordsPerPage: paginationData.recordsPerPage,
      }));
      setIsFiltering((isFiltering) => !isFiltering);
    }
  }, [isFiltering]);

  useEffect(() => {
    readUsers();
    readOperators();
  }, []);

  useEffect(() => {
    if (!isFiltering) {
      readData(
        usersId,
        operatorsId,
        fromDate,
        toDate,
        rechargeNumber,
        transactionsId
      );
    }
  }, [paginationData, readData]);

  const { accountsPermissions } = props;
  const canTrack =
    accountsPermissions.includes(PERMISSION_TYPES.TRACK) || false;
  const canUpdate =
    accountsPermissions.includes(PERMISSION_TYPES.UPDATE) || false;
  const canReload =
    accountsPermissions.includes(PERMISSION_TYPES.RELOAD) || false;
  const canRefund =
    accountsPermissions.includes(PERMISSION_TYPES.REFUND) || false;

  const userOptions = [{ title: "-- SELECT USER --", value: "" }];
  if (users && users.length > 0) {
    users.forEach(({ id, fullName, mobileNumber }) =>
      userOptions.push({
        title: `${fullName} ( ${mobileNumber} )`,
        value: id,
      })
    );
  }

  const operatorOptions = [{ title: "-- SELECT OPERATOR --", value: "" }];
  if (operators && operators.length > 0) {
    operators.forEach(({ id, operatorName }) =>
      operatorOptions.push({
        title: operatorName,
        value: id,
      })
    );
  }

  const [showFilters, setShowFilters] = useState(false);
  const onClickShowFilters = () => {
    setShowFilters((showFilters) => !showFilters);
  };

  return (
    <>
      <PageHeading
        {...props}
        reloadData={reloadData}
        showExport={transactions.length > 0}
        onClickExport={onClickExport}
      />
      <FiltersButton onClick={onClickShowFilters} value={showFilters} />
      <StyledFiltersContainer className={showFilters ? "show" : ""}>
        <Form method="POST" action="#" onSubmit={filterData}>
          <Fieldset>
            <Label>User</Label>
            <Dropdown
              placeholder="User"
              value={usersId}
              onChange={setUsersId}
              options={userOptions}
              disabled={isLoading}
              searchable
            />
          </Fieldset>
          <Fieldset>
            <Label>Operator</Label>
            <Dropdown
              placeholder="Operator"
              value={operatorsId}
              onChange={setOperatorsId}
              options={operatorOptions}
              disabled={isLoading}
              searchable
            />
          </Fieldset>
          <Fieldset>
            <Label>From Date</Label>
            <TextInput
              type="date"
              value={fromDate}
              onChange={setFromDate}
              placeholder="From Date"
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>To Date</Label>
            <TextInput
              type="date"
              value={toDate}
              onChange={setToDate}
              placeholder="To Date"
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Recharge Number</Label>
            <TextInput
              value={rechargeNumber}
              onChange={setRechargeNumber}
              placeholder="Recharge Number"
              maxLength={100}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Transactions Id</Label>
            <TextInput
              value={transactionsId}
              onChange={setTransactionsId}
              placeholder="Transactions Id"
              maxLength={100}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Button disabled={isLoading} type="submit">
              Filter
            </Button>
            <ResetButton disabled={isLoading} type="button" onClick={resetForm}>
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
      </StyledFiltersContainer>
      <LoadingSpinner
        isLoading={
          !showModal &&
          responseStatus === "" &&
          transactions?.length === 0 &&
          isLoading
        }
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILED &&
          !isLoading &&
          transactions?.length === 0
        }
        message={`No transactions found`}
      />
      {!showModal && (
        <MessageBox status={responseStatus} message={responseMessage} />
      )}
      {transactions?.length > 0 && (
        <>
          <TableBuilder
            isLoading={!showModal && transactions?.length !== 0 && isLoading}
            tableHeadings={[
              {
                title: "",
                dataSelector: "transactionsId",
                sticky: true,
                canSort: false,
                width: "100px",
                CellRenderer: (value, item) => (
                  <>
                    {canTrack && (
                      <>
                        <CustomButton
                          icon={faCode}
                          onClick={() => toggleModal(value)}
                          style={{ color: "orange" }}
                        />
                      </>
                    )}
                    {canUpdate &&
                      (item.rechargeStatus === "Success" ||
                        item.rechargeStatus === "Pending") && (
                        <CustomButton
                          icon={faEdit}
                          onClick={() => {
                            setModalTransactionsId(value);
                            setApiOperatorsId(item.rechargeOperatorsId);
                            setApiTransactionsId(item.sellersApiTransactionsId);
                            setModalType("UPDATE");
                            setShowModal(true);
                          }}
                          style={{ color: "#0095ff" }}
                        />
                      )}
                    {canReload && item.rechargeStatus !== "Failure" && (
                      <CustomButton
                        icon={faRotateRight}
                        onClick={() => reloadTransaction(value)}
                        style={{ color: "#333f7a" }}
                      />
                    )}
                    {canRefund &&
                      (item.rechargeStatus === "Success" ||
                        item.rechargeStatus === "Pending") && (
                        <CustomConfirmButton
                          icon={faIndianRupeeSign}
                          title="Refund"
                          message="Are you sure, you want to refund this transaction ?"
                          proceedButtonText="Refund"
                          cancelButtonText="Cancel"
                          onClick={() => refundTransaction(value)}
                          color="forestgreen"
                          hoverColor="#006200"
                        />
                      )}
                  </>
                ),
              },
              {
                title: "Transaction Id",
                dataSelector: "transactionsId",
                dataType: "string",
              },
              {
                title: "Transaction Time",
                dataSelector: "transactionTime",
                dataType: "string",
              },
              {
                title: "Seller",
                dataSelector: "userName",
                dataType: "string",
              },
              {
                title: "API",
                dataSelector: "apiName",
                dataType: "string",
              },
              {
                title: "Operator",
                dataSelector: "operatorName",
                dataType: "string",
              },
              {
                title: "Recharge Number",
                dataSelector: "rechargeNumber",
                dataType: "string",
              },
              {
                title: "Amount",
                dataSelector: "rechargeAmount",
                dataType: "number",
                CellRenderer: (value) => formatCurrency(value),
              },
              {
                title: "Operators Id",
                dataSelector: "rechargeOperatorsId",
                dataType: "string",
                CellRenderer: (value) => value || "N/A",
              },
              {
                title: "Seller Status",
                dataSelector: "sellersStatus",
                dataType: "string",
                align: "center",
                CellRenderer: (value) => (
                  <StatusHighliter
                    className={
                      value === "Success"
                        ? "green"
                        : value === "Failed"
                        ? "red"
                        : "yellow"
                    }
                  >
                    {value}
                  </StatusHighliter>
                ),
              },
              {
                title: "Final Status",
                dataSelector: "rechargeStatus",
                dataType: "string",
                align: "center",
                CellRenderer: (value) => (
                  <StatusHighliter
                    className={
                      value === "Success"
                        ? "green"
                        : value === "Failed"
                        ? "red"
                        : "yellow"
                    }
                  >
                    {value}
                  </StatusHighliter>
                ),
              },
            ]}
            tableData={transactions}
          />
          <Pagination
            totalRecords={totalRecords}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
          />
          <Modal
            modalStatus={showModal}
            setModalStatus={() => {
              resetResponseData();
              setShowModal(false);
              setModalData([]);
            }}
            hideCloseButton
          >
            <StyledModalContainer width={`1200px`}>
              {modalType === "UPDATE" ? (
                <>
                  <h3>Update Transaction</h3>
                  <FormContainer>
                  <Fieldset>
                      <StyledSpan style={{ fontSize: "16px", color: "red" }}>
                        Note: Upon update transaction status will be marked as
                        Success
                      </StyledSpan>
                    </Fieldset>
                    <Form method="POST" action="#" onSubmit={updateTransaction}>
                      <Fieldset>
                        <Label required>API Transactions ID</Label>
                        <TextInput
                          value={apiTransactionsId}
                          onChange={setApiTransactionsId}
                          placeholder="Please enter api transactions id"
                          disabled={isLoading === true}
                        />
                      </Fieldset>
                      <Fieldset>
                        <Label required>API Operators ID</Label>
                        <TextInput
                          value={apiOperatorsId}
                          onChange={setApiOperatorsId}
                          placeholder="Please enter api operators id"
                          disabled={isLoading === true}
                        />
                      </Fieldset>
                      {showModal && responseStatus !== "" && (
                        <Fieldset>
                          <MessageBox
                            status={responseStatus}
                            message={responseMessage}
                          />
                        </Fieldset>
                      )}
                      <Fieldset>
                        <UpdateButton
                          disabled={isLoading === true}
                          type="submit"
                          style={{ width: "auto" }}
                        >
                          Update
                        </UpdateButton>
                        <ResetButton
                          disabled={isLoading === true}
                          type="button"
                          onClick={resetModalForm}
                        >
                          Reset
                        </ResetButton>
                      </Fieldset>
                      <OverlayLoader showLoader={showModal && isLoading} />
                    </Form>
                  </FormContainer>
                </>
              ) : (
                <>
                  <h3>API Call Logs</h3>
                  <StyledApiCallLogsContainer>
                    <LoadingSpinner
                      isLoading={
                        responseStatus === "" &&
                        modalData?.length === 0 &&
                        isLoading
                      }
                    />
                    <NoData
                      status={
                        responseStatus !== API_RESPONSE_TYPES.FAILED &&
                        !isLoading &&
                        modalData?.length === 0
                      }
                      message={`No api logs found`}
                    />
                    <MessageBox
                      status={responseStatus}
                      message={responseMessage}
                    />
                    {modalData?.length > 0 &&
                      modalData.map(
                        (
                          {
                            requestType,
                            request,
                            requestOn,
                            requestBody,
                            response,
                            responseOn,
                          },
                          index
                        ) => (
                          <Accordination
                            key={index}
                            header={`${requestType}`}
                            autoExpanded={true}
                          >
                            <Grid columns={"calc(50% - 10px) calc(50% - 10px)"}>
                              <GridItem>
                                <StyledBodyContainer>
                                  <StyledHeading>
                                    API Request{" "}
                                    {requestOn && `( ${requestOn} )`}
                                  </StyledHeading>
                                  <StyledContent>{request}</StyledContent>
                                </StyledBodyContainer>
                                {requestBody && (
                                  <StyledBodyContainer>
                                    <StyledHeading>
                                      API Request Body
                                    </StyledHeading>
                                    <StyledContent>{requestBody}</StyledContent>
                                  </StyledBodyContainer>
                                )}
                              </GridItem>
                              <GridItem>
                                <StyledBodyContainer>
                                  <StyledHeading>
                                    API Response{" "}
                                    {responseOn && `( ${responseOn} )`}
                                  </StyledHeading>
                                  <StyledContent>
                                    <JSONPretty
                                      id="json-pretty"
                                      data={response}
                                    ></JSONPretty>
                                  </StyledContent>
                                </StyledBodyContainer>
                              </GridItem>
                            </Grid>
                          </Accordination>
                        )
                      )}
                  </StyledApiCallLogsContainer>
                </>
              )}
            </StyledModalContainer>
          </Modal>
        </>
      )}
    </>
  );
};

export default withAppHOC(SellerRechargeTransactionsList);
