import React, { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk, faEdit } from "@fortawesome/free-solid-svg-icons";
import { withAppHOC } from "../../../hoc";
import PageHeading from "../../../components/PageHeader/PageHeader";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import NoData from "../../../components/NoData/NoData";
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  ResetButton,
  MessageBox,
  Dropdown,
  Switch,
  CustomButton,
  Button,
  CustomConfirmButton,
} from "../../../components/FormElements";
import { TableBuilder } from "../../../components/TableElements";
import Modal from "../../../components/Modal";
import OverlayLoader from "../../../components/OverlayLoader/OverlayLoader";
import {
  StyledLink,
  StyledModalContainer,
  StyledFiltersContainer,
  StyledOTPModal,
  StyledOTPText,
} from "../../../components/Styled";
import Pagination from "../../../components/Pagination/Pagination";
import API from "../../../api";
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from "../../../constants";
import {
  getSearchParams,
  shouldRedirect,
  redirectToPageOne,
} from "../../../utils";
import FiltersButton from "../../../components/FiltersButton/FiltersButton";
import moment from "moment";

const StaffAccountsList = (props) => {
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams("pageNumber") || 1,
    recordsPerPage: getSearchParams("recordsPerPage") || 30,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [accounts, setAccounts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [fMobileNumber, setFmobileNumber] = useState("");
  const [fName, setFname] = useState("");
  const [fAccountStatus, setFaccountStatus] = useState("");
  const [accountsLatestOtp, setAccountsLatestOtp] = useState("");

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus("");
    setResponseMessage("");
  };

  const resetFilters = () => {
    setFmobileNumber("");
    setFname("");
    setFaccountStatus("");
    readData();
  };

  const readData = useCallback(
    (fMobileNumber = "", fName = "", fAccountStatus = "") => {
      setIsLoading(true);
      setResponseStatus("");
      setResponseMessage("");
      setAccounts([]);
      API.get(
        `/accounts-management/accounts?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&fMobileNumber=${fMobileNumber}&fName=${fName}&fAccountStatus=${fAccountStatus}`
      )
        .then((response) => {
          const { status, message, data, pageInfo } = response.data;
          if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
            redirectToPageOne();
          } else {
            if (status === API_RESPONSE_TYPES.FAILED) {
              setResponseStatus(status);
              setResponseMessage(message);
            } else {
              setTotalRecords(pageInfo.totalRecords);
            }
            setAccounts(data);
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILED);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [paginationData]
  );

  const reloadData = () => {
    readData(fMobileNumber, fName, fAccountStatus);
  };

  const readAccountsLatestOTP = (accountsId) => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.get(`/accounts-management/accounts/last-otp/${accountsId}`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILED) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          setAccountsLatestOtp(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILED);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateData = (value, data, key) => {
    const newData = { ...data };
    newData[key] = value === true ? `Active` : `Inactive`;
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.put(
      `/accounts-management/accounts/${newData.id}?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&fMobileNumber=${fMobileNumber}&fName=${fName}&fAccountStatus=${fAccountStatus}`,
      newData
    )
      .then((response) => {
        const { status, message, data } = response.data;
        if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
          redirectToPageOne();
        } else {
          if (status === API_RESPONSE_TYPES.FAILED) {
            setResponseStatus(status);
            setResponseMessage(message);
          } else {
            setAccounts(data);
          }
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILED);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteData = (accountsId) => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.delete(
      `/accounts-management/accounts/${accountsId}?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&fMobileNumber=${fMobileNumber}&fName=${fName}&fAccountStatus=${fAccountStatus}`
    )
      .then((response) => {
        const { status, message, data } = response.data;
        if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
          redirectToPageOne();
        } else {
          if (status === API_RESPONSE_TYPES.FAILED) {
            setResponseStatus(status);
            setResponseMessage(message);
          }
          setAccounts(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILED);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const filterData = (e) => {
    e.preventDefault();
    setIsFiltering(true);
  };

  useEffect(() => {
    if (isFiltering) {
      setPaginationData((paginationData) => ({
        pageNumber: 1,
        recordsPerPage: paginationData.recordsPerPage,
      }));
      setIsFiltering((isFiltering) => !isFiltering);
    }
  }, [isFiltering]);

  useEffect(() => {
    if (!isFiltering) {
      readData(fMobileNumber, fName, fAccountStatus);
    }
  }, [paginationData, readData]);

  const { accountsPermissions, subRoutes } = props;

  const canUpdate =
    accountsPermissions.includes(PERMISSION_TYPES.UPDATE) || false;
  const canReadLatestOTP =
    accountsPermissions.includes(PERMISSION_TYPES.READ_LATEST_OTP) || false;
  const canDelete =
    accountsPermissions.includes(PERMISSION_TYPES.DELETE) || false;

  let updateLink = "";
  let updateTitle = "";
  if (canUpdate) {
    const { title, path } = subRoutes.find(
      ({ permissionRequired }) => permissionRequired === PERMISSION_TYPES.UPDATE
    );
    updateLink = path;
    updateTitle = title;
  }

  const accountStatusOptions = [
    { title: "-- SELECT ACCOUNT STATUS --", value: "" },
    { title: "Active", value: "Active" },
    { title: "Inactive", value: "Inactive" },
  ];

  const [showFilters, setShowFilters] = useState(false);
  const onClickShowFilters = () => {
    setShowFilters((showFilters) => !showFilters);
  };

  return (
    <>
      <PageHeading {...props} reloadData={reloadData} />
      <FiltersButton onClick={onClickShowFilters} value={showFilters} />
      <StyledFiltersContainer className={showFilters ? "show" : ""}>
        <Form method="POST" action="#" onSubmit={filterData}>
          <Fieldset>
            <Label>Mobile Number</Label>
            <TextInput
              value={fMobileNumber}
              onChange={setFmobileNumber}
              placeholder="Mobile Number"
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Name</Label>
            <TextInput
              value={fName}
              onChange={setFname}
              placeholder="First Name, Last Name"
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Account Status</Label>
            <Dropdown
              style={{ border: "1px solid #cacaca" }}
              placeholder="Account Status"
              value={fAccountStatus}
              onChange={setFaccountStatus}
              options={accountStatusOptions}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Button disabled={isLoading} type="submit">
              Filter
            </Button>
            <ResetButton
              disabled={isLoading}
              type="button"
              onClick={resetFilters}
            >
              Clear
            </ResetButton>
          </Fieldset>
        </Form>
      </StyledFiltersContainer>
      <LoadingSpinner
        isLoading={
          responseStatus === "" &&
          !showModal &&
          accounts?.length === 0 &&
          isLoading
        }
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILED &&
          !isLoading &&
          accounts?.length === 0
        }
        message={`No accounts found`}
      />
      {!showModal && responseStatus === API_RESPONSE_TYPES.FAILED && (
        <MessageBox status={responseStatus} message={responseMessage} />
      )}
      {accounts?.length > 0 && (
        <>
          <TableBuilder
            isLoading={!showModal && accounts?.length !== 0 && isLoading}
            tableHeadings={[
              {
                title: "",
                dataSelector: "id",
                align: "center",
                sticky: true,
                canSort: false,
                width: "100px",
                CellRenderer: (value, item) => (
                  <>
                    {canUpdate && (
                      <StyledLink
                        to={updateLink.replace(":id", value)}
                        title={updateTitle}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </StyledLink>
                    )}
                    {canDelete && (
                      <CustomConfirmButton onClick={() => deleteData(value)} />
                    )}
                    {canReadLatestOTP && (
                      <CustomButton
                        icon={faAsterisk}
                        onClick={() => {
                          readAccountsLatestOTP(value);
                          setShowModal(true);
                        }}
                        style={{ color: "gray" }}
                      />
                    )}
                  </>
                ),
              },
              {
                title: "First Name",
                dataSelector: "firstName",
                dataType: "string",
              },
              {
                title: "Last Name",
                dataSelector: "lastName",
                dataType: "string",
              },
              {
                title: "Role",
                dataSelector: "roleName",
                dataType: "string",
              },
              {
                title: "Mobile Number",
                dataSelector: "mobileNumber",
                dataType: "string",
              },
              {
                title: "Status",
                dataSelector: "accountsStatus",
                dataType: "string",
                align: "center",
                canSort: false,
                CellRenderer: (value, data) => (
                  <Switch
                    value={value === "Active"}
                    onChange={(value) =>
                      updateData(value, data, "accountsStatus")
                    }
                  />
                ),
              },
              {
                title: "Created Date",
                dataSelector: "createdOn",
                dataType: "date",
                align: "center",
              },
            ]}
            tableData={accounts}
          />
          <Pagination
            totalRecords={totalRecords}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
          />
          <Modal
            modalStatus={showModal}
            setModalStatus={() => {
              resetResponseData();
              setShowModal(false);
            }}
            hideCloseButton
          >
            <StyledModalContainer width={"500px"}>
              <h3>Accounts Latest OTP</h3>
              <FormContainer>
                <StyledOTPModal>
                  {isLoading ? (
                    <StyledOTPText>
                      Please wait, we are feting the data
                    </StyledOTPText>
                  ) : accountsLatestOtp?.length > 0 ? (
                    <StyledOTPText>OTP is {accountsLatestOtp}</StyledOTPText>
                  ) : (
                    <StyledOTPText>No OTP Found</StyledOTPText>
                  )}
                  <OverlayLoader showLoader={showModal && isLoading} />
                </StyledOTPModal>
              </FormContainer>
            </StyledModalContainer>
          </Modal>
        </>
      )}
    </>
  );
};

export default withAppHOC(StaffAccountsList);
