import React, { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { withAppHOC } from "../../../hoc";
import PageHeading from "../../../components/PageHeader/PageHeader";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import NoData from "../../../components/NoData/NoData";
import {
  Switch,
  CustomConfirmButton,
  MessageBox,
} from "../../../components/FormElements";
import { Image } from "../../../components/Image";
import { TableBuilder } from "../../../components/TableElements";
import {
  StyledLink,
  StyledIconTitleContainer,
  StyledIconContainer,
  StyledTitleContainer,
  StyledSpan,
} from "../../../components/Styled";
import Pagination from "../../../components/Pagination/Pagination";
import API from "../../../api";
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from "../../../constants";
import {
  getSearchParams,
  shouldRedirect,
  redirectToPageOne,
  formatCurrency,
} from "../../../utils";
import StatusHighliter from "../../../components/StatusHighliter/StatusHighliter";

const OperatorsList = (props) => {
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams("pageNumber") || 1,
    recordsPerPage: getSearchParams("recordsPerPage") || 10,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [operators, setOperators] = useState([]);

  const readData = useCallback(() => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    setOperators([]);
    API.get(
      `/recharge-configurations/operators/?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}`
    )
      .then((response) => {
        const { status, message, data, pageInfo } = response.data;
        if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
          redirectToPageOne();
        } else {
          setOperators(data);
          if (status === API_RESPONSE_TYPES.FAILED) {
            setResponseStatus(status);
            setResponseMessage(message);
          } else {
            setTotalRecords(pageInfo.totalRecords);
          }
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILED);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [paginationData]);

  const reloadData = () => {
    readData();
  };

  const updateData = (value, data) => {
    const newData = { ...data };
    newData.operatorStatus = value === true ? `Active` : `Inactive`;
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.put(
      `/recharge-configurations/operators/${newData.id}?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}`,
      newData
    )
      .then((response) => {
        const { status, message, data } = response.data;
        if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
          redirectToPageOne();
        } else {
          if (status === API_RESPONSE_TYPES.FAILED) {
            setResponseStatus(status);
            setResponseMessage(message);
          } else {
            setOperators(data);
          }
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILED);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteData = (id) => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.delete(
      `/recharge-configurations/operators/${id}?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}`
    )
      .then((response) => {
        const { status, message, data } = response.data;
        if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
          redirectToPageOne();
        } else {
          if (status === API_RESPONSE_TYPES.FAILED) {
            setResponseStatus(status);
            setResponseMessage(message);
          } else {
            setOperators(data);
          }
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILED);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (isFiltering) {
      setPaginationData((paginationData) => ({
        pageNumber: 1,
        recordsPerPage: paginationData.recordsPerPage,
      }));
      setIsFiltering((isFiltering) => !isFiltering);
    }
  }, [isFiltering]);

  useEffect(() => {
    if (!isFiltering) {
      readData();
    }
  }, [paginationData, readData]);

  const { accountsPermissions, subRoutes } = props;
  let updateLink = "";
  let updateTitle = "";
  const canUpdate =
    accountsPermissions.includes(PERMISSION_TYPES.UPDATE) || false;
  const canDelete =
    accountsPermissions.includes(PERMISSION_TYPES.DELETE) || false;
  if (canUpdate) {
    const { title, path } = subRoutes.find(
      ({ permissionRequired }) => permissionRequired === PERMISSION_TYPES.UPDATE
    );
    updateLink = path;
    updateTitle = title;
  }

  return (
    <>
      <PageHeading {...props} reloadData={reloadData} />
      <LoadingSpinner
        isLoading={
          responseStatus === "" && operators?.length === 0 && isLoading
        }
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILED &&
          !isLoading &&
          operators?.length === 0
        }
        message={`No operators found`}
      />
      {responseStatus === API_RESPONSE_TYPES.FAILED && (
        <MessageBox status={responseStatus} message={responseMessage} />
      )}
      {operators?.length > 0 && (
        <>
          <TableBuilder
            isLoading={operators?.length !== 0 && isLoading}
            tableHeadings={[
              {
                title: "",
                dataSelector: "id",
                sticky: true,
                canSort: false,
                width: "100px",
                CellRenderer: (value) => (
                  <>
                    {canUpdate && (
                      <StyledLink
                        to={updateLink.replace(":id", value)}
                        title={updateTitle}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </StyledLink>
                    )}
                    {canDelete && (
                      <CustomConfirmButton onClick={() => deleteData(value)} />
                    )}
                  </>
                ),
              },
              {
                title: "Operator",
                dataSelector: "operatorName",
                dataType: "string",
                CellRenderer: (value, item) => (
                  <StyledIconTitleContainer>
                    <StyledIconContainer>
                      <Image
                        source={
                          item.operatorLogo
                            ? item.operatorLogo
                            : "/no-image.png"
                        }
                        alt={value}
                        style={{ width: "50px", height: "50px" }}
                      />
                    </StyledIconContainer>
                    <StyledTitleContainer>
                      {value}
                      <StyledSpan>{item.serviceName}</StyledSpan>
                    </StyledTitleContainer>
                  </StyledIconTitleContainer>
                ),
              },
              {
                title: "Masters Margin",
                dataSelector: "mastersMargin",
                dataType: "number",
                CellRenderer: (value) => `${value}%`,
              },
              {
                title: "Circle Check",
                dataSelector: "shouldCheckCircle",
                dataType: "number",
              },
              {
                title: "Min Amount",
                dataSelector: "minRechargeAmount",
                dataType: "number",
                CellRenderer: (value) => formatCurrency(value),
              },
              {
                title: "Max Amount",
                dataSelector: "maxRechargeAmount",
                dataType: "number",
                CellRenderer: (value) => formatCurrency(value),
              },
              {
                title: "Plan API Code",
                dataSelector: "planApiOperatorCode",
                dataType: "number",
              },
              {
                title: "Roffer Status",
                dataSelector: "rofferApplicable",
                dataType: "string",
                align: "center",
                CellRenderer: (value) => (
                  <StatusHighliter
                    className={value === "Yes" ? "green" : "red"}
                  >
                    {value}
                  </StatusHighliter>
                ),
              },
              {
                title: "Status",
                dataSelector: "operatorStatus",
                dataType: "string",
                align: "center",
                CellRenderer: (value, data) =>
                  value && (
                    <Switch
                      value={value === "Active"}
                      onChange={(value) => updateData(value, data)}
                    />
                  ),
              },
            ]}
            tableData={operators}
          />
          <Pagination
            totalRecords={totalRecords}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
          />
        </>
      )}
    </>
  );
};

export default withAppHOC(OperatorsList);
