import React, { useState, useEffect, useCallback } from "react";
import { withAppHOC } from "../../hoc";
import PageHeading from "../../components/PageHeader/PageHeader";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import NoData from "../../components/NoData/NoData";
import {
  MessageBox,
  Form,
  Fieldset,
  Button,
  ResetButton,
  Dropdown,
  TextInput,
  Label,
} from "../../components/FormElements";
import { Image } from "../../components/Image";
import { TableBuilder } from "../../components/TableElements";
import {
  StyledIconTitleContainer,
  StyledIconContainer,
  StyledTitleContainer,
  StyledSpan,
  StyledFiltersContainer,
} from "../../components/Styled";
import API from "../../api";
import { API_RESPONSE_TYPES } from "../../constants";
import { formatCurrency } from "../../utils";
import moment from "moment";

const OperatorDayBook = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [daybookData, setDaybookData] = useState([]);
  const [users, setUsers] = useState([]);
  const [usersId, setUsersId] = useState("");
  const [transactionType, setTransactionType] = useState("");
  const [fromDate, setFromDate] = useState(moment().format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));

  const resetForm = () => {
    setUsersId("");
    setTransactionType("");
    setFromDate(moment().format("YYYY-MM-DD"));
    setToDate(moment().format("YYYY-MM-DD"));
    readData(
      "",
      "",
      moment().format("YYYY-MM-DD"),
      moment().format("YYYY-MM-DD")
    );
  };

  const readUsers = () => {
    API.get("/accounts-management/users").then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setUsers(data);
      }
    });
  };

  const readData = useCallback(
    (usersId = "", transactionType = "", fromDate = "", toDate = "") => {
      setIsLoading(true);
      setResponseStatus("");
      setResponseMessage("");
      API.get(
        `/reports/operators-day-book/buyer?usersId=${usersId}&transactionType=${transactionType}&fromDate=${fromDate}&toDate=${toDate}`
      )
        .then((response) => {
          const { status, message, data } = response.data;
          if (status === API_RESPONSE_TYPES.FAILED) {
            setResponseStatus(status);
            setResponseMessage(message);
          }
          setDaybookData(data);
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILED);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    []
  );

  const filterData = (e) => {
    e.preventDefault();
    readData(usersId, transactionType, fromDate, toDate);
  };

  useEffect(() => {
    readUsers();
    readData(usersId, transactionType, fromDate, toDate);
  }, []);

  const userOptions = [{ title: "-- SELECT USER --", value: "" }];
  if (users && users.length > 0) {
    users.forEach(({ id, fullName, mobileNumber }) =>
      userOptions.push({
        title: `${fullName} ( ${mobileNumber} )`,
        value: id,
      })
    );
  }

  return (
    <>
      <PageHeading {...props} canReloadProp={false} />
      <StyledFiltersContainer className="show">
        <Form method="POST" action="#" onSubmit={filterData}>
          <Fieldset>
            <Label>User</Label>
            <Dropdown
              placeholder="User"
              value={usersId}
              onChange={setUsersId}
              options={userOptions}
              disabled={isLoading}
              searchable
            />
          </Fieldset>
          <Fieldset>
            <Label>Transaction Type</Label>
            <Dropdown
              placeholder="Transaction Type"
              value={transactionType}
              onChange={setTransactionType}
              options={[
                { title: "-- Select Transaction Type --", value: "" },
                { title: "GST", value: "GST" },
                { title: "Non-GST", value: "Non-GST" },
              ]}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>From Date</Label>
            <TextInput
              type="date"
              value={fromDate}
              onChange={setFromDate}
              placeholder="From Date"
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>To Date</Label>
            <TextInput
              type="date"
              value={toDate}
              onChange={setToDate}
              placeholder="To Date"
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Button disabled={isLoading} type="submit">
              Search
            </Button>
            <ResetButton disabled={isLoading} type="button" onClick={resetForm}>
              Clear
            </ResetButton>
          </Fieldset>
        </Form>
      </StyledFiltersContainer>
      <LoadingSpinner
        isLoading={
          responseStatus === "" && daybookData?.length === 0 && isLoading
        }
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILED &&
          !isLoading &&
          daybookData?.length === 0
        }
        message={`No data found`}
      />
      <MessageBox status={responseStatus} message={responseMessage} />
      {daybookData?.length > 0 && (
        <TableBuilder
          isLoading={daybookData?.length !== 0 && isLoading}
          tableHeadings={[
            {
              title: "Operator",
              dataSelector: "operatorName",
              dataType: "string",
              CellRenderer: (value, item) => (
                <StyledIconTitleContainer>
                  <StyledIconContainer>
                    <Image
                      source={
                        item.operatorLogo ? item.operatorLogo : "/no-image.png"
                      }
                      alt={value}
                      style={{ width: "50px", height: "50px" }}
                    />
                  </StyledIconContainer>
                  <StyledTitleContainer>
                    {value}
                    <StyledSpan>{item.serviceName}</StyledSpan>
                  </StyledTitleContainer>
                </StyledIconTitleContainer>
              ),
            },
            {
              title: "Success",
              dataSelector: "successAmount",
              dataType: "string",
              CellRenderer: (value, item) =>
                `${formatCurrency(value)} (${item.successCount})`,
            },
            {
              title: "Failed",
              dataSelector: "failedAmount",
              dataType: "string",
              CellRenderer: (value, item) =>
                `${formatCurrency(value)} (${item.failedCount})`,
            },
            {
              title: "Pending",
              dataSelector: "pendingAmount",
              dataType: "string",
              CellRenderer: (value, item) =>
                `${formatCurrency(value)} (${item.pendingCount})`,
            },
            {
              title: "Average Margin",
              dataSelector: "averageMargin",
              dataType: "string",
              CellRenderer: (value) => `${(value).toFixed(2)}%`,
            },
            {
              title: "Wallet Debit",
              dataSelector: "walletDebit",
              dataType: "string",
              CellRenderer: (value) => formatCurrency(value),
            },
            {
              title: "Refunded",
              dataSelector: "refundAmount",
              dataType: "string",
              CellRenderer: (value, item) =>
                `${formatCurrency(value)} (${item.refundCount})`,
            },
            {
              title: "Wallet Credit",
              dataSelector: "walletCredit",
              dataType: "string",
              CellRenderer: (value) => formatCurrency(value),
            },
          ]}
          tableData={daybookData}
          tableFooter={[
            {
              value: "",
            },
            {
              value: `${formatCurrency(
                daybookData.reduce(
                  (previousValue, currentValue) =>
                    previousValue + Number(currentValue.successAmount),
                  0
                )
              )} (${daybookData.reduce(
                (previousValue, currentValue) =>
                  previousValue + Number(currentValue.successCount),
                0
              )})`,
            },
            {
              value: `${formatCurrency(
                daybookData.reduce(
                  (previousValue, currentValue) =>
                    previousValue + Number(currentValue.failedAmount),
                  0
                )
              )} (${daybookData.reduce(
                (previousValue, currentValue) =>
                  previousValue + Number(currentValue.failedCount),
                0
              )})`,
            },
            {
              value: `${formatCurrency(
                daybookData.reduce(
                  (previousValue, currentValue) =>
                    previousValue + Number(currentValue.pendingAmount),
                  0
                )
              )} (${daybookData.reduce(
                (previousValue, currentValue) =>
                  previousValue + Number(currentValue.pendingCount),
                0
              )})`,
            },
            {
              value: "",
            },
            {
              value: formatCurrency(
                daybookData.reduce(
                  (previousValue, currentValue) =>
                    previousValue + Number(currentValue.walletDebit),
                  0
                )
              ),
            },
            {
              value: `${formatCurrency(
                daybookData.reduce(
                  (previousValue, currentValue) =>
                    previousValue + Number(currentValue.refundAmount),
                  0
                )
              )} (${daybookData.reduce(
                (previousValue, currentValue) =>
                  previousValue + Number(currentValue.refundCount),
                0
              )})`,
            },
            {
              value: `${formatCurrency(
                daybookData.reduce(
                  (previousValue, currentValue) =>
                    previousValue + Number(currentValue.walletCredit),
                  0
                )
              )}`,
            },
          ]}
        />
      )}
    </>
  );
};

export default withAppHOC(OperatorDayBook);
