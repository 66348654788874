import React, { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownLong } from "@fortawesome/free-solid-svg-icons";
import { withAppHOC } from "../../hoc";
import PageHeading from "../../components/PageHeader/PageHeader";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import NoData from "../../components/NoData/NoData";
import {
  MessageBox,
  Form,
  Fieldset,
  Button,
  ResetButton,
  Label,
  Dropdown,
  TextInput,
} from "../../components/FormElements";
import {
  StyledLink,
  StyledFiltersContainer,
  StyledSpan,
} from "../../components/Styled";
import { TableBuilder } from "../../components/TableElements";
import Pagination from "../../components/Pagination/Pagination";
import API from "../../api";
import { API_RESPONSE_TYPES } from "../../constants";
import {
  getSearchParams,
  shouldRedirect,
  redirectToPageOne,
  formatCurrency,
} from "../../utils";
import FiltersButton from "../../components/FiltersButton/FiltersButton";
import StatusHighliter from "../../components/StatusHighliter/StatusHighliter";
import { isEmpty } from "lodash";

const SellerInvoices = (props) => {
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams("pageNumber") || 1,
    recordsPerPage: getSearchParams("recordsPerPage") || 10,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [data, setData] = useState([]);
  const [users, setUsers] = useState([]);
  const [usersId, setUsersId] = useState("");
  const [date, setDate] = useState("");
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");

  const resetForm = () => {
    setUsersId("");
    setYear("");
    setMonth("");
    setDate("");
    readData();
  };

  const readUsers = useCallback(() => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.get("/accounts-management/users?pageNumber=1&recordsPerPage=100000")
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILED) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          setUsers(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILED);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const readData = useCallback(
    (usersId = "", year = "", month = "") => {
      setIsLoading(true);
      setResponseStatus("");
      setResponseMessage("");
      API.get(
        `/invoicing/sellers/?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&usersId=${usersId}&year=${year}&month=${month}`
      )
        .then((response) => {
          const { status, message, data, pageInfo } = response.data;
          if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
            redirectToPageOne();
          } else {
            if (status === API_RESPONSE_TYPES.FAILED) {
              setResponseStatus(status);
              setResponseMessage(message);
            } else {
              setTotalRecords(pageInfo.totalRecords);
            }
            setData(data);
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILED);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [paginationData]
  );

  const reloadData = () => {
    readData(usersId, year, month);
  };

  //   const updateData = (value, data, key) => {
  //     const newData = { ...data };
  //     newData[key] = value === true ? `Active` : `Inactive`;
  //     setIsLoading(true);
  //     setResponseStatus("");
  //     setResponseMessage("");
  //     API.put(
  //       `/recharge-configurations/seller-apis/${newData.id}?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&usersId=${usersId}`,
  //       newData
  //     )
  //       .then((response) => {
  //         const { status, message, data } = response.data;
  //         if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
  //           redirectToPageOne();
  //         } else {
  //           if (status === API_RESPONSE_TYPES.FAILED) {
  //             setResponseStatus(status);
  //             setResponseMessage(message);
  //           } else {
  //             setSellerApis(data);
  //           }
  //         }
  //       })
  //       .catch((error) => {
  //         setResponseStatus(API_RESPONSE_TYPES.FAILED);
  //         setResponseMessage(error.message);
  //       })
  //       .finally(() => {
  //         setIsLoading(false);
  //       });
  //   };

  const filterData = (e) => {
    e.preventDefault();
    setIsFiltering(true);
  };

  useEffect(() => {
    if (isFiltering) {
      setPaginationData((paginationData) => ({
        pageNumber: 1,
        recordsPerPage: paginationData.recordsPerPage,
      }));
      setIsFiltering((isFiltering) => !isFiltering);
    }
  }, [isFiltering]);

  useEffect(() => {
    if (!isFiltering) {
      readData(usersId, year, month);
    }
  }, [paginationData, readData]);

  const [showFilters, setShowFilters] = useState(false);
  const onClickShowFilters = () => {
    setShowFilters((showFilters) => !showFilters);
  };

  useEffect(() => {
    readUsers();
  }, [readUsers]);

  useEffect(() => {
    if (!isEmpty(date)) {
      const splitedDate = date.split("-");
      setYear(splitedDate[0]);
      setMonth(splitedDate[1]);
    } else {
      setYear("");
      setMonth("");
    }
  }, [date]);

  const usersOptions = [{ title: "-- SELECT USER --", value: "" }];
  users.forEach(({ id, fullName }) =>
    usersOptions.push({
      title: fullName,
      value: id,
    })
  );

  return (
    <>
      <PageHeading {...props} reloadData={reloadData} />
      <FiltersButton onClick={onClickShowFilters} value={showFilters} />
      <StyledFiltersContainer className={showFilters ? "show" : ""}>
        <Form method="POST" action="#" onSubmit={filterData}>
          <Fieldset>
            <Label>User</Label>
            <Dropdown
              value={usersId}
              onChange={setUsersId}
              placeholder="User"
              disabled={isLoading}
              options={usersOptions}
              searchable
            />
          </Fieldset>
          <Fieldset>
            <Label>Year & Month</Label>
            <TextInput
              type="month"
              value={date}
              onChange={setDate}
              placeholder="Month"
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Button disabled={isLoading} type="submit">
              Filter
            </Button>
            <ResetButton disabled={isLoading} type="button" onClick={resetForm}>
              Clear
            </ResetButton>
          </Fieldset>
        </Form>
      </StyledFiltersContainer>
      <LoadingSpinner
        isLoading={responseStatus === "" && data?.length === 0 && isLoading}
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILED &&
          !isLoading &&
          data?.length === 0
        }
        message={`No invoices found`}
      />
      {responseStatus === API_RESPONSE_TYPES.FAILED && (
        <MessageBox status={responseStatus} message={responseMessage} />
      )}
      {data?.length > 0 && (
        <>
          <TableBuilder
            isLoading={data?.length !== 0 && isLoading}
            tableHeadings={[
              {
                title: "",
                dataSelector: "id",
                sticky: true,
                canSort: false,
                width: "100px",
                CellRenderer: (_, item) =>
                  item.invoiceFile && (
                    <StyledLink
                      to={item.invoiceFile}
                      title="Download"
                      target="_blank"
                    >
                      <FontAwesomeIcon icon={faDownLong} /> Download
                    </StyledLink>
                  ),
              },
              {
                title: "User",
                dataSelector: "fullName",
                dataType: "string",
                CellRenderer: (value, item) => (
                  <>
                    {value}
                    <StyledSpan>{item?.mobileNumber}</StyledSpan>
                  </>
                ),
              },
              {
                title: "Year",
                dataSelector: "year",
                dataType: "string",
              },
              {
                title: "Month",
                dataSelector: "month",
                dataType: "string",
              },
              {
                title: "Amount",
                dataSelector: "amount",
                dataType: "string",
                CellRenderer: (value) => formatCurrency(value) || "N/A",
              },
              {
                title: "Remark",
                dataSelector: "remark",
                dataType: "string",
                CellRenderer: (value) => value || "N/A",
              },
              {
                title: "Status",
                dataSelector: "status",
                dataType: "string",
                align: "center",
                CellRenderer: (value) => (
                  <StatusHighliter
                    className={
                      value === "Approved"
                        ? "green"
                        : value === "Rejected"
                        ? "red"
                        : value === "Submitted"
                        ? "blue"
                        : "yellow"
                    }
                  >
                    {value}
                  </StatusHighliter>
                ),
              },
              {
                title: "GSTR1 Status",
                dataSelector: "gstr1Status",
                dataType: "string",
                align: "center",
                CellRenderer: (value) => (
                  <StatusHighliter
                    className={
                      value === "Approved"
                        ? "green"
                        : value === "Rejected"
                        ? "red"
                        : value === "Submitted"
                        ? "blue"
                        : "yellow"
                    }
                  >
                    {value}
                  </StatusHighliter>
                ),
              },
            ]}
            tableData={data}
          />
          <Pagination
            totalRecords={totalRecords}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
          />
        </>
      )}
    </>
  );
};

export default withAppHOC(SellerInvoices);
