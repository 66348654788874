import React, { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { withAppHOC } from "../../../hoc";
import PageHeading from "../../../components/PageHeader/PageHeader";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import NoData from "../../../components/NoData/NoData";
import {
  MessageBox,
  Form,
  Fieldset,
  Button,
  ResetButton,
  Label,
  Dropdown,
  Switch,
} from "../../../components/FormElements";
import {
  StyledLink,
  StyledFiltersContainer,
  StyledSpan,
} from "../../../components/Styled";
import { TableBuilder } from "../../../components/TableElements";
import Pagination from "../../../components/Pagination/Pagination";
import API from "../../../api";
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from "../../../constants";
import {
  getSearchParams,
  shouldRedirect,
  redirectToPageOne,
} from "../../../utils";
import FiltersButton from "../../../components/FiltersButton/FiltersButton";

const APIProvidersTable = (props) => {
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams("pageNumber") || 1,
    recordsPerPage: getSearchParams("recordsPerPage") || 10,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [sellerApis, setSellerApis] = useState([]);
  const [users, setUsers] = useState([]);
  const [fUsersId, setFusersId] = useState("");

  const resetForm = () => {
    setFusersId("");
    readData();
  };

  const readUsers = useCallback(() => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.get("/accounts-management/users?pageNumber=1&recordsPerPage=100000")
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILED) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          setUsers(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILED);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const readData = useCallback(
    (fUsersId = "") => {
      setIsLoading(true);
      setResponseStatus("");
      setResponseMessage("");
      API.get(
        `/recharge-configurations/seller-apis/?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&fUsersId=${fUsersId}`
      )
        .then((response) => {
          const { status, message, data, pageInfo } = response.data;
          if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
            redirectToPageOne();
          } else {
            if (status === API_RESPONSE_TYPES.FAILED) {
              setResponseStatus(status);
              setResponseMessage(message);
            } else {
              setTotalRecords(pageInfo.totalRecords);
            }
            setSellerApis(data);
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILED);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [paginationData]
  );

  const reloadData = () => {
    readData(fUsersId);
  };

  const updateData = (value, data, key) => {
    const newData = { ...data };
    newData[key] = value === true ? `Active` : `Inactive`;
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.put(
      `/recharge-configurations/seller-apis/${newData.id}?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&fUsersId=${fUsersId}`,
      newData
    )
      .then((response) => {
        const { status, message, data } = response.data;
        if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
          redirectToPageOne();
        } else {
          if (status === API_RESPONSE_TYPES.FAILED) {
            setResponseStatus(status);
            setResponseMessage(message);
          } else {
            setSellerApis(data);
          }
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILED);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const filterData = (e) => {
    e.preventDefault();
    setIsFiltering(true);
  };

  useEffect(() => {
    if (isFiltering) {
      setPaginationData((paginationData) => ({
        pageNumber: 1,
        recordsPerPage: paginationData.recordsPerPage,
      }));
      setIsFiltering((isFiltering) => !isFiltering);
    }
  }, [isFiltering]);

  useEffect(() => {
    if (!isFiltering) {
      readData(fUsersId);
    }
  }, [paginationData, readData]);

  const { accountsPermissions, subRoutes } = props;
  let updateLink = "";
  let updateTitle = "";
  const canUpdate =
    accountsPermissions.includes(PERMISSION_TYPES.UPDATE) || false;
  if (canUpdate) {
    const { title, path } = subRoutes.find(
      ({ permissionRequired }) => permissionRequired === PERMISSION_TYPES.UPDATE
    );
    updateLink = path;
    updateTitle = title;
  }

  const [showFilters, setShowFilters] = useState(false);
  const onClickShowFilters = () => {
    setShowFilters((showFilters) => !showFilters);
  };

  useEffect(() => {
    readUsers();
  }, [readUsers]);

  const usersOptions = [{ title: "-- SELECT USER --", value: "" }];
  users.forEach(({ id, fullName }) =>
    usersOptions.push({
      title: fullName,
      value: id,
    })
  );

  return (
    <>
      <PageHeading {...props} reloadData={reloadData} />
      <FiltersButton onClick={onClickShowFilters} value={showFilters} />
      <StyledFiltersContainer className={showFilters ? "show" : ""}>
        <Form method="POST" action="#" onSubmit={filterData}>
          <Fieldset>
            <Label>User</Label>
            <Dropdown
              value={fUsersId}
              onChange={setFusersId}
              placeholder="User"
              disabled={isLoading}
              options={usersOptions}
              searchable
            />
          </Fieldset>
          <Fieldset>
            <Button disabled={isLoading} type="submit">
              Filter
            </Button>
            <ResetButton
              disabled={isLoading}
              type="button"
              onClick={resetForm}
            >
              Clear
            </ResetButton>
          </Fieldset>
        </Form>
      </StyledFiltersContainer>
      <LoadingSpinner
        isLoading={
          responseStatus === "" && sellerApis?.length === 0 && isLoading
        }
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILED &&
          !isLoading &&
          sellerApis?.length === 0
        }
        message={`No seller apis found`}
      />
      {responseStatus === API_RESPONSE_TYPES.FAILED && (
        <MessageBox status={responseStatus} message={responseMessage} />
      )}
      {sellerApis?.length > 0 && (
        <>
          <TableBuilder
            isLoading={sellerApis?.length !== 0 && isLoading}
            tableHeadings={[
              {
                title: "",
                dataSelector: "id",
                sticky: true,
                canSort: false,
                width: "100px",
                CellRenderer: (value) => (
                  <>
                    {canUpdate && (
                      <StyledLink
                        to={updateLink.replace(":id", value)}
                        title={updateTitle}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </StyledLink>
                    )}
                  </>
                ),
              },
              {
                title: "User",
                dataSelector: "fullName",
                dataType: "string",
                CellRenderer: (value, item) => (
                  <>
                    {value}
                    <StyledSpan>{item?.mobileNumber}</StyledSpan>
                  </>
                ),
              },
              {
                title: "API Name",
                dataSelector: "apiName",
                dataType: "string",
              },
              {
                title: "IP Address",
                dataSelector: "ipAddress",
                dataType: "string",
              },
              {
                title: "Callback Key",
                dataSelector: "callbackKey",
                dataType: "string",
                CellRenderer: (value) => `https://apis.${window.location.href.includes('remitsarathi') || window.location.href.includes('localhost') ? 'rechargeexchange.remitsarathi.com' : 'apiexchange.co.in'}/v1/callback/${value}`
              },
              {
                title: "API Status",
                dataSelector: "apiStatus",
                dataType: "string",
                align: "center",
                CellRenderer: (value, data) =>
                  value && (
                    <Switch
                      value={value === "Active"}
                      onChange={(value) => updateData(value, data, "apiStatus")}
                    />
                  ),
              },
              {
                title: "Admin API Status",
                dataSelector: "adminsApiStatus",
                dataType: "string",
                align: "center",
                CellRenderer: (value, data) =>
                  value && (
                    <Switch
                      value={value === "Active"}
                      onChange={(value) =>
                        updateData(value, data, "adminsApiStatus")
                      }
                    />
                  ),
              },
            ]}
            tableData={sellerApis}
          />
          <Pagination
            totalRecords={totalRecords}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
          />
        </>
      )}
    </>
  );
};

export default withAppHOC(APIProvidersTable);
