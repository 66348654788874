import React, { Fragment, forwardRef } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import isEmpty from "lodash/isEmpty";
import { generateRoutes } from "../../utils";
import { PERMISSION_TYPES } from "../../constants";

const StyledBreadcrumbs = styled.section`
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 10px 0px 0px;
  a {
    font-size: 13px;
    color: #545454;
    margin: 0px;
    padding: 0px;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
    svg {
      font-size: 14px;
    }
  }
  svg {
    font-size: 10px;
    color: #545454;
    padding: 0px;
    margin: 0px 5px;
  }
`;

const Breadcrumbs = forwardRef((props, ref) => {
  const allRoutes = generateRoutes();
  const { pathname } = useLocation();
  const paths = pathname.split("/");

  if (paths.includes("update")) {
    const index = paths.indexOf("update");
    paths.splice(index, paths.length - 1, paths[index].concat("/:id"));
  }
  if (paths.includes("view")) {
    const index = paths.indexOf("view");
    paths.splice(index, paths.length - 1, paths[index].concat("/:id"));
  }
  if (paths.includes("wallet-transactions")) {
    const index = paths.indexOf("wallet-transactions");
    paths.splice(index, paths.length - 1, paths[index].concat(`/:id`));
  }
  if (paths.includes("fund-switching-transactions")) {
    const index = paths.indexOf("fund-switching-transactions");
    paths.splice(index, paths.length - 1, paths[index].concat(`/:id`));
  }

  const urls = paths.map((path, index) =>
    !isEmpty(path) ? [...paths].splice(0, index + 1).join("/") : "/"
  );

  const breadcrumbsUrls = [];

  const baseBreadcrumbsUrls = allRoutes.filter(({ path }) =>
    urls.includes(path)
  );

  baseBreadcrumbsUrls.forEach((item) => {
    if (!breadcrumbsUrls.some((item1) => item1.path === item.path)) {
      breadcrumbsUrls.push(item);
    }
  });

  return (
    !isEmpty(breadcrumbsUrls) && (
      <StyledBreadcrumbs ref={ref}>
        {breadcrumbsUrls.map(
          ({ path, title, icon, permissionRequired, viewType }, index) => (
            <Fragment key={index}>
              <Link
                to={
                  [
                    PERMISSION_TYPES.UPDATE,
                    PERMISSION_TYPES.WALLET_TRANSACTIONS,
                    PERMISSION_TYPES.FUND_SWITCHING_TRANSACTIONS,
                  ].includes(permissionRequired) || viewType === "VIEW"
                    ? pathname
                    : path
                }
                title={title}
              >
                {index === 0 ? <FontAwesomeIcon icon={icon} /> : title}
              </Link>
              {breadcrumbsUrls.length !== index + 1 && (
                <FontAwesomeIcon icon={faChevronRight} />
              )}
            </Fragment>
          )
        )}
      </StyledBreadcrumbs>
    )
  );
});

export default Breadcrumbs;
