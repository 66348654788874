import React, { useState, useEffect, useCallback } from "react";
import { withAppHOC } from "../../../hoc";
import PageHeading from "../../../components/PageHeader/PageHeader";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import NoData from "../../../components/NoData/NoData";
import { Image } from "../../../components/Image";
import { TableBuilder } from "../../../components/TableElements";
import {
  StyledIconTitleContainer,
  StyledIconContainer,
  StyledTitleContainer,
  StyledSpan,
  StyledFiltersContainer,
} from "../../../components/Styled";
import {
  CustomConfirmButton,
  MessageBox,
  Form,
  Fieldset,
  Button,
  ResetButton,
  Label,
  Dropdown,
} from "../../../components/FormElements";
import FiltersButton from "../../../components/FiltersButton/FiltersButton";
import Pagination from "../../../components/Pagination/Pagination";
import API from "../../../api";
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from "../../../constants";
import {
  getSearchParams,
  shouldRedirect,
  redirectToPageOne,
} from "../../../utils";

const OperatorBlockerList = (props) => {
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams("pageNumber") || 1,
    recordsPerPage: getSearchParams("recordsPerPage") || 10,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [blockers, setBlockers] = useState([]);
  const [users, setUsers] = useState([]);
  const [operators, setOperators] = useState([]);
  const [fUsersId, setFusersId] = useState("");
  const [fOperatorsId, setFoperatorsId] = useState("");
  const [fProfileType, setFprofileType] = useState("");

  const resetForm = () => {
    setFusersId("");
    setFoperatorsId("");
    setFprofileType("");
    readData();
  };

  const readUsers = useCallback(() => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.get("/accounts-management/users?pageNumber=1&recordsPerPage=100000")
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILED) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          setUsers(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILED);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const readOperators = useCallback(() => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.get(
      "/recharge-configurations/operators?pageNumber=1&recordsPerPage=100000"
    )
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILED) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          setOperators(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILED);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const readData = useCallback(
    (fUsersId = "", fOperatorsId = "", fProfileType = "") => {
      setIsLoading(true);
      setResponseStatus("");
      setResponseMessage("");
      setBlockers([]);
      API.get(
        `/recharge-configurations/operator-blocker/?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&fUsersId=${fUsersId}&fOperatorsId=${fOperatorsId}&fProfileType=${fProfileType}`
      )
        .then((response) => {
          const { status, message, data, pageInfo } = response.data;
          if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
            redirectToPageOne();
          } else {
            setBlockers(data);
            if (status === API_RESPONSE_TYPES.FAILED) {
              setResponseStatus(status);
              setResponseMessage(message);
            } else {
              setTotalRecords(pageInfo.totalRecords);
            }
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILED);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [paginationData]
  );

  const deleteData = (id) => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.delete(
      `/recharge-configurations/operator-blocker/${id}?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&fUsersId=${fUsersId}&fOperatorsId=${fOperatorsId}&fProfileType=${fProfileType}`
    )
      .then((response) => {
        const { status, message, data } = response.data;
        if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
          redirectToPageOne();
        } else {
          if (status === API_RESPONSE_TYPES.FAILED) {
            setResponseStatus(status);
            setResponseMessage(message);
          } else {
            setBlockers(data);
          }
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILED);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const reloadData = () => {
    readData(fUsersId, fOperatorsId, fProfileType);
  };

  const filterData = (e) => {
    e.preventDefault();
    setIsFiltering(true);
  };

  useEffect(() => {
    if (isFiltering) {
      setPaginationData((paginationData) => ({
        pageNumber: 1,
        recordsPerPage: paginationData.recordsPerPage,
      }));
      setIsFiltering((isFiltering) => !isFiltering);
    }
  }, [isFiltering]);

  useEffect(() => {
    if (!isFiltering) {
      readData(fUsersId, fOperatorsId, fProfileType);
    }
  }, [paginationData, readData]);

  useEffect(() => {
    readUsers();
    readOperators();
  }, [readUsers, readOperators]);

  const usersOptions = [{ title: "-- SELECT USER --", value: "" }];
  users.forEach(({ id, fullName }) =>
    usersOptions.push({
      title: fullName,
      value: id,
    })
  );

  const operatorsOptions = [{ title: "-- SELECT OPERATOR --", value: "" }];
  operators.forEach(({ id, operatorName }) =>
    operatorsOptions.push({
      title: operatorName,
      value: id,
    })
  );

  const [showFilters, setShowFilters] = useState(false);
  const onClickShowFilters = () => {
    setShowFilters((showFilters) => !showFilters);
  };

  const { accountsPermissions } = props;
  const canDelete =
    accountsPermissions.includes(PERMISSION_TYPES.DELETE) || false;

  return (
    <>
      <PageHeading {...props} reloadData={reloadData} />
      <FiltersButton onClick={onClickShowFilters} value={showFilters} />
      <StyledFiltersContainer className={showFilters ? "show" : ""}>
        <Form method="POST" action="#" onSubmit={filterData}>
          <Fieldset>
            <Label>User</Label>
            <Dropdown
              value={fUsersId}
              onChange={setFusersId}
              placeholder="User"
              disabled={isLoading}
              options={usersOptions}
              searchable
            />
          </Fieldset>
          <Fieldset>
            <Label>Operator</Label>
            <Dropdown
              value={fOperatorsId}
              onChange={setFoperatorsId}
              placeholder="Operator"
              disabled={isLoading}
              options={operatorsOptions}
            />
          </Fieldset>
          <Fieldset>
            <Label>Profile Type</Label>
            <Dropdown
              value={fProfileType}
              onChange={setFprofileType}
              placeholder="Profile Type"
              disabled={isLoading}
              options={[
                {
                  title: "-- SELECT PROFILE TYPE --",
                  value: "",
                },
                {
                  title: "Buyer",
                  value: "Buyer",
                },
                {
                  title: "Seller",
                  value: "Seller",
                },
              ]}
            />
          </Fieldset>
          <Fieldset>
            <Button disabled={isLoading} type="submit">
              Filter
            </Button>
            <ResetButton disabled={isLoading} type="button" onClick={resetForm}>
              Clear
            </ResetButton>
          </Fieldset>
        </Form>
      </StyledFiltersContainer>
      <LoadingSpinner
        isLoading={responseStatus === "" && blockers?.length === 0 && isLoading}
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILED &&
          !isLoading &&
          blockers?.length === 0
        }
        message={`No blockers found`}
      />
      {responseStatus === API_RESPONSE_TYPES.FAILED && (
        <MessageBox status={responseStatus} message={responseMessage} />
      )}
      {blockers?.length > 0 && (
        <>
          <TableBuilder
            isLoading={blockers?.length !== 0 && isLoading}
            tableHeadings={[
              {
                title: "",
                dataSelector: "id",
                sticky: true,
                canSort: false,
                width: "100px",
                CellRenderer: (value) => (
                  <>
                    {canDelete && (
                      <CustomConfirmButton onClick={() => deleteData(value)} />
                    )}
                  </>
                ),
              },
              {
                title: "User",
                dataSelector: "fullName",
                dataType: "string",
                CellRenderer: (value, item) => (
                  <>
                    {value}
                    <StyledSpan>{item.mobileNumber}</StyledSpan>
                  </>
                ),
              },
              {
                title: "Operator",
                dataSelector: "operatorName",
                dataType: "string",
                CellRenderer: (value, item) => (
                  <StyledIconTitleContainer>
                    <StyledIconContainer>
                      <Image
                        source={
                          item.operatorLogo
                            ? item.operatorLogo
                            : "/no-image.png"
                        }
                        alt={value}
                        style={{ width: "50px", height: "50px" }}
                      />
                    </StyledIconContainer>
                    <StyledTitleContainer>{value}</StyledTitleContainer>
                  </StyledIconTitleContainer>
                ),
              },
              {
                title: "Profile Type",
                dataSelector: "profileType",
                dataType: "string",
              },
              {
                title: "Remark",
                dataSelector: "remark",
                dataType: "string",
                CellRenderer: (value) => value || "N/A",
              },
            ]}
            tableData={blockers}
          />
          <Pagination
            totalRecords={totalRecords}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
          />
        </>
      )}
    </>
  );
};

export default withAppHOC(OperatorBlockerList);
