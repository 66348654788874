import React, { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { withAppHOC } from "../../../hoc";
import PageHeading from "../../../components/PageHeader/PageHeader";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import NoData from "../../../components/NoData/NoData";
import {
  CustomConfirmButton,
  MessageBox,
  Switch,
} from "../../../components/FormElements";
import { TableBuilder } from "../../../components/TableElements";
import {
  StyledLink,
  StyledIconTitleContainer,
  StyledIconContainer,
  StyledTitleContainer,
} from "../../../components/Styled";
import { Image } from "../../../components/Image";
import Pagination from "../../../components/Pagination/Pagination";
import API from "../../../api";
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from "../../../constants";
import {
  getSearchParams,
  shouldRedirect,
  redirectToPageOne,
} from "../../../utils";

const BankAccountsList = (props) => {
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams("pageNumber") || 1,
    recordsPerPage: getSearchParams("recordsPerPage") || 10,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [bankAccounts, setBankAccounts] = useState([]);

  const readData = useCallback(() => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.get(
      `/configurations/bank-accounts?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}`
    )
      .then((response) => {
        const { status, data, pageInfo } = response.data;
        if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
          redirectToPageOne();
        } else {
          if (status === API_RESPONSE_TYPES.SUCCESS) {
            setTotalRecords(pageInfo.totalRecords);
          }
          setBankAccounts(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILED);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [paginationData]);

  const reloadData = () => {
    readData();
  };

  const updateData = (value, data, key) => {
    const newData = { ...data };
    newData[key] = value === true ? `Active` : `Inactive`;
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.put(
      `/configurations/bank-accounts/${newData.id}?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}`,
      newData
    )
      .then((response) => {
        const { status, message, data } = response.data;
        if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
          redirectToPageOne();
        } else {
          if (status === API_RESPONSE_TYPES.FAILED) {
            setResponseStatus(status);
            setResponseMessage(message);
          } else {
            setBankAccounts(data);
          }
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILED);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteData = (id) => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.delete(
      `/configurations/bank-accounts/${id}?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}`
    )
      .then((response) => {
        const { status, message, data } = response.data;
        if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
          redirectToPageOne();
        } else {
          if (status === API_RESPONSE_TYPES.FAILED) {
            setResponseStatus(status);
            setResponseMessage(message);
          } else {
            setBankAccounts(data);
          }
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILED);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    readData();
  }, [paginationData, readData]);

  const { accountsPermissions, subRoutes } = props;
  let updateLink = "";
  let updateTitle = "";
  const canUpdate =
    accountsPermissions.includes(PERMISSION_TYPES.UPDATE) || false;
  const canDelete =
    accountsPermissions.includes(PERMISSION_TYPES.DELETE) || false;
  if (canUpdate) {
    const { title, path } = subRoutes.find(
      ({ permissionRequired }) => permissionRequired === PERMISSION_TYPES.UPDATE
    );
    updateLink = path;
    updateTitle = title;
  }

  return (
    <>
      <PageHeading {...props} reloadData={reloadData} />
      <LoadingSpinner
        isLoading={
          responseStatus === "" && bankAccounts?.length === 0 && isLoading
        }
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILED &&
          !isLoading &&
          bankAccounts?.length === 0
        }
        message={`No bank accounts found`}
      />
      {responseStatus === API_RESPONSE_TYPES.FAILED && (
        <MessageBox status={responseStatus} message={responseMessage} />
      )}
      {bankAccounts?.length > 0 && (
        <>
          <TableBuilder
            isLoading={bankAccounts?.length !== 0 && isLoading}
            tableHeadings={[
              {
                title: "",
                dataSelector: "id",
                sticky: true,
                canSort: false,
                width: "100px",
                CellRenderer: (value) => (
                  <>
                    {canUpdate && (
                      <StyledLink
                        to={updateLink.replace(":id", value)}
                        title={updateTitle}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </StyledLink>
                    )}
                    {canDelete && (
                      <CustomConfirmButton onClick={() => deleteData(value)} />
                    )}
                  </>
                ),
              },
              {
                title: "Bank",
                dataSelector: "bankName",
                dataType: "string",
                canSort: false,
                CellRenderer: (value, item) => (
                  <StyledIconTitleContainer>
                    <StyledIconContainer>
                      <Image
                        source={item.bankLogo ? item.bankLogo : "/no-image.png"}
                        alt={value}
                        style={{ width: "50px", height: "50px" }}
                      />
                    </StyledIconContainer>
                    <StyledTitleContainer>{value}</StyledTitleContainer>
                  </StyledIconTitleContainer>
                ),
              },
              {
                title: "Payee Name",
                dataSelector: "payeeName",
                dataType: "string",
              },
              {
                title: "Account Number",
                dataSelector: "accountNumber",
                dataType: "string",
              },
              {
                title: "IFSC Code",
                dataSelector: "ifscCode",
                dataType: "string",
              },
              {
                title: "Status",
                dataSelector: "bankStatus",
                dataType: "string",
                align: "center",
                canSort: false,
                CellRenderer: (value, data) => (
                  <Switch
                    value={value === "Active"}
                    onChange={(value) =>
                      updateData(value, data, "bankStatus")
                    }
                  />
                ),
              },
            ]}
            tableData={bankAccounts}
          />
          <Pagination
            totalRecords={totalRecords}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
          />
        </>
      )}
    </>
  );
};

export default withAppHOC(BankAccountsList);
