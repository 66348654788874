import React, { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAsterisk,
  faEdit,
  faIndianRupeeSign,
  faList12,
  faListCheck,
} from "@fortawesome/free-solid-svg-icons";
import { withAppHOC } from "../../../hoc";
import PageHeading from "../../../components/PageHeader/PageHeader";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import NoData from "../../../components/NoData/NoData";
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  ResetButton,
  MessageBox,
  Dropdown,
  Switch,
  CustomButton,
  Button,
  NumberInput,
  CreateButton,
} from "../../../components/FormElements";
import { Image } from "../../../components/Image";
import { TableBuilder } from "../../../components/TableElements";
import Modal from "../../../components/Modal";
import OverlayLoader from "../../../components/OverlayLoader/OverlayLoader";
import {
  StyledLink,
  StyledSpan,
  StyledModalContainer,
  StyledFiltersContainer,
  StyledOTPModal,
  StyledOTPText,
} from "../../../components/Styled";
import Pagination from "../../../components/Pagination/Pagination";
import API from "../../../api";
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from "../../../constants";
import {
  getSearchParams,
  formatCurrency,
  shouldRedirect,
  redirectToPageOne,
  getAmountInWords,
} from "../../../utils";
import FiltersButton from "../../../components/FiltersButton/FiltersButton";

const UsersList = (props) => {
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams("pageNumber") || 1,
    recordsPerPage: getSearchParams("recordsPerPage") || 30,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [users, setUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [fMobileNumber, setFmobileNumber] = useState("");
  const [fName, setFname] = useState("");
  const [fUserStatus, setFuserStatus] = useState("");
  const [accountsLatestOtp, setAccountsLatestOtp] = useState("");
  const [usersId, setUsersId] = useState("");
  const [modalType, setModalType] = useState("");
  const [wallet, setWallet] = useState("");
  const [transactionType, setTransactionType] = useState("");
  const [remark, setRemark] = useState("");
  const [amount, setAmount] = useState("");

  const resetForm = () => {
    setTransactionType("");
    setRemark("");
    setAmount("");
    setWallet("");
  };

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus("");
    setResponseMessage("");
  };

  const resetFilters = () => {
    setFmobileNumber("");
    setFname("");
    setFuserStatus("");
    readData();
  };

  const readData = useCallback(
    (fMobileNumber = "", fName = "", fUserStatus = "") => {
      setIsLoading(true);
      setResponseStatus("");
      setResponseMessage("");
      setUsers([]);
      API.get(
        `/accounts-management/users?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&fMobileNumber=${fMobileNumber}&fName=${fName}&fUserStatus=${fUserStatus}`
      )
        .then((response) => {
          const { status, message, data, pageInfo } = response.data;
          if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
            redirectToPageOne();
          } else {
            if (status === API_RESPONSE_TYPES.FAILED) {
              setResponseStatus(status);
              setResponseMessage(message);
            } else {
              setTotalRecords(pageInfo.totalRecords);
            }
            setUsers(data);
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILED);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [paginationData]
  );

  const reloadData = () => {
    readData(fMobileNumber, fName, fUserStatus);
  };

  const readAccountsLatestOTP = (usersId) => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.get(`/accounts-management/users/last-otp/${usersId}`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILED) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          setAccountsLatestOtp(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILED);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateData = (value, data, key) => {
    const newData = { ...data };
    newData[key] = value === true ? `Active` : `Inactive`;
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.put(
      `/accounts-management/users/${newData.id}?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&fMobileNumber=${fMobileNumber}&fName=${fName}&fUserStatus=${fUserStatus}`,
      newData
    )
      .then((response) => {
        const { status, message, data } = response.data;
        if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
          redirectToPageOne();
        } else {
          if (status === API_RESPONSE_TYPES.FAILED) {
            setResponseStatus(status);
            setResponseMessage(message);
          } else {
            setUsers(data);
          }
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILED);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.put(`/accounts-management/users/process-funds/${usersId}`, {
      wallet,
      transactionType,
      remark,
      amount,
    })
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          resetForm();
          setTimeout(() => {
            resetResponseData();
            setUsersId("");
            setModalType("");
            setShowModal(false);
            reloadData();
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const filterData = (e) => {
    e.preventDefault();
    setIsFiltering(true);
  };

  useEffect(() => {
    if (isFiltering) {
      setPaginationData((paginationData) => ({
        pageNumber: 1,
        recordsPerPage: paginationData.recordsPerPage,
      }));
      setIsFiltering((isFiltering) => !isFiltering);
    }
  }, [isFiltering]);

  useEffect(() => {
    if (!isFiltering) {
      readData(fMobileNumber, fName, fUserStatus);
    }
  }, [paginationData, readData]);

  const { accountsPermissions, subRoutes } = props;

  const canUpdate =
    accountsPermissions.includes(PERMISSION_TYPES.UPDATE) || false;
  const canReadLatestOTP =
    accountsPermissions.includes(PERMISSION_TYPES.READ_LATEST_OTP) || false;
  const canReadWalletTransactions =
    accountsPermissions.includes(PERMISSION_TYPES.WALLET_TRANSACTIONS) || false;
  const canReadFundSwitchingTransactions =
    accountsPermissions.includes(
      PERMISSION_TYPES.FUND_SWITCHING_TRANSACTIONS
    ) || false;
  const canProcessFunds =
    accountsPermissions.includes(PERMISSION_TYPES.WALLET_PROCESS_FUNDS) ||
    false;

  let updateLink = "";
  let updateTitle = "";
  if (canUpdate) {
    const { title, path } = subRoutes.find(
      ({ permissionRequired }) => permissionRequired === PERMISSION_TYPES.UPDATE
    );
    updateLink = path;
    updateTitle = title;
  }
  let walletTransactionsLink = "";
  let walletTransactionsTitle = "";
  if (canReadWalletTransactions) {
    const { title, path } = subRoutes.find(
      ({ permissionRequired }) =>
        permissionRequired === PERMISSION_TYPES.WALLET_TRANSACTIONS
    );
    walletTransactionsLink = path;
    walletTransactionsTitle = title;
  }
  let fundSwitchingTransactionsLink = "";
  let fundSwitchingTransactionsTitle = "";
  if (canReadFundSwitchingTransactions) {
    const { title, path } = subRoutes.find(
      ({ permissionRequired }) =>
        permissionRequired === PERMISSION_TYPES.FUND_SWITCHING_TRANSACTIONS
    );
    fundSwitchingTransactionsLink = path;
    fundSwitchingTransactionsTitle = title;
  }

  const userStatusOptions = [
    { title: "-- SELECT USER STATUS --", value: "" },
    { title: "Active", value: "Active" },
    { title: "Inactive", value: "Inactive" },
  ];

  const [showFilters, setShowFilters] = useState(false);
  const onClickShowFilters = () => {
    setShowFilters((showFilters) => !showFilters);
  };

  return (
    <>
      <PageHeading {...props} reloadData={reloadData} />
      <FiltersButton onClick={onClickShowFilters} value={showFilters} />
      <StyledFiltersContainer className={showFilters ? "show" : ""}>
        <Form method="POST" action="#" onSubmit={filterData}>
          <Fieldset>
            <Label>Mobile Number</Label>
            <TextInput
              value={fMobileNumber}
              onChange={setFmobileNumber}
              placeholder="Mobile Number"
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Full Name</Label>
            <TextInput
              value={fName}
              onChange={setFname}
              placeholder="Full Name"
              canProcessFunds
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>User Status</Label>
            <Dropdown
              style={{ border: "1px solid #cacaca" }}
              placeholder="User Status"
              value={fUserStatus}
              onChange={setFuserStatus}
              options={userStatusOptions}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Button disabled={isLoading} type="submit">
              Filter
            </Button>
            <ResetButton
              disabled={isLoading}
              type="button"
              onClick={resetFilters}
            >
              Clear
            </ResetButton>
          </Fieldset>
        </Form>
      </StyledFiltersContainer>
      <LoadingSpinner
        isLoading={
          responseStatus === "" &&
          !showModal &&
          users?.length === 0 &&
          isLoading
        }
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILED &&
          !isLoading &&
          users?.length === 0
        }
        message={`No users found`}
      />
      {!showModal && responseStatus === API_RESPONSE_TYPES.FAILED && (
        <MessageBox status={responseStatus} message={responseMessage} />
      )}
      {users?.length > 0 && (
        <>
          <TableBuilder
            isLoading={!showModal && users?.length !== 0 && isLoading}
            tableHeadings={[
              {
                title: "",
                dataSelector: "id",
                align: "center",
                sticky: true,
                canSort: false,
                width: "100px",
                CellRenderer: (value, item) => (
                  <>
                    {canUpdate && (
                      <StyledLink
                        to={updateLink.replace(":id", value)}
                        title={updateTitle}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </StyledLink>
                    )}
                    {canReadLatestOTP && (
                      <CustomButton
                        icon={faAsterisk}
                        onClick={() => {
                          readAccountsLatestOTP(value);
                          setShowModal(true);
                          setModalType(PERMISSION_TYPES.READ_LATEST_OTP);
                        }}
                        style={{ color: "gray" }}
                      />
                    )}
                    {canReadWalletTransactions && (
                      <StyledLink
                        to={walletTransactionsLink.replace(":id", value)}
                        title={walletTransactionsTitle}
                        style={{ color: "orange" }}
                      >
                        <FontAwesomeIcon icon={faListCheck} />
                      </StyledLink>
                    )}
                    {canReadFundSwitchingTransactions && (
                      <StyledLink
                        to={fundSwitchingTransactionsLink.replace(":id", value)}
                        title={fundSwitchingTransactionsTitle}
                        style={{ color: "orange" }}
                      >
                        <FontAwesomeIcon icon={faList12} />
                      </StyledLink>
                    )}
                    {canProcessFunds && (
                      <CustomButton
                        icon={faIndianRupeeSign}
                        onClick={() => {
                          setUsersId(value);
                          setModalType(PERMISSION_TYPES.WALLET_PROCESS_FUNDS);
                          setShowModal(true);
                        }}
                      />
                    )}
                  </>
                ),
              },
              {
                title: "Photo",
                dataSelector: "photo",
                dataType: "string",
                canSort: false,
                CellRenderer: (value, item) => (
                  <Image
                    source={value ? value : "/no-image.png"}
                    alt={item?.fullName}
                    style={{ width: "50px", height: "50px" }}
                  />
                ),
              },
              {
                title: "Full Name",
                dataSelector: "fullName",
                dataType: "string",
                CellRenderer: (value, item) => (
                  <>
                    {value}
                    <StyledSpan>{item.mobileNumber}</StyledSpan>
                  </>
                ),
              },
              {
                title: "GST Seller Wallet",
                dataSelector: "gstSellerWallet",
                dataType: "number",
                CellRenderer: (value) => formatCurrency(value),
              },
              {
                title: "GST Buyer Wallet",
                dataSelector: "gstBuyerWallet",
                dataType: "number",
                CellRenderer: (value) => formatCurrency(value),
              },
              {
                title: "Non-GST Seller Wallet",
                dataSelector: "nonGstSellerWallet",
                dataType: "number",
                CellRenderer: (value) => formatCurrency(value),
              },
              {
                title: "Non-GST Buyer Wallet",
                dataSelector: "nonGstBuyerWallet",
                dataType: "number",
                CellRenderer: (value) => formatCurrency(value),
              },
              {
                title: "Topup Status",
                dataSelector: "topupStatus",
                dataType: "string",
                align: "center",
                canSort: false,
                CellRenderer: (value, data) => (
                  <Switch
                    value={value === "Active"}
                    onChange={(value) => updateData(value, data, "topupStatus")}
                  />
                ),
              },
              {
                title: "Withdraw Status",
                dataSelector: "withdrawStatus",
                dataType: "string",
                align: "center",
                canSort: false,
                CellRenderer: (value, data) => (
                  <Switch
                    value={value === "Active"}
                    onChange={(value) =>
                      updateData(value, data, "withdrawStatus")
                    }
                  />
                ),
              },
              {
                title: "Status",
                dataSelector: "userStatus",
                dataType: "string",
                align: "center",
                canSort: false,
                CellRenderer: (value, data) => (
                  <Switch
                    value={value === "Active"}
                    onChange={(value) => updateData(value, data, "userStatus")}
                  />
                ),
              },
              {
                title: "Temp Status",
                dataSelector: "tempUserStatus",
                dataType: "string",
                align: "center",
                canSort: false,
                CellRenderer: (value, data) => (
                  <Switch
                    value={value === "Active"}
                    onChange={(value) =>
                      updateData(value, data, "tempUserStatus")
                    }
                  />
                ),
              },
            ]}
            tableData={users}
          />
          <Pagination
            totalRecords={totalRecords}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
          />
          <Modal
            modalStatus={showModal}
            setModalStatus={() => {
              resetResponseData();
              resetForm();
              setShowModal(false);
              setModalType("");
              setUsersId("");
              setWallet("");
            }}
            hideCloseButton
          >
            <StyledModalContainer width={"500px"}>
              {modalType === PERMISSION_TYPES.READ_LATEST_OTP ? (
                <>
                  <h3>Users Latest OTP</h3>
                  <FormContainer>
                    <StyledOTPModal>
                      {isLoading ? (
                        <StyledOTPText>
                          Please wait, we are feting the data
                        </StyledOTPText>
                      ) : accountsLatestOtp?.length > 0 ? (
                        <StyledOTPText>
                          OTP is {accountsLatestOtp}
                        </StyledOTPText>
                      ) : (
                        <StyledOTPText>No OTP Found</StyledOTPText>
                      )}
                      <OverlayLoader showLoader={showModal && isLoading} />
                    </StyledOTPModal>
                  </FormContainer>
                </>
              ) : (
                <>
                  <h3>Add / Deduct Balance</h3>
                  <FormContainer>
                    <Form method="POST" action="#" onSubmit={onSubmit}>
                      <Fieldset>
                        <Label required>Wallet</Label>
                        <Dropdown
                          placeholder="Please select wallet"
                          value={wallet}
                          onChange={setWallet}
                          options={[
                            {
                              title: "-- SELECT WALLET --",
                              value: "",
                            },
                            {
                              title: "GST Seller Wallet",
                              value: "gstSellerWallet",
                            },
                            {
                              title: "GST Buyer Wallet",
                              value: "gstBuyerWallet",
                            },
                            {
                              title: "Non-GST Seller Wallet",
                              value: "nonGstSellerWallet",
                            },
                            {
                              title: "Non-GST Buyer Wallet",
                              value: "nonGstBuyerWallet",
                            },
                          ]}
                          disabled={isLoading === true}
                        />
                      </Fieldset>
                      <Fieldset>
                        <Label required>Transaction Type</Label>
                        <Dropdown
                          placeholder="Please select transaction type"
                          value={transactionType}
                          onChange={setTransactionType}
                          options={[
                            {
                              title: "-- SELECT TRANSACTION TYPE --",
                              value: "",
                            },
                            {
                              title: "Credit",
                              value: "Credit",
                            },
                            {
                              title: "Debit",
                              value: "Debit",
                            },
                          ]}
                          disabled={isLoading === true}
                        />
                      </Fieldset>
                      <Fieldset>
                        <Label required>Amount</Label>
                        <NumberInput
                          value={amount}
                          onChange={setAmount}
                          placeholder="Please enter amount"
                          maxLength={10}
                          disabled={isLoading === true}
                          pattern={`[0-9.]*`}
                        />
                        {amount && (
                          <StyledSpan style={{ color: "#000" }}>
                            {getAmountInWords(amount)}
                          </StyledSpan>
                        )}
                      </Fieldset>
                      <Fieldset>
                        <Label required>Remark</Label>
                        <TextInput
                          value={remark}
                          onChange={setRemark}
                          placeholder="Please enter remark"
                          disabled={isLoading === true}
                        />
                      </Fieldset>
                      {showModal && responseStatus !== "" && (
                        <Fieldset>
                          <MessageBox
                            status={responseStatus}
                            message={responseMessage}
                          />
                        </Fieldset>
                      )}
                      <Fieldset>
                        <CreateButton
                          disabled={isLoading === true}
                          type="submit"
                          style={{ width: "auto" }}
                        >
                          Submit
                        </CreateButton>
                        <ResetButton
                          disabled={isLoading === true}
                          type="button"
                          onClick={resetForm}
                        >
                          Reset
                        </ResetButton>
                      </Fieldset>
                      <OverlayLoader showLoader={showModal && isLoading} />
                    </Form>
                  </FormContainer>
                </>
              )}
            </StyledModalContainer>
          </Modal>
        </>
      )}
    </>
  );
};

export default withAppHOC(UsersList);
