import React, { useState, useEffect, useCallback } from "react";
import { withAppHOC } from "../../../hoc";
import PageHeading from "../../../components/PageHeader/PageHeader";
import OverlayLoader from "../../../components/OverlayLoader/OverlayLoader";
import { Grid, GridItem } from "../../../components/Grid";
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  MessageBox,
  UpdateButton,
  ResetButton,
} from "../../../components/FormElements";
import API from "../../../api";
import { API_RESPONSE_TYPES } from "../../../constants";

const FirmDetails = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [firmName, setFirmName] = useState("");
  const [firmAddress, setFirmAddress] = useState("");
  const [gstNumber, setGstNumber] = useState("");
  const [supportNumber, setSupportNumber] = useState("");
  const [supportEmail, setSupportEmail] = useState("");
  const [invoicePrefix, setInvoicePrefix] = useState("");
  const [invoiceDescription, setInvoiceDescription] = useState("");
  const [invoiceHsnCode, setInvoiceHsnCode] = useState("");
  const [originalFormData, setOriginalFormData] = useState({});

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus("");
    setResponseMessage("");
  };

  const resetForm = () => {
    const {
      firmName,
      firmAddress,
      gstNumber,
      supportNumber,
      supportEmail,
      invoicePrefix,
      invoiceDescription,
      invoiceHsnCode,
    } = originalFormData;
    setFirmName(firmName);
    setFirmAddress(firmAddress);
    setGstNumber(gstNumber);
    setSupportNumber(supportNumber);
    setSupportEmail(supportEmail);
    setInvoicePrefix(invoicePrefix);
    setInvoiceDescription(invoiceDescription);
    setInvoiceHsnCode(invoiceHsnCode);
  };

  const readData = useCallback(() => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.get(`/configurations/firm-details`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILED) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          const {
            firmName,
            firmAddress,
            gstNumber,
            supportNumber,
            supportEmail,
            invoicePrefix,
            invoiceDescription,
            invoiceHsnCode,
          } = data;
          setFirmName(firmName);
          setFirmAddress(firmAddress);
          setGstNumber(gstNumber);
          setSupportNumber(supportNumber);
          setSupportEmail(supportEmail);
          setInvoicePrefix(invoicePrefix);
          setInvoiceDescription(invoiceDescription);
          setInvoiceHsnCode(invoiceHsnCode);
          setOriginalFormData({
            firmName,
            firmAddress,
            gstNumber,
            supportNumber,
            supportEmail,
            invoicePrefix,
            invoiceDescription,
            invoiceHsnCode,
          });
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILED);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    const formData = {
      firmName,
      firmAddress,
      gstNumber,
      supportNumber,
      supportEmail,
      invoicePrefix,
      invoiceDescription,
      invoiceHsnCode,
    };
    API.put(`/configurations/firm-details`, formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setOriginalFormData(formData);
          setTimeout(() => {
            resetResponseData();
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILED);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    readData();
  }, [readData]);

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method="POST" action="#" onSubmit={onSubmit}>
          <Grid columns={"calc(50% - 10px) calc(50% - 10px)"}>
            <GridItem>
              <Fieldset>
                <Label>Firm Name</Label>
                <TextInput
                  value={firmName}
                  onChange={setFirmName}
                  placeholder="Please enter firm name"
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>GST Number</Label>
                <TextInput
                  value={gstNumber}
                  onChange={setGstNumber}
                  placeholder="Please enter gst number"
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Fieldset>
            <Label>Firm Address</Label>
            <TextInput
              value={firmAddress}
              onChange={setFirmAddress}
              placeholder="Please enter firm address"
              disabled={isLoading}
            />
          </Fieldset>
          <Grid columns={"calc(50% - 10px) calc(50% - 10px)"}>
            <GridItem>
              <Fieldset>
                <Label>Support Number</Label>
                <TextInput
                  value={supportNumber}
                  onChange={setSupportNumber}
                  placeholder="Please enter support number"
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Support Email</Label>
                <TextInput
                  value={supportEmail}
                  onChange={setSupportEmail}
                  placeholder="Please enter support email"
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid columns={"calc(50% - 10px) calc(50% - 10px)"}>
            <GridItem>
              <Fieldset>
                <Label>Invoice Prefix</Label>
                <TextInput
                  value={invoicePrefix}
                  onChange={setInvoicePrefix}
                  placeholder="Please enter invoice prefix"
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>HSN Code</Label>
                <TextInput
                  value={invoiceHsnCode}
                  onChange={setInvoiceHsnCode}
                  placeholder="Please enter hsn code"
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Fieldset>
            <Label>Invoice Description</Label>
            <TextInput
              value={invoiceDescription}
              onChange={setInvoiceDescription}
              placeholder="Please enter invoice description"
              disabled={isLoading}
            />
          </Fieldset>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <UpdateButton disabled={isLoading} type="submit">
              Update
            </UpdateButton>
            <ResetButton
              disabled={isLoading}
              type="button"
              onClick={resetForm}
            >
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(FirmDetails);
