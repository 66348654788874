import React, { useState, useEffect, useCallback } from "react";
import { withAppHOC } from "../../../hoc";
import PageHeading from "../../../components/PageHeader/PageHeader";
import OverlayLoader from "../../../components/OverlayLoader/OverlayLoader";
import { Grid, GridItem } from "../../../components/Grid";
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  Dropdown,
  MessageBox,
  UpdateButton,
  ResetButton,
  Legend,
} from "../../../components/FormElements";
import { StyledMicroButton } from "../../../components/Styled";
import API from "../../../api";
import { API_RESPONSE_TYPES } from "../../../constants";

const PlanAPIConfiguration = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [numberDetailsApiEndpoint, setNumberDetailsApiEndpoint] = useState("");
  const [numberDetailsApiRequestMethod, setNumberDetailsApiRequestMethod] =
    useState("");
  const [numberDetailsApiRequestBody, setNumberDetailsApiRequestBody] =
    useState("");
  const [numberDetailsApiResponseType, setNumberDetailsApiResponseType] =
    useState("");
  const [numberDetailsApiStatusKey, setNumberDetailsApiStatusKey] =
    useState("");
  const [numberDetailsApiSuccessKey, setNumberDetailsApiSuccessKey] =
    useState("");
  const [numberDetailsApiOperatorKey, setNumberDetailsApiOperatorKey] =
    useState("");
  const [numberDetailsApiCircleKey, setNumberDetailsApiCircleKey] =
    useState("");
  const [mobileRoffersApiEndpoint, setMobileRoffersApiEndpoint] = useState("");
  const [mobileRoffersBody, setMobileRoffersBody] = useState("");
  const [mobileRoffersMethod, setMobileRoffersMethod] = useState("");
  const [mobileRoffersStatusKey, setMobileRoffersStatusKey] = useState("");
  const [mobileRoffersSuccessValue, setMobileRoffersSuccessValue] =
    useState("");
  const [mobileRoffersFailedValue, setMobileRoffersFailedValue] = useState("");
  const [mobileRoffersResponseType, setMobileRoffersResponseType] =
    useState("");
  const [mobileRoffersRoffersKey, setMobileRoffersRoffersKey] = useState("");
  const [mobileRoffersAmountKey, setMobileRoffersAmountKey] = useState("");
  const [mobileRoffersValidityKey, setMobileRoffersValidityKey] = useState("");
  const [mobileRoffersDescriptionKey, setMobileRoffersDescriptionKey] =
    useState("");
  const [mobileRoffersCommissionKey, setMobileRoffersCommissionKey] =
    useState("");
  const [originalFormData, setOriginalFormData] = useState({});

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus("");
    setResponseMessage("");
  };

  const resetForm = () => {
    const {
      numberDetailsApiEndpoint,
      numberDetailsApiRequestMethod,
      numberDetailsApiRequestBody,
      numberDetailsApiResponseType,
      numberDetailsApiStatusKey,
      numberDetailsApiSuccessKey,
      numberDetailsApiOperatorKey,
      numberDetailsApiCircleKey,
      mobileRoffersApiEndpoint,
      mobileRoffersBody,
      mobileRoffersMethod,
      mobileRoffersStatusKey,
      mobileRoffersSuccessValue,
      mobileRoffersFailedValue,
      mobileRoffersResponseType,
      mobileRoffersRoffersKey,
      mobileRoffersAmountKey,
      mobileRoffersValidityKey,
      mobileRoffersDescriptionKey,
      mobileRoffersCommissionKey,
    } = originalFormData;
    setNumberDetailsApiEndpoint(numberDetailsApiEndpoint);
    setNumberDetailsApiRequestMethod(numberDetailsApiRequestMethod);
    setNumberDetailsApiRequestBody(numberDetailsApiRequestBody);
    setNumberDetailsApiResponseType(numberDetailsApiResponseType);
    setNumberDetailsApiStatusKey(numberDetailsApiStatusKey);
    setNumberDetailsApiSuccessKey(numberDetailsApiSuccessKey);
    setNumberDetailsApiOperatorKey(numberDetailsApiOperatorKey);
    setNumberDetailsApiCircleKey(numberDetailsApiCircleKey);
    setMobileRoffersApiEndpoint(mobileRoffersApiEndpoint);
    setMobileRoffersBody(mobileRoffersBody);
    setMobileRoffersMethod(mobileRoffersMethod);
    setMobileRoffersStatusKey(mobileRoffersStatusKey);
    setMobileRoffersSuccessValue(mobileRoffersSuccessValue);
    setMobileRoffersFailedValue(mobileRoffersFailedValue);
    setMobileRoffersResponseType(mobileRoffersResponseType);
    setMobileRoffersRoffersKey(mobileRoffersRoffersKey);
    setMobileRoffersAmountKey(mobileRoffersAmountKey);
    setMobileRoffersValidityKey(mobileRoffersValidityKey);
    setMobileRoffersDescriptionKey(mobileRoffersDescriptionKey);
    setMobileRoffersCommissionKey(mobileRoffersCommissionKey);
  };

  const readData = useCallback(() => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.get(`/recharge-configurations/plan-api-configuration`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILED) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          const {
            numberDetailsApiEndpoint,
            numberDetailsApiRequestMethod,
            numberDetailsApiRequestBody,
            numberDetailsApiResponseType,
            numberDetailsApiStatusKey,
            numberDetailsApiSuccessKey,
            numberDetailsApiOperatorKey,
            numberDetailsApiCircleKey,
            mobileRoffersApiEndpoint,
            mobileRoffersBody,
            mobileRoffersMethod,
            mobileRoffersStatusKey,
            mobileRoffersSuccessValue,
            mobileRoffersFailedValue,
            mobileRoffersResponseType,
            mobileRoffersRoffersKey,
            mobileRoffersAmountKey,
            mobileRoffersValidityKey,
            mobileRoffersDescriptionKey,
            mobileRoffersCommissionKey,
          } = data;
          setNumberDetailsApiEndpoint(numberDetailsApiEndpoint);
          setNumberDetailsApiRequestMethod(numberDetailsApiRequestMethod);
          setNumberDetailsApiRequestBody(numberDetailsApiRequestBody);
          setNumberDetailsApiResponseType(numberDetailsApiResponseType);
          setNumberDetailsApiStatusKey(numberDetailsApiStatusKey);
          setNumberDetailsApiSuccessKey(numberDetailsApiSuccessKey);
          setNumberDetailsApiOperatorKey(numberDetailsApiOperatorKey);
          setNumberDetailsApiCircleKey(numberDetailsApiCircleKey);
          setMobileRoffersApiEndpoint(mobileRoffersApiEndpoint);
          setMobileRoffersBody(mobileRoffersBody);
          setMobileRoffersMethod(mobileRoffersMethod);
          setMobileRoffersStatusKey(mobileRoffersStatusKey);
          setMobileRoffersSuccessValue(mobileRoffersSuccessValue);
          setMobileRoffersFailedValue(mobileRoffersFailedValue);
          setMobileRoffersResponseType(mobileRoffersResponseType);
          setMobileRoffersRoffersKey(mobileRoffersRoffersKey);
          setMobileRoffersAmountKey(mobileRoffersAmountKey);
          setMobileRoffersValidityKey(mobileRoffersValidityKey);
          setMobileRoffersDescriptionKey(mobileRoffersDescriptionKey);
          setMobileRoffersCommissionKey(mobileRoffersCommissionKey);
          setOriginalFormData({
            numberDetailsApiEndpoint,
            numberDetailsApiRequestMethod,
            numberDetailsApiRequestBody,
            numberDetailsApiResponseType,
            numberDetailsApiStatusKey,
            numberDetailsApiSuccessKey,
            numberDetailsApiOperatorKey,
            numberDetailsApiCircleKey,
            mobileRoffersApiEndpoint,
            mobileRoffersBody,
            mobileRoffersMethod,
            mobileRoffersStatusKey,
            mobileRoffersSuccessValue,
            mobileRoffersFailedValue,
            mobileRoffersResponseType,
            mobileRoffersRoffersKey,
            mobileRoffersAmountKey,
            mobileRoffersValidityKey,
            mobileRoffersDescriptionKey,
            mobileRoffersCommissionKey,
          });
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILED);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    const formData = {
      numberDetailsApiEndpoint,
      numberDetailsApiRequestMethod,
      numberDetailsApiRequestBody,
      numberDetailsApiResponseType,
      numberDetailsApiStatusKey,
      numberDetailsApiSuccessKey,
      numberDetailsApiOperatorKey,
      numberDetailsApiCircleKey,
      mobileRoffersApiEndpoint,
      mobileRoffersBody,
      mobileRoffersMethod,
      mobileRoffersStatusKey,
      mobileRoffersSuccessValue,
      mobileRoffersFailedValue,
      mobileRoffersResponseType,
      mobileRoffersRoffersKey,
      mobileRoffersAmountKey,
      mobileRoffersValidityKey,
      mobileRoffersDescriptionKey,
      mobileRoffersCommissionKey,
    };
    API.put(`/recharge-configurations/plan-api-configuration`, formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setOriginalFormData(formData);
          setTimeout(() => {
            resetResponseData();
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILED);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const addText = (setMethod, value) => {
    setMethod((currentValue) => currentValue + value);
  };

  useEffect(() => {
    readData();
  }, [readData]);

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method="POST" action="#" onSubmit={onSubmit}>
          {/* Number Details */}
          <Fieldset forLegend>
            <Legend>Number Details API</Legend>
            <Fieldset>
              <Label>API Endpoint</Label>
              <TextInput
                value={numberDetailsApiEndpoint}
                onChange={setNumberDetailsApiEndpoint}
                placeholder="Please enter api endpoint"
                disabled={isLoading}
              />
            </Fieldset>
            <Fieldset>
              <Label>Request Body</Label>
              <TextInput
                value={numberDetailsApiRequestBody}
                onChange={setNumberDetailsApiRequestBody}
                placeholder="Please enter request body"
                disabled={isLoading}
              />
              <StyledMicroButton
                disabled={isLoading}
                type="button"
                onClick={() =>
                  addText(setNumberDetailsApiRequestBody, "{{{NUMBER}}}")
                }
              >
                Mobile Number
              </StyledMicroButton>
              <StyledMicroButton
                disabled={isLoading}
                type="button"
                onClick={() =>
                  addText(setNumberDetailsApiRequestBody, "{{{OPERATOR}}}")
                }
              >
                Operator
              </StyledMicroButton>
              <StyledMicroButton
                disabled={isLoading}
                type="button"
                onClick={() =>
                  addText(setNumberDetailsApiRequestBody, "{{{CIRCLE}}}")
                }
              >
                Circle
              </StyledMicroButton>
            </Fieldset>
            <Grid columns={"calc(50% - 10px) calc(50% - 10px)"}>
              <GridItem>
                <Fieldset>
                  <Label>Request Method</Label>
                  <Dropdown
                    value={numberDetailsApiRequestMethod}
                    onChange={setNumberDetailsApiRequestMethod}
                    placeholder="Please select request method"
                    disabled={isLoading}
                    options={[
                      {
                        title: "-- SELECT API REQUEST METHOD --",
                        value: "",
                      },
                      {
                        title: "POST",
                        value: "POST",
                      },
                      {
                        title: "GET",
                        value: "GET",
                      },
                    ]}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Response Type</Label>
                  <Dropdown
                    value={numberDetailsApiResponseType}
                    onChange={setNumberDetailsApiResponseType}
                    placeholder="Please select response type"
                    disabled={isLoading}
                    options={[
                      {
                        title: "-- SELECT API RESPONSE TYPE --",
                        value: "",
                      },
                      {
                        title: "JSON",
                        value: "JSON",
                      },
                      {
                        title: "XML",
                        value: "XML",
                      },
                    ]}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
            <Grid columns={"calc(50% - 10px) calc(50% - 10px)"}>
              <GridItem>
                <Fieldset>
                  <Label>Status Key</Label>
                  <TextInput
                    value={numberDetailsApiStatusKey}
                    onChange={setNumberDetailsApiStatusKey}
                    placeholder="Please enter status key"
                    disabled={isLoading}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Success Key</Label>
                  <TextInput
                    value={numberDetailsApiSuccessKey}
                    onChange={setNumberDetailsApiSuccessKey}
                    placeholder="Please enter success key"
                    disabled={isLoading}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
            <Grid columns={"calc(50% - 10px) calc(50% - 10px)"}>
              <GridItem>
                <Fieldset>
                  <Label>Operator Key</Label>
                  <TextInput
                    value={numberDetailsApiOperatorKey}
                    onChange={setNumberDetailsApiOperatorKey}
                    placeholder="Please enter operator key"
                    disabled={isLoading}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Circle Key</Label>
                  <TextInput
                    value={numberDetailsApiCircleKey}
                    onChange={setNumberDetailsApiCircleKey}
                    placeholder="Please enter circle key"
                    disabled={isLoading}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
          </Fieldset>
          {/* Mobile Roffers */}
          <Fieldset forLegend>
            <Legend>Mobile Roffers API</Legend>
            <Fieldset>
              <Label>API Endpoint</Label>
              <TextInput
                value={mobileRoffersApiEndpoint}
                onChange={setMobileRoffersApiEndpoint}
                placeholder="Please enter api endpoint"
                disabled={isLoading}
              />
            </Fieldset>
            <Fieldset>
              <Label>Request Body</Label>
              <TextInput
                value={mobileRoffersBody}
                onChange={setMobileRoffersBody}
                placeholder="Please enter request body"
                disabled={isLoading}
              />
              <StyledMicroButton
                disabled={isLoading}
                type="button"
                onClick={() => addText(setMobileRoffersBody, "{{{NUMBER}}}")}
              >
                Mobile Number
              </StyledMicroButton>
              <StyledMicroButton
                disabled={isLoading}
                type="button"
                onClick={() => addText(setMobileRoffersBody, "{{{OPERATOR}}}")}
              >
                Operator
              </StyledMicroButton>
              <StyledMicroButton
                disabled={isLoading}
                type="button"
                onClick={() => addText(setMobileRoffersBody, "{{{CIRCLE}}}")}
              >
                Circle
              </StyledMicroButton>
            </Fieldset>
            <Grid columns={"calc(50% - 10px) calc(50% - 10px)"}>
              <GridItem>
                <Fieldset>
                  <Label>Request Method</Label>
                  <Dropdown
                    value={mobileRoffersMethod}
                    onChange={setMobileRoffersMethod}
                    placeholder="Please select request method"
                    disabled={isLoading}
                    options={[
                      {
                        title: "-- SELECT API REQUEST METHOD --",
                        value: "",
                      },
                      {
                        title: "POST",
                        value: "POST",
                      },
                      {
                        title: "GET",
                        value: "GET",
                      },
                    ]}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Response Type</Label>
                  <Dropdown
                    value={mobileRoffersResponseType}
                    onChange={setMobileRoffersResponseType}
                    placeholder="Please select response type"
                    disabled={isLoading}
                    options={[
                      {
                        title: "-- SELECT API RESPONSE TYPE --",
                        value: "",
                      },
                      {
                        title: "JSON",
                        value: "JSON",
                      },
                      {
                        title: "XML",
                        value: "XML",
                      },
                    ]}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
            <Grid
              columns={
                "calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)"
              }
            >
              <GridItem>
                <Fieldset>
                  <Label>Status Key</Label>
                  <TextInput
                    value={mobileRoffersStatusKey}
                    onChange={setMobileRoffersStatusKey}
                    placeholder="Please enter status key"
                    disabled={isLoading}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Success Value</Label>
                  <TextInput
                    value={mobileRoffersSuccessValue}
                    onChange={setMobileRoffersSuccessValue}
                    placeholder="Please enter success value"
                    disabled={isLoading}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Failed Value</Label>
                  <TextInput
                    value={mobileRoffersFailedValue}
                    onChange={setMobileRoffersFailedValue}
                    placeholder="Please enter failed value"
                    disabled={isLoading}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
            <Grid
              columns={
                "calc(25% - 15px) calc(25% - 15px) calc(25% - 15px) calc(25% - 15px)"
              }
            >
              <GridItem>
                <Fieldset>
                  <Label>Roffers Key</Label>
                  <TextInput
                    value={mobileRoffersRoffersKey}
                    onChange={setMobileRoffersRoffersKey}
                    placeholder="Please enter roffers key"
                    disabled={isLoading}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Amount Key</Label>
                  <TextInput
                    value={mobileRoffersAmountKey}
                    onChange={setMobileRoffersAmountKey}
                    placeholder="Please enter amount key"
                    disabled={isLoading}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Validity Key</Label>
                  <TextInput
                    value={mobileRoffersValidityKey}
                    onChange={setMobileRoffersValidityKey}
                    placeholder="Please enter validity key"
                    disabled={isLoading}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Description Key</Label>
                  <TextInput
                    value={mobileRoffersDescriptionKey}
                    onChange={setMobileRoffersDescriptionKey}
                    placeholder="Please enter description key"
                    disabled={isLoading}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
            <Fieldset>
              <Label>Commission Key</Label>
              <TextInput
                value={mobileRoffersCommissionKey}
                onChange={setMobileRoffersCommissionKey}
                placeholder="Please enter commission key"
                disabled={isLoading}
              />
            </Fieldset>
          </Fieldset>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <UpdateButton disabled={isLoading} type="submit">
              Update
            </UpdateButton>
            <ResetButton disabled={isLoading} type="button" onClick={resetForm}>
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(PlanAPIConfiguration);
