import {
  faHome,
  faUsers,
  faGears,
  faIndianRupeeSign,
  faChartPie,
  faFileInvoice,
} from "@fortawesome/free-solid-svg-icons";
import {
  MASTER_MODULES,
  MODULES,
  ROUTE_TYPES,
  PERMISSION_TYPES,
  VIEW_TYPES,
} from "./index";
import Login from "../containers/Auth/Login";
import ForgotPassword from "../containers/Auth/ForgotPassword";
import Profile from "../containers/Auth/Profile";
import ChangePassword from "../containers/Auth/ChangePassword";
import Sessions from "../containers/Auth/Sessions";
import Logs from "../containers/Auth/Logs";
import Dashboard from "../containers/Dashboard/Dashboard";
// Accounts Management
import UsersList from "../containers/AccountsManagement/Users/UsersList";
import UsersCreate from "../containers/AccountsManagement/Users/UsersCreate";
import UsersUpdate from "../containers/AccountsManagement/Users/UsersUpdate";
import UsersWalletTransactionsList from "../containers/AccountsManagement/Users/UsersWalletTransactionsList";
import UsersFundSwitchingTransactionsList from "../containers/AccountsManagement/Users/UsersFundSwitchingTransactionsList";
import UsersKYCList from "../containers/AccountsManagement/Users/UsersKYCList";
import UsersBankAccountsList from "../containers/AccountsManagement/Users/UsersBankAccountsList";
import UsersFundRequestsList from "../containers/AccountsManagement/Users/UsersFundRequestsList";
import UsersWithdrawRequestsList from "../containers/AccountsManagement/Users/UsersWithdrawRequestsList";
import StaffAccountsList from "../containers/AccountsManagement/StaffAccounts/StaffAccountsList";
import StaffAccountsCreate from "../containers/AccountsManagement/StaffAccounts/StaffAccountsCreate";
import StaffAccountsUpdate from "../containers/AccountsManagement/StaffAccounts/StaffAccountsUpdate";
import RolesAndPermissionsList from "../containers/AccountsManagement/RolesAndPermissions/RolesAndPermissionsList";
import RolesAndPermissionsCreate from "../containers/AccountsManagement/RolesAndPermissions/RolesAndPermissionsCreate";
import RolesAndPermissionsUpdate from "../containers/AccountsManagement/RolesAndPermissions/RolesAndPermissionsUpdate";
// Configurations
import FirmDetails from "../containers/Configurations/FirmDetails/FirmDetails";
import AppConfiguration from "../containers/Configurations/AppConfiguration/AppConfiguration";
import BankAccountsList from "../containers/Configurations/BankAccounts/BankAccountsList";
import BankAccountsCreate from "../containers/Configurations/BankAccounts/BankAccountsCreate";
import BankAccountsUpdate from "../containers/Configurations/BankAccounts/BankAccountsUpdate";
import ScrollMessage from "../containers/Configurations/ScrollMessage/ScrollMessage";
import SmsAPIConfiguration from "../containers/Configurations/SmsAPIConfiguration/SmsAPIConfiguration";
import WhatsappAPIConfiguration from "../containers/Configurations/WhatsappAPIConfiguration/WhatsappAPIConfiguration";
// Recharge Configurations
import CirclesList from "../containers/RechargeConfigurations/Circles/CirclesList";
import CirclesCreate from "../containers/RechargeConfigurations/Circles/CirclesCreate";
import CirclesUpdate from "../containers/RechargeConfigurations/Circles/CirclesUpdate";
import AdminOperatorMarginList from "../containers/RechargeConfigurations/AdminOperatorMargin/AdminOperatorMarginList";
import AdminOperatorMarginCreate from "../containers/RechargeConfigurations/AdminOperatorMargin/AdminOperatorMarginCreate";
import AdminOperatorMarginUpdate from "../containers/RechargeConfigurations/AdminOperatorMargin/AdminOperatorMarginUpdate";
import OperatorsList from "../containers/RechargeConfigurations/Operators/OperatorsList";
import OperatorsCreate from "../containers/RechargeConfigurations/Operators/OperatorsCreate";
import OperatorsUpdate from "../containers/RechargeConfigurations/Operators/OperatorsUpdate";
import PlanAPIConfiguration from "../containers/RechargeConfigurations/PlanAPIConfiguration/PlanAPIConfiguration";
import SellerAPISList from "../containers/RechargeConfigurations/SellerAPIS/SellerAPISList";
import SellerAPISCreate from "../containers/RechargeConfigurations/SellerAPIS/SellerAPISCreate";
import SellerAPISUpdate from "../containers/RechargeConfigurations/SellerAPIS/SellerAPISUpdate";
import OperatorBlockerList from "../containers/RechargeConfigurations/OperatorBlocker/OperatorBlockerList";
import OperatorBlockerCreate from "../containers/RechargeConfigurations/OperatorBlocker/OperatorBlockerCreate";
import BuyerMarginsList from "../containers/RechargeConfigurations/Margins/BuyerMarginsList";
import SellerMarginsList from "../containers/RechargeConfigurations/Margins/SellerMarginsList";
// Transactions
import BuyerRechargeTransactionsList from "../containers/Transactions/BuyerRechargeTransactionsList";
import SellerRechargeTransactionsList from "../containers/Transactions/SellerRechargeTransactionsList";
import PendingRechargeTransactionsList from "../containers/Transactions/PendingRechargeTransactionsList";
import DisputedRechargeTransactionsList from "../containers/Transactions/DisputedRechargeTransactionsList";
import LiveRechargeTransactionsList from "../containers/Transactions/LiveRechargeTransactionsList";
// Reports
import BuyersOperatorDayBook from "../containers/Reports/BuyersOperatorDayBook";
import SellersOperatorDayBook from "../containers/Reports/SellersOperatorDayBook";
import Daybook from "../containers/Reports/Daybook";
// Invoicing
import BuyerInvoices from "../containers/Invoicing/BuyerInvoices";
import SellerInvoices from "../containers/Invoicing/SellerInvoices";

const { ALLOW_IF_LOGGEDIN, ALLOW_IF_NOT_LOGGEDIN } = ROUTE_TYPES;
const {
  ACCOUNTS_MANAGEMENT,
  CONFIGURATIONS,
  RECHARGE_CONFIGURATIONS,
  TRANSACTIONS,
  REPORTS,
  INVOICING,
} = MASTER_MODULES;
const {
  // Accounts Management - ACCOUNTS_MANAGEMENT
  USERS,
  USERS_KYC,
  USERS_BANK_ACCOUNTS,
  USERS_FUND_REQUESTS,
  USERS_WITHDRAW_REQUESTS,
  STAFF_ACCOUNTS,
  ROLES_AND_PERMISSIONS,
  // Configurations - CONFIGURATIONS
  FIRM_DETAILS,
  APP_CONFIGURATION,
  BANK_ACCOUNTS,
  SCROLL_MESSAGE,
  SMS_API,
  WHATSAPP_API,
  // Recharge Configurations - RECHARGE_CONFIGURATIONS
  CIRCLES,
  OPERATORS,
  ADMIN_OPERATOR_MARGINS,
  PLAN_API_CONFIGURATION,
  SELLER_APIS,
  OPERATORS_BLOCKER,
  BUYER_MARGINS,
  SELLER_MARGINS,
  // Transactions - TRANSACTIONS
  BUYER_RECHARGE_TRANSACTIONS,
  SELLER_RECHARGE_TRANSACTIONS,
  PENDING_RECHARGE_TRANSACTIONS,
  DISPUTED_RECHARGE_TRANSACTIONS,
  LIVE_RECHARGE_TRANSACTIONS,
  // Reports - REPORTS
  BUYERS_OPERATORS_DAY_BOOK,
  SELLERS_OPERATORS_DAY_BOOK,
  DAY_BOOK,
  // Invoicing - INVOICING
  BUYER_INVOICES,
  SELLER_INVOICES,
} = MODULES;
const {
  CREATE,
  READ,
  UPDATE,
  WALLET_TRANSACTIONS,
  FUND_SWITCHING_TRANSACTIONS,
} = PERMISSION_TYPES;
const { TABLE, GRID } = VIEW_TYPES;

const ROUTES = [
  {
    path: "/",
    title: "Dashboard",
    icon: faHome,
    routeType: ALLOW_IF_LOGGEDIN,
    component: Dashboard,
    hideHeading: true,
    hideBreadcrumbs: true,
    exact: true,
  },
  {
    path: "/login",
    title: "Login",
    routeType: ALLOW_IF_NOT_LOGGEDIN,
    component: Login,
    hideInNavigation: true,
  },
  {
    path: "/forgot-password",
    title: "Forgot Password",
    routeType: ALLOW_IF_NOT_LOGGEDIN,
    component: ForgotPassword,
    hideInNavigation: true,
  },
  {
    path: "/profile",
    title: "My Profile",
    showInHeader: true,
    routeType: ALLOW_IF_LOGGEDIN,
    component: Profile,
    hideInNavigation: true,
  },
  {
    path: "/change-password",
    title: "Change Password",
    showInHeader: true,
    routeType: ALLOW_IF_LOGGEDIN,
    component: ChangePassword,
    hideInNavigation: true,
  },
  {
    path: "/sessions",
    title: "My Sessions",
    showInHeader: true,
    routeType: ALLOW_IF_LOGGEDIN,
    component: Sessions,
    hideInNavigation: true,
    viewType: TABLE,
    accountsPermissions: [READ],
  },
  {
    path: "/logs",
    title: "My Logs",
    showInHeader: true,
    routeType: ALLOW_IF_LOGGEDIN,
    component: Logs,
    hideInNavigation: true,
    viewType: TABLE,
    accountsPermissions: [READ],
  },
  // Accounts Management
  {
    path: "/accounts-management",
    title: "Accounts Management",
    icon: faUsers,
    routeType: ALLOW_IF_LOGGEDIN,
    masterModuleName: ACCOUNTS_MANAGEMENT,
    isDummy: true,
    subRoutes: [
      {
        path: "/accounts-management/users",
        title: "Users",
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: USERS,
        permissionRequired: READ,
        component: UsersList,
        viewType: TABLE,
        masterModule: ACCOUNTS_MANAGEMENT,
        subRoutes: [
          {
            path: "/accounts-management/users/create",
            title: "Create Users",
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: USERS,
            permissionRequired: CREATE,
            component: UsersCreate,
            viewType: GRID,
            masterModule: ACCOUNTS_MANAGEMENT,
            hideInNavigation: true,
          },
          {
            path: "/accounts-management/users/update/:id",
            title: "Update Users",
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: USERS,
            permissionRequired: UPDATE,
            component: UsersUpdate,
            viewType: GRID,
            masterModule: ACCOUNTS_MANAGEMENT,
            hideInNavigation: true,
          },
          {
            path: "/accounts-management/users/wallet-transactions/:id",
            title: "Wallet Transactions",
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: USERS,
            permissionRequired: WALLET_TRANSACTIONS,
            component: UsersWalletTransactionsList,
            viewType: GRID,
            masterModule: ACCOUNTS_MANAGEMENT,
            hideInNavigation: true,
          },
          {
            path: "/accounts-management/users/fund-switching-transactions/:id",
            title: "Fund Switching Transactions",
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: USERS,
            permissionRequired: FUND_SWITCHING_TRANSACTIONS,
            component: UsersFundSwitchingTransactionsList,
            viewType: GRID,
            masterModule: ACCOUNTS_MANAGEMENT,
            hideInNavigation: true,
          },
        ],
      },
      {
        path: "/accounts-management/users-kyc",
        title: "Users KYC",
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: USERS_KYC,
        permissionRequired: READ,
        component: UsersKYCList,
        viewType: TABLE,
        masterModule: ACCOUNTS_MANAGEMENT,
      },
      {
        path: "/accounts-management/users-bank-accounts",
        title: "Users Bank Accounts",
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: USERS_BANK_ACCOUNTS,
        permissionRequired: READ,
        component: UsersBankAccountsList,
        viewType: TABLE,
        masterModule: ACCOUNTS_MANAGEMENT,
      },
      {
        path: "/accounts-management/users-fund-requests",
        title: "Users Fund Requests",
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: USERS_FUND_REQUESTS,
        permissionRequired: READ,
        component: UsersFundRequestsList,
        viewType: TABLE,
        masterModule: ACCOUNTS_MANAGEMENT,
      },
      {
        path: "/accounts-management/users-withdraw-requests",
        title: "Users Withdraw Requests",
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: USERS_WITHDRAW_REQUESTS,
        permissionRequired: READ,
        component: UsersWithdrawRequestsList,
        viewType: TABLE,
        masterModule: ACCOUNTS_MANAGEMENT,
      },
      {
        path: "/accounts-management/staff-accounts",
        title: "Staff Accounts",
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: STAFF_ACCOUNTS,
        permissionRequired: READ,
        component: StaffAccountsList,
        viewType: TABLE,
        masterModule: ACCOUNTS_MANAGEMENT,
        subRoutes: [
          {
            path: "/accounts-management/staff-accounts/create",
            title: "Create Staff Account",
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: STAFF_ACCOUNTS,
            permissionRequired: CREATE,
            component: StaffAccountsCreate,
            viewType: GRID,
            masterModule: ACCOUNTS_MANAGEMENT,
            hideInNavigation: true,
          },
          {
            path: "/accounts-management/staff-accounts/update/:id",
            title: "Update Staff Account",
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: STAFF_ACCOUNTS,
            permissionRequired: UPDATE,
            component: StaffAccountsUpdate,
            viewType: GRID,
            masterModule: ACCOUNTS_MANAGEMENT,
            hideInNavigation: true,
          },
        ],
      },
      {
        path: "/accounts-management/roles-and-permissions",
        title: "Roles & Permissions",
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: ROLES_AND_PERMISSIONS,
        permissionRequired: READ,
        component: RolesAndPermissionsList,
        viewType: TABLE,
        masterModule: ACCOUNTS_MANAGEMENT,
        subRoutes: [
          {
            path: "/accounts-management/roles-and-permissions/create",
            title: "Create Roles & Permissions",
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: ROLES_AND_PERMISSIONS,
            permissionRequired: CREATE,
            component: RolesAndPermissionsCreate,
            viewType: GRID,
            masterModule: ACCOUNTS_MANAGEMENT,
            hideInNavigation: true,
          },
          {
            path: "/accounts-management/roles-and-permissions/update/:id",
            title: "Update Roles & Permissions",
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: ROLES_AND_PERMISSIONS,
            permissionRequired: UPDATE,
            component: RolesAndPermissionsUpdate,
            viewType: GRID,
            masterModule: ACCOUNTS_MANAGEMENT,
            hideInNavigation: true,
          },
        ],
      },
    ],
  },
  // Configurations
  {
    path: "/configurations",
    title: "Configurations",
    icon: faGears,
    routeType: ALLOW_IF_LOGGEDIN,
    masterModuleName: CONFIGURATIONS,
    isDummy: true,
    subRoutes: [
      {
        path: "/configurations/firm-details",
        title: "Firm Details",
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: FIRM_DETAILS,
        permissionRequired: READ,
        component: FirmDetails,
        viewType: GRID,
        masterModule: CONFIGURATIONS,
      },
      {
        path: "/configurations/app-configuration",
        title: "APP Configuration",
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: APP_CONFIGURATION,
        permissionRequired: READ,
        component: AppConfiguration,
        viewType: GRID,
        masterModule: CONFIGURATIONS,
      },
      {
        path: "/configurations/bank-accounts",
        title: "Bank Accounts",
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: BANK_ACCOUNTS,
        permissionRequired: READ,
        component: BankAccountsList,
        viewType: TABLE,
        masterModule: CONFIGURATIONS,
        subRoutes: [
          {
            path: "/configurations/bank-accounts/create",
            title: "Create Bank Account",
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: BANK_ACCOUNTS,
            permissionRequired: CREATE,
            component: BankAccountsCreate,
            viewType: GRID,
            masterModule: CONFIGURATIONS,
            hideInNavigation: true,
          },
          {
            path: "/configurations/bank-accounts/update/:id",
            title: "Update Bank Account",
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: BANK_ACCOUNTS,
            permissionRequired: UPDATE,
            component: BankAccountsUpdate,
            viewType: GRID,
            masterModule: CONFIGURATIONS,
            hideInNavigation: true,
          },
        ],
      },
      {
        path: "/configurations/scroll-message",
        title: "Scroll Message",
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: SCROLL_MESSAGE,
        permissionRequired: READ,
        component: ScrollMessage,
        viewType: GRID,
        masterModule: CONFIGURATIONS,
      },
      {
        path: "/configurations/sms-api-configuration",
        title: "SMS API Configuration",
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: SMS_API,
        permissionRequired: READ,
        component: SmsAPIConfiguration,
        viewType: GRID,
        masterModule: CONFIGURATIONS,
      },
      {
        path: "/configurations/whatsapp-api-configuration",
        title: "Whatsapp API Configuration",
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: WHATSAPP_API,
        permissionRequired: READ,
        component: WhatsappAPIConfiguration,
        viewType: GRID,
        masterModule: CONFIGURATIONS,
      },
    ],
  },
  // Recharge Configurations
  {
    path: "/recharge-configurations",
    title: "Recharge Configurations",
    icon: faGears,
    routeType: ALLOW_IF_LOGGEDIN,
    masterModuleName: RECHARGE_CONFIGURATIONS,
    isDummy: true,
    subRoutes: [
      {
        path: "/recharge-configurations/circles",
        title: "Circles",
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: CIRCLES,
        permissionRequired: READ,
        component: CirclesList,
        viewType: TABLE,
        masterModule: RECHARGE_CONFIGURATIONS,
        subRoutes: [
          {
            path: "/recharge-configurations/circles/create",
            title: "Create Circle",
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: CIRCLES,
            permissionRequired: CREATE,
            component: CirclesCreate,
            viewType: GRID,
            masterModule: RECHARGE_CONFIGURATIONS,
            hideInNavigation: true,
          },
          {
            path: "/recharge-configurations/circles/update/:id",
            title: "Update Circle",
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: CIRCLES,
            permissionRequired: UPDATE,
            component: CirclesUpdate,
            viewType: GRID,
            masterModule: RECHARGE_CONFIGURATIONS,
            hideInNavigation: true,
          },
        ],
      },
      {
        path: "/recharge-configurations/operators",
        title: "Operators",
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: OPERATORS,
        permissionRequired: READ,
        component: OperatorsList,
        viewType: TABLE,
        masterModule: RECHARGE_CONFIGURATIONS,
        subRoutes: [
          {
            path: "/recharge-configurations/operators/create",
            title: "Create Operator",
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: OPERATORS,
            permissionRequired: CREATE,
            component: OperatorsCreate,
            viewType: GRID,
            masterModule: RECHARGE_CONFIGURATIONS,
            hideInNavigation: true,
          },
          {
            path: "/recharge-configurations/operators/update/:id",
            title: "Update Operator",
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: OPERATORS,
            permissionRequired: UPDATE,
            component: OperatorsUpdate,
            viewType: GRID,
            masterModule: RECHARGE_CONFIGURATIONS,
            hideInNavigation: true,
          },
        ],
      },
      {
        path: "/recharge-configurations/admin-operator-margins",
        title: "Admin Operator Margins",
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: ADMIN_OPERATOR_MARGINS,
        permissionRequired: READ,
        component: AdminOperatorMarginList,
        viewType: TABLE,
        masterModule: RECHARGE_CONFIGURATIONS,
        subRoutes: [
          {
            path: "/recharge-configurations/admin-operator-margins/create",
            title: "Create Admin Operator Margin",
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: ADMIN_OPERATOR_MARGINS,
            permissionRequired: CREATE,
            component: AdminOperatorMarginCreate,
            viewType: GRID,
            masterModule: RECHARGE_CONFIGURATIONS,
            hideInNavigation: true,
          },
          {
            path: "/recharge-configurations/admin-operator-margins/update/:id",
            title: "Update Admin Operator Margin",
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: ADMIN_OPERATOR_MARGINS,
            permissionRequired: UPDATE,
            component: AdminOperatorMarginUpdate,
            viewType: GRID,
            masterModule: RECHARGE_CONFIGURATIONS,
            hideInNavigation: true,
          },
        ],
      },
      {
        path: "/configurations/plan-api-configuration",
        title: "Plan API Configuration",
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: PLAN_API_CONFIGURATION,
        permissionRequired: READ,
        component: PlanAPIConfiguration,
        viewType: GRID,
        masterModule: RECHARGE_CONFIGURATIONS,
      },
      {
        path: "/recharge-configurations/seller-apis",
        title: "Seller APIS",
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: SELLER_APIS,
        permissionRequired: READ,
        component: SellerAPISList,
        viewType: TABLE,
        masterModule: RECHARGE_CONFIGURATIONS,
        subRoutes: [
          {
            path: "/recharge-configurations/seller-apis/create",
            title: "Create Seller API",
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: SELLER_APIS,
            permissionRequired: CREATE,
            component: SellerAPISCreate,
            viewType: GRID,
            masterModule: RECHARGE_CONFIGURATIONS,
            hideInNavigation: true,
          },
          {
            path: "/recharge-configurations/seller-apis/update/:id",
            title: "Update Seller API",
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: SELLER_APIS,
            permissionRequired: UPDATE,
            component: SellerAPISUpdate,
            viewType: GRID,
            masterModule: RECHARGE_CONFIGURATIONS,
            hideInNavigation: true,
          },
        ],
      },
      {
        path: "/recharge-configurations/operators-blocker",
        title: "Operators Blockers",
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: OPERATORS_BLOCKER,
        permissionRequired: READ,
        component: OperatorBlockerList,
        viewType: TABLE,
        masterModule: RECHARGE_CONFIGURATIONS,
        subRoutes: [
          {
            path: "/recharge-configurations/operators-blocker/create",
            title: "Create Operators Blocker",
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: OPERATORS_BLOCKER,
            permissionRequired: CREATE,
            component: OperatorBlockerCreate,
            viewType: GRID,
            masterModule: RECHARGE_CONFIGURATIONS,
            hideInNavigation: true,
          },
        ],
      },
      {
        path: "/recharge-configurations/buyer-margins",
        title: "Buyer Margins",
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: BUYER_MARGINS,
        permissionRequired: READ,
        component: BuyerMarginsList,
        viewType: TABLE,
        masterModule: RECHARGE_CONFIGURATIONS,
      },
      {
        path: "/recharge-configurations/seller-margins",
        title: "Seller Margins",
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: SELLER_MARGINS,
        permissionRequired: READ,
        component: SellerMarginsList,
        viewType: TABLE,
        masterModule: RECHARGE_CONFIGURATIONS,
      },
    ],
  },
  // Transactions
  {
    path: "/transactions",
    title: "Transactions",
    icon: faIndianRupeeSign,
    routeType: ALLOW_IF_LOGGEDIN,
    masterModuleName: TRANSACTIONS,
    isDummy: true,
    subRoutes: [
      {
        path: "/transactions/buyer-recharge-transactions",
        title: "Buyer Recharge Transactions",
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: BUYER_RECHARGE_TRANSACTIONS,
        permissionRequired: READ,
        component: BuyerRechargeTransactionsList,
        viewType: TABLE,
        masterModule: TRANSACTIONS,
      },
      {
        path: "/transactions/seller-recharge-transactions",
        title: "Seller Recharge Transactions",
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: SELLER_RECHARGE_TRANSACTIONS,
        permissionRequired: READ,
        component: SellerRechargeTransactionsList,
        viewType: TABLE,
        masterModule: TRANSACTIONS,
      },
      {
        path: "/transactions/pending-recharge-transactions",
        title: "Pending Recharge Transactions",
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: PENDING_RECHARGE_TRANSACTIONS,
        permissionRequired: READ,
        component: PendingRechargeTransactionsList,
        viewType: TABLE,
        masterModule: TRANSACTIONS,
      },
      {
        path: "/transactions/disputed-recharge-transactions",
        title: "Disputed Recharge Transactions",
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: DISPUTED_RECHARGE_TRANSACTIONS,
        permissionRequired: READ,
        component: DisputedRechargeTransactionsList,
        viewType: TABLE,
        masterModule: TRANSACTIONS,
      },
      {
        path: "/transactions/live-recharge-transactions",
        title: "Live Recharge Transactions",
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: LIVE_RECHARGE_TRANSACTIONS,
        permissionRequired: READ,
        component: LiveRechargeTransactionsList,
        viewType: TABLE,
        masterModule: TRANSACTIONS,
        target: "_blank",
      },
    ],
  },
  // Reports
  {
    path: "/reports",
    title: "Reports",
    icon: faChartPie,
    routeType: ALLOW_IF_LOGGEDIN,
    masterModuleName: REPORTS,
    isDummy: true,
    subRoutes: [
      {
        path: "/reports/buyers-operator-day-book",
        title: "Buyers Operator Day Book",
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: BUYERS_OPERATORS_DAY_BOOK,
        permissionRequired: READ,
        component: BuyersOperatorDayBook,
        viewType: TABLE,
        masterModule: REPORTS,
      },{
        path: "/reports/sellers-operator-day-book",
        title: "Sellers Operator Day Book",
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: SELLERS_OPERATORS_DAY_BOOK,
        permissionRequired: READ,
        component: SellersOperatorDayBook,
        viewType: TABLE,
        masterModule: REPORTS,
      },
      {
        path: "/reports/daybook",
        title: "Daybook",
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: DAY_BOOK,
        permissionRequired: READ,
        component: Daybook,
        viewType: TABLE,
        masterModule: REPORTS,
      },
    ],
  }, // Invoicing
  {
    path: "/invoicing",
    title: "Invoicing",
    icon: faFileInvoice,
    routeType: ALLOW_IF_LOGGEDIN,
    masterModuleName: INVOICING,
    isDummy: true,
    subRoutes: [
      {
        path: "/invoicing/buyer-invoices",
        title: "Buyer Invoices",
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: BUYER_INVOICES,
        permissionRequired: READ,
        component: BuyerInvoices,
        viewType: TABLE,
        masterModule: INVOICING,
      },
      {
        path: "/invoicing/seller-invoices",
        title: "Seller Invoices",
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: SELLER_INVOICES,
        permissionRequired: READ,
        component: SellerInvoices,
        viewType: TABLE,
        masterModule: INVOICING,
      },
    ],
  },
];

export { ROUTES };
