import React, { useState, useEffect, useCallback } from "react";
import { withAppHOC } from "../../../hoc";
import PageHeading from "../../../components/PageHeader/PageHeader";
import OverlayLoader from "../../../components/OverlayLoader/OverlayLoader";
import { Grid, GridItem } from "../../../components/Grid";
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  MessageBox,
  UpdateButton,
  ResetButton,
  Dropdown,
  NumberInput,
} from "../../../components/FormElements";
import API from "../../../api";
import { API_RESPONSE_TYPES } from "../../../constants";

const AppConfiguration = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [gstWalletStatus, setGstWalletStatus] = useState("Active");
  const [nonGstWalletStatus, setNonGstWalletStatus] = useState("Active");
  const [sellersMaxAllowedApis, setSellersMaxAllowedApis] = useState("");
  const [maxRechargeApiHits, setMaxRechargeApiHits] = useState("");
  const [twofaStatus, setTwofaStatus] = useState("Active");
  const [complaintHoldDays, setComplaintHoldDays] = useState("");
  const [sellerAmountHoldPercentage, setSellerAmountHoldPercentage] =
    useState("");
  const [timeBetweenRedeem, setTimeBetweenRedeem] = useState("");
  const [callbackRechargeProcessingTime, setCallbackRechargeProcessingTime] =
    useState("");
  const [originalFormData, setOriginalFormData] = useState({});

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus("");
    setResponseMessage("");
  };

  const resetForm = () => {
    const {
      gstWalletStatus,
      nonGstWalletStatus,
      sellersMaxAllowedApis,
      maxRechargeApiHits,
      twofaStatus,
      complaintHoldDays,
      sellerAmountHoldPercentage,
      timeBetweenRedeem,
      callbackRechargeProcessingTime,
    } = originalFormData;
    setGstWalletStatus(gstWalletStatus);
    setNonGstWalletStatus(nonGstWalletStatus);
    setSellersMaxAllowedApis(sellersMaxAllowedApis);
    setMaxRechargeApiHits(maxRechargeApiHits);
    setTwofaStatus(twofaStatus);
    setComplaintHoldDays(complaintHoldDays);
    setSellerAmountHoldPercentage(sellerAmountHoldPercentage);
    setTimeBetweenRedeem(timeBetweenRedeem);
    setCallbackRechargeProcessingTime(callbackRechargeProcessingTime);
  };

  const readData = useCallback(() => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.get(`/configurations/app-configuration`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILED) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          const {
            gstWalletStatus,
            nonGstWalletStatus,
            sellersMaxAllowedApis,
            maxRechargeApiHits,
            twofaStatus,
            complaintHoldDays,
            sellerAmountHoldPercentage,
            timeBetweenRedeem,
            callbackRechargeProcessingTime,
          } = data;
          setGstWalletStatus(gstWalletStatus);
          setNonGstWalletStatus(nonGstWalletStatus);
          setSellersMaxAllowedApis(sellersMaxAllowedApis);
          setMaxRechargeApiHits(maxRechargeApiHits);
          setTwofaStatus(twofaStatus);
          setComplaintHoldDays(complaintHoldDays);
          setSellerAmountHoldPercentage(sellerAmountHoldPercentage);
          setTimeBetweenRedeem(timeBetweenRedeem);
          setCallbackRechargeProcessingTime(callbackRechargeProcessingTime);
          setOriginalFormData({
            gstWalletStatus,
            nonGstWalletStatus,
            sellersMaxAllowedApis,
            maxRechargeApiHits,
            twofaStatus,
            complaintHoldDays,
            sellerAmountHoldPercentage,
            timeBetweenRedeem,
            callbackRechargeProcessingTime,
          });
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILED);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    const formData = {
      gstWalletStatus,
      nonGstWalletStatus,
      sellersMaxAllowedApis,
      maxRechargeApiHits,
      twofaStatus,
      complaintHoldDays,
      sellerAmountHoldPercentage,
      timeBetweenRedeem,
      callbackRechargeProcessingTime,
    };
    API.put(`/configurations/app-configuration`, formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setOriginalFormData(formData);
          setTimeout(() => {
            resetResponseData();
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILED);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    readData();
  }, [readData]);

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method="POST" action="#" onSubmit={onSubmit}>
          <Grid
            columns={
              "calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)"
            }
          >
            <GridItem>
              <Fieldset>
                <Label>2FA Status</Label>
                <Dropdown
                  value={twofaStatus}
                  onChange={setTwofaStatus}
                  placeholder="Please select 2FA status"
                  disabled={isLoading}
                  options={[
                    { title: "Active", value: "Active" },
                    { title: "Inactive", value: "Inactive" },
                  ]}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>GST Wallet Status</Label>
                <Dropdown
                  value={gstWalletStatus}
                  onChange={setGstWalletStatus}
                  placeholder="Please select gst wallet status"
                  disabled={isLoading}
                  options={[
                    { title: "Active", value: "Active" },
                    { title: "Inactive", value: "Inactive" },
                  ]}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Non-GST Wallet Status</Label>
                <Dropdown
                  value={nonGstWalletStatus}
                  onChange={setNonGstWalletStatus}
                  placeholder="Please select non gst wallet status"
                  disabled={isLoading}
                  options={[
                    { title: "Active", value: "Active" },
                    { title: "Inactive", value: "Inactive" },
                  ]}
                />
              </Fieldset>
            </GridItem>
          </Grid>

          <Grid
            columns={
              "calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)"
            }
          >
            <GridItem>
              <Fieldset>
                <Label>Seller Max Allowed APIS</Label>
                <NumberInput
                  value={sellersMaxAllowedApis}
                  onChange={setSellersMaxAllowedApis}
                  placeholder="Please enter seller max allowed apis"
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Max Recharge APIS Hits</Label>
                <NumberInput
                  value={maxRechargeApiHits}
                  onChange={setMaxRechargeApiHits}
                  placeholder="Please enter seller max allowed apis"
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Complaint Hold Days</Label>
                <NumberInput
                  value={complaintHoldDays}
                  onChange={setComplaintHoldDays}
                  placeholder="Please enter complaint hold days"
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid
            columns={
              "calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)"
            }
          >
            <GridItem>
              <Fieldset>
                <Label>Seller Amount Hold Percentage</Label>
                <NumberInput
                  value={sellerAmountHoldPercentage}
                  onChange={setSellerAmountHoldPercentage}
                  placeholder="Please enter seller amount hold percentage"
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Time Between Redeem Requests</Label>
                <NumberInput
                  value={timeBetweenRedeem}
                  onChange={setTimeBetweenRedeem}
                  placeholder="Please enter time between redeem"
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Callback Recharge Processing Time</Label>
                <NumberInput
                  value={callbackRechargeProcessingTime}
                  onChange={setCallbackRechargeProcessingTime}
                  placeholder="Please enter callback recharge processing time"
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <UpdateButton disabled={isLoading} type="submit">
              Update
            </UpdateButton>
            <ResetButton
              disabled={isLoading}
              type="button"
              onClick={resetForm}
            >
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(AppConfiguration);
