import React, { useState, useEffect } from "react";
import { withAppHOC } from "../../../hoc";
import PageHeading from "../../../components/PageHeader/PageHeader";
import OverlayLoader from "../../../components/OverlayLoader/OverlayLoader";
import { Grid, GridItem } from "../../../components/Grid";
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  FileInput,
  Dropdown,
  MessageBox,
  CreateButton,
  ResetButton,
  NumberInput,
} from "../../../components/FormElements";
import API from "../../../api";
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from "../../../constants";
import { getBase64, getReturnUrl } from "../../../utils";
import Numberinput from "../../../components/FormElements/NumberInput";

const OperatorsCreate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [operatorName, setOperatorName] = useState("");
  const [operatorLogo, setOperatorLogo] = useState([]);
  const [operatorLogoBase64, setOperatorLogoBase64] = useState("");
  const [mastersMargin, setMastersMargin] = useState("");
  const [shouldCheckCircle, setShouldCheckCircle] = useState("No");
  const [minRechargeAmount, setMinRechargeAmount] = useState("1");
  const [maxRechargeAmount, setMaxRechargeAmount] = useState("5000");
  const [planApiOperatorCode, setPlanApiOperatorCode] = useState("");
  const [operatorStatus, setOperatorStatus] = useState("Active");
  const [rofferApplicable, setRofferApplicable] = useState("Yes");
  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus("");
    setResponseMessage("");
  };

  const resetForm = () => {
    setOperatorName("");
    setOperatorLogo([]);
    setOperatorLogoBase64("");
    setMastersMargin("");
    setShouldCheckCircle("No");
    setMinRechargeAmount("1");
    setMaxRechargeAmount("5000");
    setPlanApiOperatorCode("");
    setOperatorStatus("Active");
    setRofferApplicable("Yes");
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    const formData = {
      operatorName,
      operatorLogo: operatorLogoBase64,
      mastersMargin,
      shouldCheckCircle,
      minRechargeAmount,
      maxRechargeAmount,
      planApiOperatorCode,
      operatorStatus,
      rofferApplicable,
    };
    API.post("/recharge-configurations/operators", formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          resetForm();
          setTimeout(() => {
            resetResponseData();
            window.location.href = getReturnUrl(PERMISSION_TYPES.CREATE);
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILED);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (operatorLogo?.length > 0) {
      getBase64(operatorLogo[0], setOperatorLogoBase64);
    }
  }, [operatorLogo]);

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method="POST" action="#" onSubmit={onSubmit}>
          <Grid
            columns={
              "calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)"
            }
          >
            <GridItem>
              <Fieldset>
                <Label required>Operator Name</Label>
                <TextInput
                  value={operatorName}
                  onChange={setOperatorName}
                  placeholder="Please enter operator name"
                  maxLength={100}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Masters Margin</Label>
                <Numberinput
                  value={mastersMargin}
                  onChange={setMastersMargin}
                  placeholder="Please enter masters margin"
                  maxLength={100}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Circle Check</Label>
                <Dropdown
                  placeholder="Please select should circle check"
                  value={shouldCheckCircle}
                  onChange={setShouldCheckCircle}
                  options={[
                    {
                      title: "Yes",
                      value: "Yes",
                    },
                    {
                      title: "No",
                      value: "No",
                    },
                  ]}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Fieldset>
            <Label>Operator Logo</Label>
            <FileInput
              files={operatorLogo}
              onChange={setOperatorLogo}
              accept="image/*"
              disabled={isLoading}
            />
          </Fieldset>
          <Grid columns={"calc(50% - 10px) calc(50% - 10px)"}>
            <GridItem>
              <Fieldset>
                <Label required>Min Recharge Amount</Label>
                <NumberInput
                  value={minRechargeAmount}
                  onChange={setMinRechargeAmount}
                  placeholder="Please enter min recharge amount"
                  maxLength={100}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Max Recharge Amount</Label>
                <NumberInput
                  value={maxRechargeAmount}
                  onChange={setMaxRechargeAmount}
                  placeholder="Please enter max recharge amount"
                  maxLength={100}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Fieldset>
            <Label>Plan API Code</Label>
            <TextInput
              value={planApiOperatorCode}
              onChange={setPlanApiOperatorCode}
              placeholder="Please enter plan api operator code"
              maxLength={100}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label required>Operator Status</Label>
            <Dropdown
              placeholder="Please select operator status"
              value={operatorStatus}
              onChange={setOperatorStatus}
              options={[
                {
                  title: "-- SELECT OPERATOR STATUS --",
                },
                {
                  title: "Active",
                  value: "Active",
                },
                {
                  title: "Inactive",
                  value: "Inactive",
                },
              ]}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label required>Roffer Status</Label>
            <Dropdown
              placeholder="Please select roffer status"
              value={rofferApplicable}
              onChange={setRofferApplicable}
              options={[
                {
                  title: "-- SELECT ROFFER STATUS --",
                },
                {
                  title: "Yes",
                  value: "Yes",
                },
                {
                  title: "No",
                  value: "No",
                },
              ]}
              disabled={isLoading}
            />
          </Fieldset>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <CreateButton disabled={isLoading} type="submit">
              Create
            </CreateButton>
            <ResetButton
              disabled={isLoading}
              type="button"
              onClick={resetForm}
            >
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(OperatorsCreate);
