import React, { useState, useEffect, useCallback } from "react";
import {
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import exportFromJSON from "export-from-json";
import { withAppHOC } from "../../../hoc";
import PageHeading from "../../../components/PageHeader/PageHeader";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import NoData from "../../../components/NoData/NoData";
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  ResetButton,
  MessageBox,
  Dropdown,
  CustomButton,
  Button,
  CreateButton,
} from "../../../components/FormElements";
import { TableBuilder } from "../../../components/TableElements";
import Modal from "../../../components/Modal";
import OverlayLoader from "../../../components/OverlayLoader/OverlayLoader";
import {
  StyledSpan,
  StyledModalContainer,
  StyledFiltersContainer,
} from "../../../components/Styled";
import Pagination from "../../../components/Pagination/Pagination";
import API from "../../../api";
import { API_RESPONSE_TYPES } from "../../../constants";
import {
  getSearchParams,
  shouldRedirect,
  redirectToPageOne,
  formatCurrency,
} from "../../../utils";
import FiltersButton from "../../../components/FiltersButton/FiltersButton";
import { Image } from "../../../components/Image";
import moment from "moment";
import StatusHighliter from "../../../components/StatusHighliter/StatusHighliter";

const UsersFundRequestsList = (props) => {
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams("pageNumber") || 1,
    recordsPerPage: getSearchParams("recordsPerPage") || 30,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [data, setData] = useState([]);
  const [mobileNumber, setMobileNumber] = useState("");
  const [approvalStatus, setApprovalStatus] = useState("Pending");
  const [startDate, setStateDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [remark, setRemark] = useState("");
  const [updateApprovalStatus, setUpdateApprovalStatus] = useState("");

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus("");
    setResponseMessage("");
  };

  const resetFilters = () => {
    setMobileNumber("");
    setApprovalStatus("");
    setStateDate("");
    setEndDate("");
    readData();
  };

  const readData = useCallback(
    (mobileNumber = "", approvalStatus = "", startDate = "", endDate = "") => {
      setIsLoading(true);
      setResponseStatus("");
      setResponseMessage("");
      setData([]);
      API.get(
        `/accounts-management/users/users-fund-requests?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&mobileNumber=${mobileNumber}&approvalStatus=${approvalStatus}&startDate=${startDate}&endDate=${endDate}`
      )
        .then((response) => {
          const { status, message, data, pageInfo } = response.data;
          if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
            redirectToPageOne();
          } else {
            if (status === API_RESPONSE_TYPES.FAILED) {
              setResponseStatus(status);
              setResponseMessage(message);
            } else {
              setTotalRecords(pageInfo.totalRecords);
            }
            setData(data);
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILED);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [paginationData]
  );

  const reloadData = () => {
    readData(mobileNumber, approvalStatus, startDate, endDate);
  };

  const updateData = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.put(`/accounts-management/users/users-fund-requests/${modalData.id}`, {
      approvalStatus:
        updateApprovalStatus === "Approve" ? "Approved" : "Rejected",
      remark,
    })
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          reloadData();
          setShowModal(false);
          setModalData(null);
          setUpdateApprovalStatus("");
          setRemark("");
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILED);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const filterData = (e) => {
    e.preventDefault();
    setIsFiltering(true);
  };

  useEffect(() => {
    if (isFiltering) {
      setPaginationData((paginationData) => ({
        pageNumber: 1,
        recordsPerPage: paginationData.recordsPerPage,
      }));
      setIsFiltering((isFiltering) => !isFiltering);
    }
  }, [isFiltering]);

  useEffect(() => {
    if (!isFiltering) {
      readData(mobileNumber, approvalStatus, startDate, endDate);
    }
  }, [paginationData, readData]);

  const [showFilters, setShowFilters] = useState(false);
  const onClickShowFilters = () => {
    setShowFilters((showFilters) => !showFilters);
  };

  const onClickExport = () => {
    exportFromJSON({
      data: data,
      fileName: "users_fund_requests",
      exportType: exportFromJSON.types.csv,
    });
  };

  return (
    <>
      <PageHeading
        {...props}
        reloadData={reloadData}
        showExport={true}
        onClickExport={onClickExport}
      />
      <FiltersButton onClick={onClickShowFilters} value={showFilters} />
      <StyledFiltersContainer className={showFilters ? "show" : ""}>
        <Form method="POST" action="#" onSubmit={filterData}>
          <Fieldset>
            <Label>Mobile Number</Label>
            <TextInput
              value={mobileNumber}
              onChange={setMobileNumber}
              placeholder="Mobile Number"
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Approval Status</Label>
            <Dropdown
              placeholder="Approval Status"
              value={approvalStatus}
              onChange={setApprovalStatus}
              options={[
                { title: "-- SELECT APPROVAL STATUS --", value: "" },
                { title: "Pending", value: "Pending" },
                { title: "Approved", value: "Approved" },
                { title: "Rejected", value: "Rejected" },
              ]}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Start Date</Label>
            <TextInput
              type="date"
              placeholder="Start Date"
              value={startDate}
              onChange={setStateDate}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>End Date</Label>
            <TextInput
              type="date"
              placeholder="End Date"
              value={endDate}
              onChange={setEndDate}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Button disabled={isLoading} type="submit">
              Filter
            </Button>
            <ResetButton
              disabled={isLoading}
              type="button"
              onClick={resetFilters}
            >
              Clear
            </ResetButton>
          </Fieldset>
        </Form>
      </StyledFiltersContainer>
      <LoadingSpinner
        isLoading={
          responseStatus === "" && !showModal && data?.length === 0 && isLoading
        }
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILED &&
          !isLoading &&
          data?.length === 0
        }
        message={`No records found`}
      />
      {!showModal && responseStatus === API_RESPONSE_TYPES.FAILED && (
        <MessageBox status={responseStatus} message={responseMessage} />
      )}
      {data?.length > 0 && (
        <>
          <TableBuilder
            isLoading={!showModal && data?.length !== 0 && isLoading}
            tableHeadings={[
              {
                title: "",
                dataSelector: "id",
                align: "center",
                sticky: true,
                canSort: false,
                width: "100px",
                CellRenderer: (value, item) =>
                  item.approvalStatus === "Pending" && (
                    <>
                      <CustomButton
                        icon={faCircleCheck}
                        onClick={() => {
                          setShowModal(true);
                          setModalData(item);
                          setUpdateApprovalStatus("Approve");
                        }}
                        color="forestgreen"
                        hoverColor="#006200"
                      />
                      <CustomButton
                        icon={faCircleXmark}
                        onClick={() => {
                          setShowModal(true);
                          setModalData(item);
                          setUpdateApprovalStatus("Reject");
                        }}
                        color="red"
                        hoverColor="#a90404"
                      />
                    </>
                  ),
              },
              {
                title: "User",
                dataSelector: "fullName",
                dataType: "string",
                CellRenderer: (value, item) => (
                  <>
                    {value}
                    <StyledSpan>{item.mobileNumber}</StyledSpan>
                  </>
                ),
              },
              {
                title: "Bank Details",
                dataSelector: "bankName",
                dataType: "string",
                CellRenderer: (value, item) => (
                  <>
                    {value || "N/A"}
                    <StyledSpan>{item.accountNumber || "N/A"}</StyledSpan>
                    <StyledSpan>{item.payeeName || "N/A"}</StyledSpan>
                    <StyledSpan>{item.ifscCode || "N/A"}</StyledSpan>
                  </>
                ),
              },
              {
                title: "Wallet Type",
                dataSelector: "walletType",
                dataType: "string",
              },
              {
                title: "Payment Type",
                dataSelector: "paymentType",
                dataType: "string",
              },
              {
                title: "UTR Number",
                dataSelector: "utrNumber",
                dataType: "string",
                CellRenderer: (value) => value || "N/A",
              },
              {
                title: "Amount",
                dataSelector: "amount",
                dataType: "string",
                CellRenderer: (value) => formatCurrency(value),
              },
              {
                title: "Status",
                dataSelector: "approvalStatus",
                dataType: "string",
                align: "center",
                CellRenderer: (value) =>
                  value && (
                    <StatusHighliter
                      className={
                        value === "Approved"
                          ? "green"
                          : value === "Rejected"
                          ? "red"
                          : value === "Pending"
                          ? "yellow"
                          : "blue"
                      }
                    >
                      {value}
                    </StatusHighliter>
                  ),
              },
              {
                title: "Remark",
                dataSelector: "remark",
                dataType: "string",
                CellRenderer: (value) => value || "N/A",
              },
              {
                title: "Date & Time",
                dataSelector: "createdOn",
                dataType: "string",
              },
              {
                title: "Processed",
                dataSelector: "updatedOn",
                dataType: "string",
                cellrenderer: (value) => (value ? value : "N/A"),
              },
            ]}
            tableData={data}
          />
          <Pagination
            totalRecords={totalRecords}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
          />
          <Modal
            modalStatus={showModal}
            setModalStatus={() => {
              resetResponseData();
              setShowModal(false);
              setModalData(null);
              setRemark("");
              setApprovalStatus("");
            }}
            hideCloseButton
          >
            <StyledModalContainer width={"500px"}>
              <h3>{updateApprovalStatus} Request</h3>
              <FormContainer>
                <Form method="POST" action="#" onSubmit={updateData}>
                  <Fieldset>
                    <Label required={updateApprovalStatus !== "Approve"}>
                      Remark
                    </Label>
                    <TextInput
                      value={remark}
                      onChange={setRemark}
                      placeholder="Please enter remark"
                      disabled={showModal && isLoading}
                    />
                  </Fieldset>
                  {showModal && responseStatus && (
                    <Fieldset>
                      <MessageBox
                        status={showModal && responseStatus}
                        message={showModal && responseMessage}
                      />
                    </Fieldset>
                  )}
                  <Fieldset>
                    <CreateButton
                      disabled={showModal && isLoading}
                      type="submit"
                    >
                      Submit
                    </CreateButton>
                  </Fieldset>
                </Form>
                <OverlayLoader showLoader={showModal && isLoading} />
              </FormContainer>
            </StyledModalContainer>
          </Modal>
        </>
      )}
    </>
  );
};

export default withAppHOC(UsersFundRequestsList);
