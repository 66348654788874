import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { Button } from ".";

const StyledIconButton = styled(Button)`
  padding: 0px;
  margin: 0px 5px;
  color: ${({ color }) => (color ? color : `forestgreen`)};
  background-color: transparent;
  &:hover {
    background-color: transparent;
    color: ${({ hoverColor }) => (hoverColor ? hoverColor : `#006200`)};
  }
`;

const CustomButton = ({
  showIcon = true,
  showTitle = false,
  icon,
  title = "",
  color = "",
  hoverColor = "",
  onClick,
  ...rest
}) => {
  return (
    <StyledIconButton
      onClick={onClick}
      {...rest}
      color={color}
      hoverColor={hoverColor}
    >
      {showIcon && <FontAwesomeIcon icon={icon ? icon : faTrashCan} />}
      {showTitle && ` ${title}`}
    </StyledIconButton>
  );
};

export default CustomButton;
