const MASTER_MODULES = {
  ACCOUNTS_MANAGEMENT: "ACCOUNTS_MANAGEMENT",
  CONFIGURATIONS: "CONFIGURATIONS",
  RECHARGE_CONFIGURATIONS: "RECHARGE_CONFIGURATIONS",
  TRANSACTIONS: "TRANSACTIONS",
  REPORTS: "REPORTS",
  INVOICING: "INVOICING",
};

const MODULES = {
  // Accounts Management - ACCOUNTS_MANAGEMENT
  USERS: "USERS",
  USERS_KYC: "USERS_KYC",
  USERS_BANK_ACCOUNTS: "USERS_BANK_ACCOUNTS",
  USERS_FUND_REQUESTS: "USERS_FUND_REQUESTS",
  USERS_WITHDRAW_REQUESTS: "USERS_WITHDRAW_REQUESTS",
  STAFF_ACCOUNTS: "STAFF_ACCOUNTS",
  ROLES_AND_PERMISSIONS: "ROLES_AND_PERMISSIONS",
  // Configurations - CONFIGURATIONS
  FIRM_DETAILS: "FIRM_DETAILS",
  APP_CONFIGURATION: "APP_CONFIGURATION",
  BANK_ACCOUNTS: "BANK_ACCOUNTS",
  SCROLL_MESSAGE: "SCROLL_MESSAGE",
  SMS_API: "SMS_API",
  WHATSAPP_API: "WHATSAPP_API",
  // Recharge Configurations - RECHARGE_CONFIGURATIONS
  CIRCLES: "CIRCLES",
  OPERATORS: "OPERATORS",
  ADMIN_OPERATOR_MARGINS: "ADMIN_OPERATOR_MARGINS",
  PLAN_API_CONFIGURATION: "PLAN_API_CONFIGURATION",
  SELLER_APIS: "SELLER_APIS",
  OPERATORS_BLOCKER: "OPERATORS_BLOCKER",
  BUYER_MARGINS: "BUYER_MARGINS",
  SELLER_MARGINS: "SELLER_MARGINS",
  // Transactions - TRANSACTIONS
  BUYER_RECHARGE_TRANSACTIONS: "BUYER_RECHARGE_TRANSACTIONS",
  SELLER_RECHARGE_TRANSACTIONS: "SELLER_RECHARGE_TRANSACTIONS",
  PENDING_RECHARGE_TRANSACTIONS: "PENDING_RECHARGE_TRANSACTIONS",
  DISPUTED_RECHARGE_TRANSACTIONS: "DISPUTED_RECHARGE_TRANSACTIONS",
  LIVE_RECHARGE_TRANSACTIONS: "LIVE_RECHARGE_TRANSACTIONS",
  // Reports - REPORTS
  BUYERS_OPERATORS_DAY_BOOK: "BUYERS_OPERATORS_DAY_BOOK",
  SELLERS_OPERATORS_DAY_BOOK: "SELLERS_OPERATORS_DAY_BOOK",
  DAY_BOOK: "DAY_BOOK",
  // Invoicing - INVOICING
  BUYER_INVOICES: "BUYER_INVOICES",
  SELLER_INVOICES: "SELLER_INVOICES",
};

const ROUTE_TYPES = {
  ALLOW_IF_LOGGEDIN: "ALLOW_IF_LOGGEDIN",
  ALLOW_IF_NOT_LOGGEDIN: "ALLOW_IF_NOT_LOGGEDIN",
};

const PERMISSION_TYPES = {
  CREATE: "CREATE",
  READ: "READ",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
  TRACK: "TRACK",
  WALLET_TRANSACTIONS: "WALLET_TRANSACTIONS",
  FUND_SWITCHING_TRANSACTIONS: "FUND_SWITCHING_TRANSACTIONS",
  READ_LATEST_OTP: "READ_LATEST_OTP",
  EXPORT: "EXPORT",
  WALLET_PROCESS_FUNDS: "WALLET_PROCESS_FUNDS",
  RELOAD: "RELOAD",
  REFUND: "REFUND",
};

const VIEW_TYPES = {
  TILES: "TILES",
  TABLE: "TABLE",
  LIST: "LIST",
  GRID: "GRID",
  VIEW: "VIEW",
};

const ACCOUNT_TYPES = {
  ADMINS: "admins",
};

const API_RESPONSE_TYPES = {
  SUCCESS: "success",
  FAILED: "failed",
};

export {
  MASTER_MODULES,
  MODULES,
  ROUTE_TYPES,
  PERMISSION_TYPES,
  VIEW_TYPES,
  ACCOUNT_TYPES,
  API_RESPONSE_TYPES,
};
