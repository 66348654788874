import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import exportFromJSON from "export-from-json";
import { withAppHOC } from "../../../hoc";
import PageHeading from "../../../components/PageHeader/PageHeader";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import NoData from "../../../components/NoData/NoData";
import {
  MessageBox,
  Form,
  Fieldset,
  TextInput,
  ResetButton,
  Button,
  Label,
} from "../../../components/FormElements";
import { TableBuilder } from "../../../components/TableElements";
import Pagination from "../../../components/Pagination/Pagination";
import { StyledFiltersContainer } from "../../../components/Styled";
import API from "../../../api";
import { API_RESPONSE_TYPES } from "../../../constants";
import {
  getSearchParams,
  formatCurrency,
  shouldRedirect,
  redirectToPageOne,
  camelCaseToString,
} from "../../../utils";
import FiltersButton from "../../../components/FiltersButton/FiltersButton";

const UsersFundSwitchingTransactionsList = (props) => {
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams("pageNumber") || 1,
    recordsPerPage: getSearchParams("recordsPerPage") || 10,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [fundSwitchingTransactions, setFundSwitchingTransactions] = useState(
    []
  );
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const { id } = useParams();

  const resetForm = () => {
    setFromDate("");
    setToDate("");
    readData();
  };

  const readData = useCallback(
    (fromDate = "", toDate = "") => {
      setIsLoading(true);
      setResponseStatus("");
      setResponseMessage("");
      API.get(
        `/accounts-management/users/fund-switching-transactions/${id}?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&fromDate=${fromDate}&toDate=${toDate}`
      )
        .then((response) => {
          const { status, message, data, pageInfo } = response.data;
          if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
            redirectToPageOne();
          } else {
            if (status === API_RESPONSE_TYPES.FAILED) {
              setResponseStatus(status);
              setResponseMessage(message);
            } else {
              setFundSwitchingTransactions(data);
              setTotalRecords(pageInfo.totalRecords);
            }
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILED);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [paginationData, id]
  );

  const onClickExport = () => {
    exportFromJSON({
      data: fundSwitchingTransactions,
      fileName: "fund_switching_transactions",
      exportType: exportFromJSON.types.csv,
    });
  };

  const reloadData = () => {
    readData(fromDate, toDate);
  };

  const filterData = (e) => {
    e.preventDefault();
    setIsFiltering(true);
  };

  useEffect(() => {
    if (isFiltering) {
      setPaginationData((paginationData) => ({
        pageNumber: 1,
        recordsPerPage: paginationData.recordsPerPage,
      }));
      setIsFiltering((isFiltering) => !isFiltering);
    }
  }, [isFiltering]);

  useEffect(() => {
    if (!isFiltering) {
      readData(fromDate, toDate);
    }
  }, [paginationData, readData]);

  const [showFilters, setShowFilters] = useState(false);
  const onClickShowFilters = () => {
    setShowFilters((showFilters) => !showFilters);
  };

  return (
    <>
      <PageHeading
        {...props}
        canReloadProp={true}
        reloadData={reloadData}
        showExport={fundSwitchingTransactions.length > 0}
        onClickExport={onClickExport}
      />
      <FiltersButton onClick={onClickShowFilters} value={showFilters} />
      <StyledFiltersContainer className={showFilters ? "show" : ""}>
        <Form method="POST" action="#" onSubmit={filterData}>
          <Fieldset>
            <Label>Start Date</Label>
            <TextInput
              type="date"
              value={fromDate}
              onChange={setFromDate}
              placeholder="Start Date"
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>End Date</Label>
            <TextInput
              type="date"
              value={toDate}
              onChange={setToDate}
              placeholder="End Date"
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Button disabled={isLoading} type="submit">
              Filter
            </Button>
            <ResetButton disabled={isLoading} type="button" onClick={resetForm}>
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
      </StyledFiltersContainer>
      <LoadingSpinner
        isLoading={
          responseStatus === "" &&
          fundSwitchingTransactions?.length === 0 &&
          isLoading
        }
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILED &&
          !isLoading &&
          fundSwitchingTransactions?.length === 0
        }
        message={`No fund switching transactions found`}
      />
      {responseStatus === API_RESPONSE_TYPES.FAILED && (
        <MessageBox status={responseStatus} message={responseMessage} />
      )}
      {fundSwitchingTransactions?.length > 0 && (
        <>
          <TableBuilder
            isLoading={fundSwitchingTransactions?.length !== 0 && isLoading}
            tableHeadings={[
              {
                title: "From Wallet",
                dataSelector: "fromWallet",
                dataType: "string",
                CellRenderer: (value) => camelCaseToString(value),
              },
              {
                title: "To Wallet",
                dataSelector: "toWallet",
                dataType: "string",
                CellRenderer: (value) => camelCaseToString(value),
              },
              {
                title: "Amount",
                dataSelector: "amount",
                dataType: "number",
                CellRenderer: (value) => formatCurrency(value),
              },
              {
                title: "Date & Time",
                dataSelector: "createdOn",
                dataType: "string",
              },
            ]}
            tableData={fundSwitchingTransactions}
          />
          <Pagination
            totalRecords={totalRecords}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
          />
        </>
      )}
    </>
  );
};

export default withAppHOC(UsersFundSwitchingTransactionsList);
