import React, { useState, useEffect } from "react";
import { withAppHOC } from "../../../hoc";
import PageHeading from "../../../components/PageHeader/PageHeader";
import OverlayLoader from "../../../components/OverlayLoader/OverlayLoader";
import { Grid, GridItem } from "../../../components/Grid";
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  Dropdown,
  FileInput,
  MessageBox,
  CreateButton,
  ResetButton,
} from "../../../components/FormElements";
import API from "../../../api";
import {
  API_RESPONSE_TYPES,
  PERMISSION_TYPES,
} from "../../../constants";
import { getBase64, getReturnUrl } from "../../../utils";

const BankAccountsCreate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankLogo, setBankLogo] = useState([]);
  const [bankLogoBase64, setBankLogoBase64] = useState("");
  const [payeeName, setPayeeName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [bankStatus, setBankStatus] = useState("Active");

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus("");
    setResponseMessage("");
  };

  const resetForm = () => {
    setBankName("");
    setBankLogo("");
    setBankLogoBase64("");
    setPayeeName("");
    setAccountNumber("");
    setIfscCode("");
    setBankStatus("Active");
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    const formData = {
      bankName,
      bankLogo: bankLogoBase64,
      payeeName,
      accountNumber,
      ifscCode,
      bankStatus,
    };
    API.post("/configurations/bank-accounts", formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          resetForm();
          setTimeout(() => {
            resetResponseData();
            window.location.href = getReturnUrl(PERMISSION_TYPES.CREATE);
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILED);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (bankLogo?.length > 0) {
      getBase64(bankLogo[0], setBankLogoBase64);
    }
  }, [bankLogo]);

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method="POST" action="#" onSubmit={onSubmit}>
          <Grid
            columns={
              "calc(25% - 15px) calc(25% - 15px) calc(25% - 15px) calc(25% - 15px)"
            }
          >
            <GridItem>
              <Fieldset>
                <Label required>Bank Name</Label>
                <TextInput
                  value={bankName}
                  onChange={setBankName}
                  placeholder="Please enter bank name"
                  maxLength={100}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Payee Name</Label>
                <TextInput
                  value={payeeName}
                  onChange={setPayeeName}
                  placeholder="Please enter payee name"
                  maxLength={100}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Account Number</Label>
                <TextInput
                  value={accountNumber}
                  onChange={setAccountNumber}
                  placeholder="Please enter account number"
                  maxLength={200}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>IFSC Code</Label>
                <TextInput
                  value={ifscCode}
                  onChange={setIfscCode}
                  placeholder="Please enter ifsc code"
                  maxLength={100}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Fieldset>
            <Label>Bank Logo</Label>
            <FileInput
              files={bankLogo}
              onChange={setBankLogo}
              accept="image/*"
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label required>Bank Status</Label>
            <Dropdown
              placeholder="Please select bank status"
              value={bankStatus}
              onChange={setBankStatus}
              options={[
                {
                  title: "-- SELECT BANK STATUS --",
                  value: "",
                },
                {
                  title: "Active",
                  value: "Active",
                },
                {
                  title: "Inactive",
                  value: "Inactive",
                },
              ]}
              disabled={isLoading}
            />
          </Fieldset>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <CreateButton disabled={isLoading} type="submit">
              Create
            </CreateButton>
            <ResetButton
              disabled={isLoading}
              type="button"
              onClick={resetForm}
            >
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(BankAccountsCreate);
