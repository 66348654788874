import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { withAppHOC } from "../../hoc";
import API from "../../api";
import { API_RESPONSE_TYPES } from "../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faExchange,
  faFlag,
  faIndianRupeeSign,
  faMoneyBills,
  faMoneyBillTransfer,
  faMoneyBillTrendUp,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { formatCurrency } from "../../utils";

const StyledHeading = styled.h1`
  font-size: 24px;
  font-weight: normal;
  color: rgb(0, 0, 0);
  margin: 20px 0px;
  padding: 0px;
`;

const StyledStatsContainerOuter = styled.section`
  width: 100%;
  height: auto;
  margin: 0px;
  padding: 0px;
  display: flex;
  align-items: center;
  @media (max-width: 600px) {
    display: block;
  }
`;

const StyledStatsContainer = styled.section`
  width: auto;
  height: auto;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
`;

const StyledStatsSection = styled.section`
  width: 450px;
  height: 100px;
  background: linear-gradient(
    to top right,
    ${({
      theme: {
        colors: { primaryColor },
      },
    }) => (primaryColor ? primaryColor : `#0095ff`)},
    ${({
      theme: {
        colors: { secondaryColor },
      },
    }) => (secondaryColor ? secondaryColor : `#0078ce`)},
    ${({
      theme: {
        colors: { secondaryColor },
      },
    }) => (secondaryColor ? secondaryColor : `#0078ce`)},
    ${({
      theme: {
        colors: { primaryColor },
      },
    }) => (primaryColor ? primaryColor : `#0095ff`)}
  );
  padding: 0px;
  margin: 0px;
  display: grid;
  border-radius: 10px;
  grid-template-columns: max-content auto;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const StyledStatsIcon = styled.section`
  width: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 15px;
  svg {
    width: 30px;
    height: 30px;
    color: #ffffff;
    border: 5px solid #ffffff;
    padding: 15px;
    border-radius: 100px;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const StyledStatsCounter = styled.section`
  width: 100%;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  color: #ffffff;
`;

const StyledStatsHeading = styled.p`
  font-size: 14px;
  font-weight: normal;
  margin: 0px;
  padding: 15px;
  text-align: left;
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledStatsCount = styled.h3`
  font-size: 22px;
  font-weight: normal;
  margin: 0px;
  padding: 18px 15px 0px 15px;
`;

const Dashboard = () => {
  const [stats, setStats] = useState(null);

  const readStats = useCallback(() => {
    API.get("/dashboard/stats").then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setStats(data);
      }
    });
  }, []);

  useEffect(() => {
    readStats();
  }, [readStats]);

  return stats && (
    <>
      <StyledHeading>Recharge Summary</StyledHeading>
      <StyledStatsContainerOuter>
        <StyledStatsContainer>
          <StyledStatsSection>
            <StyledStatsIcon>
              <FontAwesomeIcon icon={faCheck} />
            </StyledStatsIcon>
            <StyledStatsCounter>
              <StyledStatsCount>
                {formatCurrency(stats?.successAmount)} ( {stats?.successCount} )
              </StyledStatsCount>
              <StyledStatsHeading title="Success Amount">
                Success Amount
              </StyledStatsHeading>
            </StyledStatsCounter>
          </StyledStatsSection>
          <StyledStatsSection>
            <StyledStatsIcon>
              <FontAwesomeIcon icon={faXmark} />
            </StyledStatsIcon>
            <StyledStatsCounter>
              <StyledStatsCount>
                {formatCurrency(stats?.failedAmount)} ( {stats?.failedCount} )
              </StyledStatsCount>
              <StyledStatsHeading title="Failed Amount">
                Failed Amount
              </StyledStatsHeading>
            </StyledStatsCounter>
          </StyledStatsSection>
          <StyledStatsSection>
            <StyledStatsIcon>
              <FontAwesomeIcon icon={faExchange} />
            </StyledStatsIcon>
            <StyledStatsCounter>
              <StyledStatsCount>
                {formatCurrency(stats?.pendingAmount)} ( {stats?.pendingCount} )
              </StyledStatsCount>
              <StyledStatsHeading title="Pending Amount">
                Pending Amount
              </StyledStatsHeading>
            </StyledStatsCounter>
          </StyledStatsSection>
          <StyledStatsSection>
            <StyledStatsIcon>
              <FontAwesomeIcon icon={faFlag} />
            </StyledStatsIcon>
            <StyledStatsCounter>
              <StyledStatsCount>
                {formatCurrency(stats?.complaintAmount)} ({" "}
                {stats?.complaintCount} )
              </StyledStatsCount>
              <StyledStatsHeading title="Complaint Amount">
                Complaint Amount
              </StyledStatsHeading>
            </StyledStatsCounter>
          </StyledStatsSection>
        </StyledStatsContainer>
      </StyledStatsContainerOuter>
      <StyledHeading>Purchase Summary</StyledHeading>
      <StyledStatsContainerOuter>
        <StyledStatsContainer>
          <StyledStatsSection>
            <StyledStatsIcon>
              <FontAwesomeIcon icon={faIndianRupeeSign} />
            </StyledStatsIcon>
            <StyledStatsCounter>
              <StyledStatsCount>
                {formatCurrency(stats?.purchaseApprovedTotal)} ({" "}
                {stats?.purchaseApprovedCount} )
              </StyledStatsCount>
              <StyledStatsHeading title="Purchase Approved">
                Purchase Approved
              </StyledStatsHeading>
            </StyledStatsCounter>
          </StyledStatsSection>
          <StyledStatsSection>
            <StyledStatsIcon>
              <FontAwesomeIcon icon={faIndianRupeeSign} />
            </StyledStatsIcon>
            <StyledStatsCounter>
              <StyledStatsCount>
                {formatCurrency(stats?.purchasePendingTotal)} ({" "}
                {stats?.purchasePendingCount} )
              </StyledStatsCount>
              <StyledStatsHeading title="Purchase Pending">
                Purchase Pending
              </StyledStatsHeading>
            </StyledStatsCounter>
          </StyledStatsSection>
          <StyledStatsSection>
            <StyledStatsIcon>
              <FontAwesomeIcon icon={faIndianRupeeSign} />
            </StyledStatsIcon>
            <StyledStatsCounter>
              <StyledStatsCount>
                {formatCurrency(stats?.purchaseRejectedTotal)} ({" "}
                {stats?.purchaseRejectedCount} )
              </StyledStatsCount>
              <StyledStatsHeading title="Purchase Rejected">
                Purchase Rejected
              </StyledStatsHeading>
            </StyledStatsCounter>
          </StyledStatsSection>
        </StyledStatsContainer>
      </StyledStatsContainerOuter>
      <StyledHeading>Withdrawal Summary</StyledHeading>
      <StyledStatsContainerOuter>
        <StyledStatsContainer>
          <StyledStatsSection>
            <StyledStatsIcon>
              <FontAwesomeIcon icon={faMoneyBills} />
            </StyledStatsIcon>
            <StyledStatsCounter>
              <StyledStatsCount>
                {formatCurrency(stats?.withdrawApprovedTotal)} ({" "}
                {stats?.withdrawApprovedCount} )
              </StyledStatsCount>
              <StyledStatsHeading title="Withdrawal Approved">
                Withdrawal Approved
              </StyledStatsHeading>
            </StyledStatsCounter>
          </StyledStatsSection>
          <StyledStatsSection>
            <StyledStatsIcon>
              <FontAwesomeIcon icon={faMoneyBillTransfer} />
            </StyledStatsIcon>
            <StyledStatsCounter>
              <StyledStatsCount>
                {formatCurrency(stats?.withdrawPendingTotal)} ({" "}
                {stats?.withdrawPendingCount} )
              </StyledStatsCount>
              <StyledStatsHeading title="Withdrawal Pending">
                Withdrawal Pending
              </StyledStatsHeading>
            </StyledStatsCounter>
          </StyledStatsSection>
          <StyledStatsSection>
            <StyledStatsIcon>
              <FontAwesomeIcon icon={faMoneyBillTrendUp} />
            </StyledStatsIcon>
            <StyledStatsCounter>
              <StyledStatsCount>
                {formatCurrency(stats?.withdrawRejectedTotal)} ({" "}
                {stats?.withdrawRejectedCount} )
              </StyledStatsCount>
              <StyledStatsHeading title="Withdrawal Rejected">
                Withdrawal Rejected
              </StyledStatsHeading>
            </StyledStatsCounter>
          </StyledStatsSection>
        </StyledStatsContainer>
      </StyledStatsContainerOuter>
    </>
  );
};

export default withAppHOC(Dashboard);
