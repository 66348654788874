import { MASTER_MODULES, MODULES, PERMISSION_TYPES } from "./index";

const {
  ACCOUNTS_MANAGEMENT,
  CONFIGURATIONS,
  RECHARGE_CONFIGURATIONS,
  TRANSACTIONS,
  REPORTS,
  INVOICING,
} = MASTER_MODULES;
const {
  // Accounts Management - ACCOUNTS_MANAGEMENT
  USERS,
  USERS_KYC,
  USERS_BANK_ACCOUNTS,
  USERS_FUND_REQUESTS,
  USERS_WITHDRAW_REQUESTS,
  STAFF_ACCOUNTS,
  ROLES_AND_PERMISSIONS,
  // Configurations - CONFIGURATIONS
  FIRM_DETAILS,
  APP_CONFIGURATION,
  BANK_ACCOUNTS,
  SCROLL_MESSAGE,
  SMS_API,
  WHATSAPP_API,
  // Recharge Configurations - RECHARGE_CONFIGURATIONS
  CIRCLES,
  OPERATORS,
  ADMIN_OPERATOR_MARGINS,
  PLAN_API_CONFIGURATION,
  SELLER_APIS,
  OPERATORS_BLOCKER,
  BUYER_MARGINS,
  SELLER_MARGINS,
  // Transactions - TRANSACTIONS
  BUYER_RECHARGE_TRANSACTIONS,
  SELLER_RECHARGE_TRANSACTIONS,
  PENDING_RECHARGE_TRANSACTIONS,
  DISPUTED_RECHARGE_TRANSACTIONS,
  LIVE_RECHARGE_TRANSACTIONS,
  // Reports - REPORTS
  BUYERS_OPERATORS_DAY_BOOK,
  SELLERS_OPERATORS_DAY_BOOK,
  DAY_BOOK,
  // Invoicing - INVOICING
  BUYER_INVOICES,
  SELLER_INVOICES,
} = MODULES;
const {
  CREATE,
  READ,
  UPDATE,
  DELETE,
  TRACK,
  WALLET_TRANSACTIONS,
  FUND_SWITCHING_TRANSACTIONS,
  READ_LATEST_OTP,
  WALLET_PROCESS_FUNDS,
  REFUND,RELOAD
} = PERMISSION_TYPES;

const modulesMapper = [
  // Accounts Management
  {
    moduleName: USERS,
    masterModule: ACCOUNTS_MANAGEMENT,
    permissions: [
      {
        permission: READ,
      },
      {
        permission: CREATE,
        dependencyPermissions: [READ],
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
      {
        permission: WALLET_TRANSACTIONS,
        dependencyPermissions: [READ],
      },
      {
        permission: FUND_SWITCHING_TRANSACTIONS,
        dependencyPermissions: [READ],
      },
      {
        permission: READ_LATEST_OTP,
        dependencyPermissions: [READ],
      },
      {
        permission: WALLET_PROCESS_FUNDS,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: USERS_KYC,
    masterModule: ACCOUNTS_MANAGEMENT,
    permissions: [
      {
        permission: READ,
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: USERS_BANK_ACCOUNTS,
    masterModule: ACCOUNTS_MANAGEMENT,
    permissions: [
      {
        permission: READ,
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: USERS_FUND_REQUESTS,
    masterModule: ACCOUNTS_MANAGEMENT,
    permissions: [
      {
        permission: READ,
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: USERS_WITHDRAW_REQUESTS,
    masterModule: ACCOUNTS_MANAGEMENT,
    permissions: [
      {
        permission: READ,
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: STAFF_ACCOUNTS,
    masterModule: ACCOUNTS_MANAGEMENT,
    permissions: [
      {
        permission: READ,
      },
      {
        permission: CREATE,
        dependencyPermissions: [READ],
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
      {
        permission: READ_LATEST_OTP,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: ROLES_AND_PERMISSIONS,
    masterModule: ACCOUNTS_MANAGEMENT,
    permissions: [
      {
        permission: READ,
      },
      {
        permission: CREATE,
        dependencyPermissions: [READ],
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
      {
        permission: DELETE,
        dependencyPermissions: [READ],
      },
    ],
  },
  // Configurations
  {
    moduleName: FIRM_DETAILS,
    masterModule: CONFIGURATIONS,
    permissions: [
      {
        permission: READ,
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: APP_CONFIGURATION,
    masterModule: CONFIGURATIONS,
    permissions: [
      {
        permission: READ,
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: BANK_ACCOUNTS,
    masterModule: CONFIGURATIONS,
    permissions: [
      {
        permission: READ,
      },
      {
        permission: CREATE,
        dependencyPermissions: [READ],
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
      {
        permission: DELETE,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: SCROLL_MESSAGE,
    masterModule: CONFIGURATIONS,
    permissions: [
      {
        permission: READ,
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: SMS_API,
    masterModule: CONFIGURATIONS,
    permissions: [
      {
        permission: READ,
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: WHATSAPP_API,
    masterModule: CONFIGURATIONS,
    permissions: [
      {
        permission: READ,
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
    ],
  },
  // Recharge Configurations
  {
    moduleName: CIRCLES,
    masterModule: RECHARGE_CONFIGURATIONS,
    permissions: [
      {
        permission: READ,
      },
      {
        permission: CREATE,
        dependencyPermissions: [READ],
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
      {
        permission: DELETE,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: OPERATORS,
    masterModule: RECHARGE_CONFIGURATIONS,
    permissions: [
      {
        permission: READ,
      },
      {
        permission: CREATE,
        dependencyPermissions: [READ],
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
      {
        permission: DELETE,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: ADMIN_OPERATOR_MARGINS,
    masterModule: RECHARGE_CONFIGURATIONS,
    permissions: [
      {
        permission: READ,
      },
      {
        permission: CREATE,
        dependencyPermissions: [READ],
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
      {
        permission: DELETE,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: PLAN_API_CONFIGURATION,
    masterModule: RECHARGE_CONFIGURATIONS,
    permissions: [
      {
        permission: READ,
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: SELLER_APIS,
    masterModule: RECHARGE_CONFIGURATIONS,
    permissions: [
      {
        permission: READ,
      },
      {
        permission: CREATE,
        dependencyPermissions: [READ],
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: OPERATORS_BLOCKER,
    masterModule: RECHARGE_CONFIGURATIONS,
    permissions: [
      {
        permission: READ,
      },
      {
        permission: CREATE,
        dependencyPermissions: [READ],
      },
      {
        permission: DELETE,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: BUYER_MARGINS,
    masterModule: RECHARGE_CONFIGURATIONS,
    permissions: [
      {
        permission: READ,
      },
    ],
  },
  {
    moduleName: SELLER_MARGINS,
    masterModule: RECHARGE_CONFIGURATIONS,
    permissions: [
      {
        permission: READ,
      },
    ],
  },
  // Transactions
  {
    moduleName: BUYER_RECHARGE_TRANSACTIONS,
    masterModule: TRANSACTIONS,
    permissions: [
      {
        permission: READ,
      },
      {
        permission: TRACK,
        dependencyPermissions: [READ],
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
      {
        permission: RELOAD,
        dependencyPermissions: [READ],
      },
      {
        permission: REFUND,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: SELLER_RECHARGE_TRANSACTIONS,
    masterModule: TRANSACTIONS,
    permissions: [
      {
        permission: READ,
      },
      {
        permission: TRACK,
        dependencyPermissions: [READ],
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
      {
        permission: RELOAD,
        dependencyPermissions: [READ],
      },
      {
        permission: REFUND,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: PENDING_RECHARGE_TRANSACTIONS,
    masterModule: TRANSACTIONS,
    permissions: [
      {
        permission: READ,
      },
      {
        permission: TRACK,
        dependencyPermissions: [READ],
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
      {
        permission: RELOAD,
        dependencyPermissions: [READ],
      },
      {
        permission: REFUND,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: DISPUTED_RECHARGE_TRANSACTIONS,
    masterModule: TRANSACTIONS,
    permissions: [
      {
        permission: READ,
      },
      {
        permission: TRACK,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: LIVE_RECHARGE_TRANSACTIONS,
    masterModule: TRANSACTIONS,
    permissions: [
      {
        permission: READ,
      },
    ],
  },
  // Reports
  {
    moduleName: BUYERS_OPERATORS_DAY_BOOK,
    masterModule: REPORTS,
    permissions: [
      {
        permission: READ,
      },
    ],
  },
  {
    moduleName: SELLERS_OPERATORS_DAY_BOOK,
    masterModule: REPORTS,
    permissions: [
      {
        permission: READ,
      },
    ],
  },
  {
    moduleName: DAY_BOOK,
    masterModule: REPORTS,
    permissions: [
      {
        permission: READ,
      },
    ],
  },
  // Invoicing
  {
    moduleName: BUYER_INVOICES,
    masterModule: INVOICING,
    permissions: [
      {
        permission: READ,
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
    ],
  },
  {
    moduleName: SELLER_INVOICES,
    masterModule: INVOICING,
    permissions: [
      {
        permission: READ,
      },
      {
        permission: UPDATE,
        dependencyPermissions: [READ],
      },
    ],
  },
];

export default modulesMapper;
