import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { withAppHOC } from "../../../hoc";
import PageHeading from "../../../components/PageHeader/PageHeader";
import OverlayLoader from "../../../components/OverlayLoader/OverlayLoader";
import { Grid, GridItem } from "../../../components/Grid";
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  Dropdown,
  MessageBox,
  UpdateButton,
  ResetButton,
  Legend,
} from "../../../components/FormElements";
import { StyledMicroButton } from "../../../components/Styled";
import API from "../../../api";
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from "../../../constants";
import { getReturnUrl } from "../../../utils";

const APIProvidersUpdate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [users, setUsers] = useState([]);
  const [usersId, setUsersId] = useState("");
  const [apiName, setApiName] = useState("");
  const [ipAddress, setIpAddress] = useState("");
  const [apiEndpoint, setApiEndpoint] = useState("");
  const [apiRequestMethod, setApiRequestMethod] = useState("");
  const [apiRequestBody, setApiRequestBody] = useState("");
  const [apiResponseType, setApiResponseType] = useState("");
  const [statusKey, setStatusKey] = useState("");
  const [successValue, setSuccessValue] = useState("");
  const [failureValue, setFailureValue] = useState("");
  const [transactionIdKey, setTransactionIdKey] = useState("");
  const [operatorsIdKey, setOperatorsIdKey] = useState("");
  const [messageKey, setMessageKey] = useState("");
  const [canCheckStatus, setCanCheckStatus] = useState("No");
  const [checkStatusApiEndpoint, setCheckStatusApiEndpoint] = useState("");
  const [checkStatusApiRequestMethod, setCheckStatusApiRequestMethod] =
    useState("");
  const [checkStatusApiRequestBody, setCheckStatusApiRequestBody] =
    useState("");
  const [checkStatusApiResponseType, setCheckStatusApiResponseType] =
    useState("");
  const [checkStatusStatusKey, setCheckStatusStatusKey] = useState("");
  const [checkStatusSuccessValue, setCheckStatusSuccessValue] = useState("");
  const [checkStatusFailureValue, setCheckStatusFailureValue] = useState("");
  const [checkStatusOperatorsIdKey, setCheckStatusOperatorsIdKey] =
    useState("");
  const [canBalanceCheck, setCanBalanceCheck] = useState("No");
  const [balanceCheckApiEndpoint, setBalanceCheckApiEndpoint] = useState("");
  const [balanceCheckApiRequestMethod, setBalanceCheckApiRequestMethod] =
    useState("");
  const [balanceCheckApiRequestBody, setBalanceCheckApiRequestBody] =
    useState("");
  const [balanceCheckApiResponseType, setBalanceCheckApiResponseType] =
    useState("");
  const [balanceCheckBalanceKey, setBalanceCheckBalanceKey] = useState("");
  const [canDisputeCheck, setCanDisputeCheck] = useState("No");
  const [disputeApiEndpoint, setDisputeApiEndpoint] = useState("");
  const [disputeApiRequestMethod, setDisputeApiRequestMethod] = useState("");
  const [disputeApiRequestBody, setDisputeApiRequestBody] = useState("");
  const [disputeApiResponseType, setDisputeApiResponseType] = useState("");
  const [callbackOurTransactionId, setCallbackOurTransactionId] = useState("");
  const [callbackApiTransactionId, setCallbackApiTransactionId] = useState("");
  const [callbackStatusKey, setCallbackStatusKey] = useState("");
  const [callbackSuccessValue, setCallbackSuccessValue] = useState("");
  const [callbackFailureValue, setCallbackFailureValue] = useState("");
  const [callbackOperatorsId, setCallbackOperatorsId] = useState("");
  const [apiStatus, setApiStatus] = useState("Active");
  const [adminsApiStatus, setAdminsApiStatus] = useState("Active");
  const [remark, setRemark] = useState("");
  const [originalFormData, setOriginalFormData] = useState({});
  const { id } = useParams();

  const requestOptions = [
    {
      title: "-- SELECT REQUEST METHOD --",
      value: "",
    },
    {
      title: "GET",
      value: "GET",
    },
    {
      title: "POST",
      value: "POST",
    },
  ];

  const responseOptions = [
    {
      title: "-- SELECT RESPONSE TYPE --",
      value: "",
    },
    {
      title: "JSON",
      value: "JSON",
    },
    {
      title: "XML",
      value: "XML",
    },
  ];

  const yesNoOptions = [
    {
      title: "-- SELECT STATUS --",
      value: "",
    },
    {
      title: "Yes",
      value: "Yes",
    },
    {
      title: "No",
      value: "No",
    },
  ];

  const apiStatusOptions = [
    {
      title: "-- SELECT API STATUS --",
      value: "",
    },
    {
      title: "Active",
      value: "Active",
    },
    {
      title: "Inactive",
      value: "Inactive",
    },
  ];

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus("");
    setResponseMessage("");
  };

  const resetForm = () => {
    const {
      usersId,
      apiName,
      ipAddress,
      apiEndpoint,
      apiRequestMethod,
      apiRequestBody,
      apiResponseType,
      statusKey,
      successValue,
      failureValue,
      transactionIdKey,
      operatorsIdKey,
      messageKey,
      canCheckStatus,
      checkStatusApiEndpoint,
      checkStatusApiRequestMethod,
      checkStatusApiRequestBody,
      checkStatusApiResponseType,
      checkStatusStatusKey,
      checkStatusSuccessValue,
      checkStatusFailureValue,
      checkStatusOperatorsIdKey,
      canBalanceCheck,
      balanceCheckApiEndpoint,
      balanceCheckApiRequestMethod,
      balanceCheckApiRequestBody,
      balanceCheckApiResponseType,
      balanceCheckBalanceKey,
      canDisputeCheck,
      disputeApiEndpoint,
      disputeApiRequestMethod,
      disputeApiRequestBody,
      disputeApiResponseType,
      callbackOurTransactionId,
      callbackApiTransactionId,
      callbackStatusKey,
      callbackSuccessValue,
      callbackFailureValue,
      callbackOperatorsId,
      apiStatus,
      adminsApiStatus,
      remark,
    } = originalFormData;
    setUsersId(usersId);
    setApiName(apiName);
    setIpAddress(ipAddress);
    setApiEndpoint(apiEndpoint);
    setApiRequestMethod(apiRequestMethod);
    setApiRequestBody(apiRequestBody);
    setApiResponseType(apiResponseType);
    setStatusKey(statusKey);
    setSuccessValue(successValue);
    setFailureValue(failureValue);
    setTransactionIdKey(transactionIdKey);
    setOperatorsIdKey(operatorsIdKey);
    setMessageKey(messageKey);
    setCanCheckStatus(canCheckStatus);
    setCheckStatusApiEndpoint(checkStatusApiEndpoint);
    setCheckStatusApiRequestMethod(checkStatusApiRequestMethod);
    setCheckStatusApiRequestBody(checkStatusApiRequestBody);
    setCheckStatusApiResponseType(checkStatusApiResponseType);
    setCheckStatusStatusKey(checkStatusStatusKey);
    setCheckStatusSuccessValue(checkStatusSuccessValue);
    setCheckStatusFailureValue(checkStatusFailureValue);
    setCheckStatusOperatorsIdKey(checkStatusOperatorsIdKey);
    setCanBalanceCheck(canBalanceCheck);
    setBalanceCheckApiEndpoint(balanceCheckApiEndpoint);
    setBalanceCheckApiRequestMethod(balanceCheckApiRequestMethod);
    setBalanceCheckApiRequestBody(balanceCheckApiRequestBody);
    setBalanceCheckApiResponseType(balanceCheckApiResponseType);
    setBalanceCheckBalanceKey(balanceCheckBalanceKey);
    setCanDisputeCheck(canDisputeCheck);
    setDisputeApiEndpoint(disputeApiEndpoint);
    setDisputeApiRequestMethod(disputeApiRequestMethod);
    setDisputeApiRequestBody(disputeApiRequestBody);
    setDisputeApiResponseType(disputeApiResponseType);
    setCallbackOurTransactionId(callbackOurTransactionId);
    setCallbackApiTransactionId(callbackApiTransactionId);
    setCallbackStatusKey(callbackStatusKey);
    setCallbackSuccessValue(callbackSuccessValue);
    setCallbackFailureValue(callbackFailureValue);
    setCallbackOperatorsId(callbackOperatorsId);
    setApiStatus(apiStatus);
    setAdminsApiStatus(adminsApiStatus);
    setRemark(remark);
  };

  const readUsers = useCallback(() => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.get("/accounts-management/users?pageNumber=1&recordsPerPage=100000")
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILED) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          setUsers(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILED);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const readData = useCallback(() => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.get(`/recharge-configurations/seller-apis/${id}`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILED) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          const {
            usersId,
            apiName,
            ipAddress,
            apiEndpoint,
            apiRequestMethod,
            apiRequestBody,
            apiResponseType,
            statusKey,
            successValue,
            failureValue,
            transactionIdKey,
            operatorsIdKey,
            messageKey,
            canCheckStatus,
            checkStatusApiEndpoint,
            checkStatusApiRequestMethod,
            checkStatusApiRequestBody,
            checkStatusApiResponseType,
            checkStatusStatusKey,
            checkStatusSuccessValue,
            checkStatusFailureValue,
            checkStatusOperatorsIdKey,
            canBalanceCheck,
            balanceCheckApiEndpoint,
            balanceCheckApiRequestMethod,
            balanceCheckApiRequestBody,
            balanceCheckApiResponseType,
            balanceCheckBalanceKey,
            canDisputeCheck,
            disputeApiEndpoint,
            disputeApiRequestMethod,
            disputeApiRequestBody,
            disputeApiResponseType,
            callbackOurTransactionId,
            callbackApiTransactionId,
            callbackStatusKey,
            callbackSuccessValue,
            callbackFailureValue,
            callbackOperatorsId,
            apiStatus,
            adminsApiStatus,
            remark,
          } = data[0];
          setUsersId(usersId);
          setApiName(apiName);
          setIpAddress(ipAddress);
          setApiEndpoint(apiEndpoint);
          setApiRequestMethod(apiRequestMethod);
          setApiRequestBody(apiRequestBody);
          setApiResponseType(apiResponseType);
          setStatusKey(statusKey);
          setSuccessValue(successValue);
          setFailureValue(failureValue);
          setTransactionIdKey(transactionIdKey);
          setOperatorsIdKey(operatorsIdKey);
          setMessageKey(messageKey);
          setCanCheckStatus(canCheckStatus);
          setCheckStatusApiEndpoint(checkStatusApiEndpoint);
          setCheckStatusApiRequestMethod(checkStatusApiRequestMethod);
          setCheckStatusApiRequestBody(checkStatusApiRequestBody);
          setCheckStatusApiResponseType(checkStatusApiResponseType);
          setCheckStatusStatusKey(checkStatusStatusKey);
          setCheckStatusSuccessValue(checkStatusSuccessValue);
          setCheckStatusFailureValue(checkStatusFailureValue);
          setCheckStatusOperatorsIdKey(checkStatusOperatorsIdKey);
          setCanBalanceCheck(canBalanceCheck);
          setBalanceCheckApiEndpoint(balanceCheckApiEndpoint);
          setBalanceCheckApiRequestMethod(balanceCheckApiRequestMethod);
          setBalanceCheckApiRequestBody(balanceCheckApiRequestBody);
          setBalanceCheckApiResponseType(balanceCheckApiResponseType);
          setBalanceCheckBalanceKey(balanceCheckBalanceKey);
          setCanDisputeCheck(canDisputeCheck);
          setDisputeApiEndpoint(disputeApiEndpoint);
          setDisputeApiRequestMethod(disputeApiRequestMethod);
          setDisputeApiRequestBody(disputeApiRequestBody);
          setDisputeApiResponseType(disputeApiResponseType);
          setCallbackOurTransactionId(callbackOurTransactionId);
          setCallbackApiTransactionId(callbackApiTransactionId);
          setCallbackStatusKey(callbackStatusKey);
          setCallbackSuccessValue(callbackSuccessValue);
          setCallbackFailureValue(callbackFailureValue);
          setCallbackOperatorsId(callbackOperatorsId);
          setApiStatus(apiStatus);
          setAdminsApiStatus(adminsApiStatus);
          setRemark(remark);
          setOriginalFormData({
            usersId,
            apiName,
            ipAddress,
            apiEndpoint,
            apiRequestMethod,
            apiRequestBody,
            apiResponseType,
            statusKey,
            successValue,
            failureValue,
            transactionIdKey,
            operatorsIdKey,
            messageKey,
            canCheckStatus,
            checkStatusApiEndpoint,
            checkStatusApiRequestMethod,
            checkStatusApiRequestBody,
            checkStatusApiResponseType,
            checkStatusStatusKey,
            checkStatusSuccessValue,
            checkStatusFailureValue,
            checkStatusOperatorsIdKey,
            canBalanceCheck,
            balanceCheckApiEndpoint,
            balanceCheckApiRequestMethod,
            balanceCheckApiRequestBody,
            balanceCheckApiResponseType,
            balanceCheckBalanceKey,
            canDisputeCheck,
            disputeApiEndpoint,
            disputeApiRequestMethod,
            disputeApiRequestBody,
            disputeApiResponseType,
            callbackOurTransactionId,
            callbackApiTransactionId,
            callbackStatusKey,
            callbackSuccessValue,
            callbackFailureValue,
            callbackOperatorsId,
            apiStatus,
            adminsApiStatus,remark,
          });
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILED);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    const formData = {
      usersId,
      apiName,
      ipAddress,
      apiEndpoint,
      apiRequestMethod,
      apiRequestBody,
      apiResponseType,
      statusKey,
      successValue,
      failureValue,
      transactionIdKey,
      operatorsIdKey,
      messageKey,
      canCheckStatus,
      checkStatusApiEndpoint,
      checkStatusApiRequestMethod,
      checkStatusApiRequestBody,
      checkStatusApiResponseType,
      checkStatusStatusKey,
      checkStatusSuccessValue,
      checkStatusFailureValue,
      checkStatusOperatorsIdKey,
      canBalanceCheck,
      balanceCheckApiEndpoint,
      balanceCheckApiRequestMethod,
      balanceCheckApiRequestBody,
      balanceCheckApiResponseType,
      balanceCheckBalanceKey,
      canDisputeCheck,
      disputeApiEndpoint,
      disputeApiRequestMethod,
      disputeApiRequestBody,
      disputeApiResponseType,
      callbackOurTransactionId,
      callbackApiTransactionId,
      callbackStatusKey,
      callbackSuccessValue,
      callbackFailureValue,
      callbackOperatorsId,
      apiStatus,
      adminsApiStatus,
      remark,
    };
    API.put(`/recharge-configurations/seller-apis/${id}`, formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setOriginalFormData(formData);
          setTimeout(() => {
            resetResponseData();
            window.location.href = getReturnUrl(PERMISSION_TYPES.UPDATE);
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILED);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const addText = (setMethod, value) => {
    setMethod((currentValue) => currentValue + value);
  };

  useEffect(() => {
    readUsers();
    readData();
  }, [readUsers, readData]);

  const usersOptions = [{ title: "-- SELECT USER --", value: "" }];
  users.forEach(({ id, fullName }) =>
    usersOptions.push({
      title: fullName,
      value: id,
    })
  );

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method="POST" action="#" onSubmit={onSubmit}>
          <Fieldset>
            <Label required>User</Label>
            <Dropdown
              value={usersId}
              onChange={setUsersId}
              placeholder="Please select user"
              disabled={true}
              options={usersOptions}
              searchable
            />
          </Fieldset>
          <Grid columns={"calc(50% - 10px) calc(50% - 10px)"}>
            <GridItem>
              <Fieldset display="block">
                <Label required>API Name</Label>
                <TextInput
                  value={apiName}
                  onChange={setApiName}
                  placeholder="Please enter api name"
                  maxLength={100}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset display="block">
                <Label required>IP Address</Label>
                <TextInput
                  value={ipAddress}
                  onChange={setIpAddress}
                  placeholder="Please enter ip address"
                  maxLength={15}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Fieldset display="block">
            <Label required>API Endpoint</Label>
            <TextInput
              value={apiEndpoint}
              onChange={setApiEndpoint}
              placeholder="Please enter api endpoint"
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset display="block">
            <Label required>API Request Body</Label>
            <TextInput
              value={apiRequestBody}
              onChange={setApiRequestBody}
              placeholder="Please enter api request body"
              disabled={isLoading}
            />
            <StyledMicroButton
              disabled={isLoading}
              type="button"
              onClick={() => addText(setApiRequestBody, "{{{NUMBER}}}")}
            >
              Mobile/Account Number
            </StyledMicroButton>
            <StyledMicroButton
              disabled={isLoading}
              type="button"
              onClick={() => addText(setApiRequestBody, "{{{OPERATOR}}}")}
            >
              Operator
            </StyledMicroButton>
            <StyledMicroButton
              disabled={isLoading}
              type="button"
              onClick={() => addText(setApiRequestBody, "{{{AMOUNT}}}")}
            >
              Amount
            </StyledMicroButton>
            <StyledMicroButton
              disabled={isLoading}
              type="button"
              onClick={() => addText(setApiRequestBody, "{{{TRANSACTION_ID}}}")}
            >
              Transaction Id
            </StyledMicroButton>
            <StyledMicroButton
              disabled={isLoading}
              type="button"
              onClick={() => addText(setApiRequestBody, "{{{FIELD1}}}")}
            >
              Field 1
            </StyledMicroButton>
            <StyledMicroButton
              disabled={isLoading}
              type="button"
              onClick={() => addText(setApiRequestBody, "{{{FIELD2}}}")}
            >
              Field 2
            </StyledMicroButton>
            <StyledMicroButton
              disabled={isLoading}
              type="button"
              onClick={() => addText(setApiRequestBody, "{{{FIELD3}}}")}
            >
              Field 3
            </StyledMicroButton>
            <StyledMicroButton
              disabled={isLoading}
              type="button"
              onClick={() => addText(setApiRequestBody, "{{{FIELD4}}}")}
            >
              Field 4
            </StyledMicroButton>
          </Fieldset>
          <Grid columns={"calc(50% - 10px) calc(50% - 10px)"}>
            <GridItem>
              <Fieldset display="block">
                <Label required>API Request Method</Label>
                <Dropdown
                  value={apiRequestMethod}
                  onChange={setApiRequestMethod}
                  placeholder="Please select api request method"
                  disabled={isLoading}
                  options={requestOptions}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset display="block">
                <Label required>API Response Type</Label>
                <Dropdown
                  value={apiResponseType}
                  onChange={setApiResponseType}
                  placeholder="Please select api response type"
                  disabled={isLoading}
                  options={responseOptions}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid
            columns={
              "calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)"
            }
          >
            <GridItem>
              <Fieldset display="block">
                <Label required>Status Key</Label>
                <TextInput
                  value={statusKey}
                  onChange={setStatusKey}
                  placeholder="Please enter status key"
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset display="block">
                <Label required>Success Value</Label>
                <TextInput
                  value={successValue}
                  onChange={setSuccessValue}
                  placeholder="Please enter success value"
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset display="block">
                <Label required>Failure Value</Label>
                <TextInput
                  value={failureValue}
                  onChange={setFailureValue}
                  placeholder="Please enter failure value"
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid
            columns={
              "calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)"
            }
          >
            <GridItem>
              <Fieldset display="block">
                <Label required>Transaction ID Key</Label>
                <TextInput
                  value={transactionIdKey}
                  onChange={setTransactionIdKey}
                  placeholder="Please enter transaction id key"
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset display="block">
                <Label required>Operators ID Key</Label>
                <TextInput
                  value={operatorsIdKey}
                  onChange={setOperatorsIdKey}
                  placeholder="Please enter operators id key"
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset display="block">
                <Label>Message Key</Label>
                <TextInput
                  value={messageKey}
                  onChange={setMessageKey}
                  placeholder="Please enter message key"
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Fieldset display="block">
            <Label required>Can Check Status?</Label>
            <Dropdown
              value={canCheckStatus}
              onChange={setCanCheckStatus}
              placeholder="Please select if status can be checked via api"
              disabled={isLoading}
              options={yesNoOptions}
            />
          </Fieldset>
          {canCheckStatus === "Yes" && (
            <Fieldset forLegend display="block">
              <Legend>Check Status API Configuration</Legend>
              <Fieldset display="block">
                <Label required>Check Status API Endpoint</Label>
                <TextInput
                  value={checkStatusApiEndpoint}
                  onChange={setCheckStatusApiEndpoint}
                  placeholder="Please enter check status api endpoint"
                  disabled={isLoading}
                />
              </Fieldset>
              <Fieldset display="block">
                <Label required>Check Status API Request Body</Label>
                <TextInput
                  value={checkStatusApiRequestBody}
                  onChange={setCheckStatusApiRequestBody}
                  placeholder="Please enter check status api request body"
                  disabled={isLoading}
                />
                <StyledMicroButton
                  disabled={isLoading}
                  type="button"
                  onClick={() =>
                    addText(
                      setCheckStatusApiRequestBody,
                      "{{{TRANSACTION_ID}}}"
                    )
                  }
                >
                  Transaction Id
                </StyledMicroButton>
                <StyledMicroButton
                  disabled={isLoading}
                  type="button"
                  onClick={() =>
                    addText(
                      setCheckStatusApiRequestBody,
                      "{{{API_TRANSACTION_ID}}}"
                    )
                  }
                >
                  API Transaction Id
                </StyledMicroButton>
              </Fieldset>
              <Grid columns={"calc(50% - 10px) calc(50% - 10px)"}>
                <GridItem>
                  <Fieldset display="block">
                    <Label required>Check Status API Request Method</Label>
                    <Dropdown
                      value={checkStatusApiRequestMethod}
                      onChange={setCheckStatusApiRequestMethod}
                      placeholder="Please select check status api request method"
                      disabled={isLoading}
                      options={requestOptions}
                    />
                  </Fieldset>
                </GridItem>
                <GridItem>
                  <Fieldset display="block">
                    <Label required>Check Status API Response Type</Label>
                    <Dropdown
                      value={checkStatusApiResponseType}
                      onChange={setCheckStatusApiResponseType}
                      placeholder="Please select check status api response type"
                      disabled={isLoading}
                      options={responseOptions}
                    />
                  </Fieldset>
                </GridItem>
              </Grid>
              <Grid columns={"calc(50% - 10px) calc(50% - 10px)"}>
                <GridItem>
                  <Fieldset display="block">
                    <Label required>Check Status Status Key</Label>
                    <TextInput
                      value={checkStatusStatusKey}
                      onChange={setCheckStatusStatusKey}
                      placeholder="Please enter check status status key"
                      disabled={isLoading}
                    />
                  </Fieldset>
                </GridItem>
                <GridItem>
                  <Fieldset display="block">
                    <Label required>Check Status Operators ID Key</Label>
                    <TextInput
                      value={checkStatusOperatorsIdKey}
                      onChange={setCheckStatusOperatorsIdKey}
                      placeholder="Please enter check status operators id key"
                      disabled={isLoading}
                    />
                  </Fieldset>
                </GridItem>
              </Grid>
              <Grid columns={"calc(50% - 10px) calc(50% - 10px)"}>
                <GridItem>
                  <Fieldset display="block">
                    <Label required>Check Status success value</Label>
                    <TextInput
                      value={checkStatusSuccessValue}
                      onChange={setCheckStatusSuccessValue}
                      placeholder="Please enter check status success value"
                      disabled={isLoading}
                    />
                  </Fieldset>
                </GridItem>
                <GridItem>
                  <Fieldset display="block">
                    <Label required>Check Status failure value</Label>
                    <TextInput
                      value={checkStatusFailureValue}
                      onChange={setCheckStatusFailureValue}
                      placeholder="Please enter check status failure value"
                      disabled={isLoading}
                    />
                  </Fieldset>
                </GridItem>
              </Grid>
            </Fieldset>
          )}
          <Fieldset display="block">
            <Label required>Can Check Balance?</Label>
            <Dropdown
              value={canBalanceCheck}
              onChange={setCanBalanceCheck}
              placeholder="Please select if balance can be checked via api"
              disabled={isLoading}
              options={yesNoOptions}
            />
          </Fieldset>
          {canBalanceCheck === "Yes" && (
            <Fieldset forLegend display="block">
              <Legend>Balance Check API Configuration</Legend>
              <Fieldset display="block">
                <Label required>Balance Check API Endpoint</Label>
                <TextInput
                  value={balanceCheckApiEndpoint}
                  onChange={setBalanceCheckApiEndpoint}
                  placeholder="Please enter balance check api endpoint"
                  disabled={isLoading}
                />
              </Fieldset>
              <Fieldset display="block">
                <Label required>Balance Check API Request Body</Label>
                <TextInput
                  value={balanceCheckApiRequestBody}
                  onChange={setBalanceCheckApiRequestBody}
                  placeholder="Please enter balance check api request body"
                  disabled={isLoading}
                />
              </Fieldset>
              <Grid columns={"calc(50% - 10px) calc(50% - 10px)"}>
                <GridItem>
                  <Fieldset display="block">
                    <Label required>Balance Check API Request Method</Label>
                    <Dropdown
                      value={balanceCheckApiRequestMethod}
                      onChange={setBalanceCheckApiRequestMethod}
                      placeholder="Please select balance check api request method"
                      disabled={isLoading}
                      options={requestOptions}
                    />
                  </Fieldset>
                </GridItem>
                <GridItem>
                  <Fieldset display="block">
                    <Label required>Balance Check API Response Type</Label>
                    <Dropdown
                      value={balanceCheckApiResponseType}
                      onChange={setBalanceCheckApiResponseType}
                      placeholder="Please select balance check api response type"
                      disabled={isLoading}
                      options={responseOptions}
                    />
                  </Fieldset>
                </GridItem>
              </Grid>
              <Fieldset display="block">
                <Label required>Balance Check Balance Key</Label>
                <TextInput
                  value={balanceCheckBalanceKey}
                  onChange={setBalanceCheckBalanceKey}
                  placeholder="Please enter balance check balance key"
                  disabled={isLoading}
                />
              </Fieldset>
            </Fieldset>
          )}
          <Fieldset display="block">
            <Label required>Can Dispute Check?</Label>
            <Dropdown
              value={canDisputeCheck}
              onChange={setCanDisputeCheck}
              placeholder="Please select if dispute can be checked via api"
              disabled={isLoading}
              options={yesNoOptions}
            />
          </Fieldset>
          {canDisputeCheck === "Yes" && (
            <Fieldset forLegend display="block">
              <Legend>Dispute Check API Configuration</Legend>
              <Fieldset display="block">
                <Label required>Dispute Check API Endpoint</Label>
                <TextInput
                  value={disputeApiEndpoint}
                  onChange={setDisputeApiEndpoint}
                  placeholder="Please enter dispute check api endpoint"
                  disabled={isLoading}
                />
              </Fieldset>
              <Fieldset display="block">
                <Label required>Dispute Check API Request Body</Label>
                <TextInput
                  value={disputeApiRequestBody}
                  onChange={setDisputeApiRequestBody}
                  placeholder="Please enter dispute check api request body"
                  disabled={isLoading}
                />
                <StyledMicroButton
                  disabled={isLoading}
                  type="button"
                  onClick={() =>
                    addText(setDisputeApiRequestBody, "{{{TRANSACTION_ID}}}")
                  }
                >
                  Transaction Id
                </StyledMicroButton>
                <StyledMicroButton
                  disabled={isLoading}
                  type="button"
                  onClick={() =>
                    addText(
                      setDisputeApiRequestBody,
                      "{{{API_TRANSACTION_ID}}}"
                    )
                  }
                >
                  API Transaction Id
                </StyledMicroButton>
              </Fieldset>
              <Grid columns={"calc(50% - 10px) calc(50% - 10px)"}>
                <GridItem>
                  <Fieldset display="block">
                    <Label required>Dispute Check API Request Method</Label>
                    <Dropdown
                      value={disputeApiRequestMethod}
                      onChange={setDisputeApiRequestMethod}
                      placeholder="Please select dispute check api request method"
                      disabled={isLoading}
                      options={requestOptions}
                    />
                  </Fieldset>
                </GridItem>
                <GridItem>
                  <Fieldset display="block">
                    <Label required>Dispute Check API Response Type</Label>
                    <Dropdown
                      value={disputeApiResponseType}
                      onChange={setDisputeApiResponseType}
                      placeholder="Please select dispute check api response type"
                      disabled={isLoading}
                      options={responseOptions}
                    />
                  </Fieldset>
                </GridItem>
              </Grid>
            </Fieldset>
          )}
          <Fieldset forLegend display="block">
            <Legend>Callback Configuration</Legend>
            <Grid
              columns={
                "calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)"
              }
            >
              <Fieldset display="block">
                <Label required>Status Key</Label>
                <TextInput
                  value={callbackStatusKey}
                  onChange={setCallbackStatusKey}
                  placeholder="Please enter status key"
                  disabled={isLoading}
                />
              </Fieldset>
              <Fieldset display="block">
                <Label required>Success Value</Label>
                <TextInput
                  value={callbackSuccessValue}
                  onChange={setCallbackSuccessValue}
                  placeholder="Please enter success value"
                  disabled={isLoading}
                />
              </Fieldset>
              <Fieldset display="block">
                <Label required>Failure Value</Label>
                <TextInput
                  value={callbackFailureValue}
                  onChange={setCallbackFailureValue}
                  placeholder="Please enter failure value"
                  disabled={isLoading}
                />
              </Fieldset>
            </Grid>
            <Grid
              columns={
                "calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)"
              }
            >
              <Fieldset display="block">
                <Label required>Our Transaction Id Key</Label>
                <TextInput
                  value={callbackOurTransactionId}
                  onChange={setCallbackOurTransactionId}
                  placeholder="Please enter our transaction id key"
                  disabled={isLoading}
                />
              </Fieldset>
              <Fieldset display="block">
                <Label required>API Transaction Id Key</Label>
                <TextInput
                  value={callbackApiTransactionId}
                  onChange={setCallbackApiTransactionId}
                  placeholder="Please enter api transaction id key"
                  disabled={isLoading}
                />
              </Fieldset>
              <Fieldset display="block">
                <Label required>Operators ID Key</Label>
                <TextInput
                  value={callbackOperatorsId}
                  onChange={setCallbackOperatorsId}
                  placeholder="Please enter operators id key"
                  disabled={isLoading}
                />
              </Fieldset>
            </Grid>
          </Fieldset>
          <Fieldset display="block">
            <Label required>API Status</Label>
            <Dropdown
              value={apiStatus}
              onChange={setApiStatus}
              placeholder="Please select api status"
              disabled={isLoading}
              options={apiStatusOptions}
            />
          </Fieldset>
          <Fieldset display="block">
            <Label required>Admin API Status</Label>
            <Dropdown
              value={adminsApiStatus}
              onChange={setAdminsApiStatus}
              placeholder="Please select api status"
              disabled={isLoading}
              options={apiStatusOptions}
            />
          </Fieldset>
          <Fieldset display="block">
            <Label required={adminsApiStatus !== 'Active'}>Remark</Label>
            <TextInput
              value={remark}
              onChange={setRemark}
              placeholder="Please enter remark"
              disabled={isLoading}
            />
          </Fieldset>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <UpdateButton disabled={isLoading} type="submit">
              Update
            </UpdateButton>
            <ResetButton
              disabled={isLoading}
              type="button"
              onClick={resetForm}
            >
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(APIProvidersUpdate);
