import React, { useState, useEffect, useCallback } from "react";
import { withAppHOC } from "../../../hoc";
import PageHeading from "../../../components/PageHeader/PageHeader";
import OverlayLoader from "../../../components/OverlayLoader/OverlayLoader";
import { Grid, GridItem } from "../../../components/Grid";
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  Dropdown,
  MessageBox,
  UpdateButton,
  ResetButton,
} from "../../../components/FormElements";
import { StyledMicroButton } from "../../../components/Styled";
import API from "../../../api";
import { API_RESPONSE_TYPES } from "../../../constants";

const WhatsappAPIConfiguration = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [whatsappEndpoint, setWhatsappEndpoint] = useState("");
  const [whatsappBody, setWhatsappBody] = useState("");
  const [whatsappRequestMethod, setWhatsappRequestMethod] = useState("");
  const [whatsappResponseType, setWhatsappResponseType] = useState("");
  const [whatsappStatusKey, setWhatsappStatusKey] = useState("");
  const [whatsappSuccessValue, setWhatsappSuccessValue] = useState("");
  const [whatsappFailedValue, setWhatsappFailedValue] = useState("");
  const [whatsappRegards, setWhatsappRegards] = useState("");
  const [originalFormData, setOriginalFormData] = useState({});

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus("");
    setResponseMessage("");
  };

  const resetForm = () => {
    const {
      whatsappEndpoint,
      whatsappBody,
      whatsappRequestMethod,
      whatsappResponseType,
      whatsappStatusKey,
      whatsappSuccessValue,
      whatsappFailedValue,
      whatsappRegards,
    } = originalFormData;
    setWhatsappEndpoint(whatsappEndpoint);
    setWhatsappBody(whatsappBody);
    setWhatsappRequestMethod(whatsappRequestMethod);
    setWhatsappResponseType(whatsappResponseType);
    setWhatsappStatusKey(whatsappStatusKey);
    setWhatsappSuccessValue(whatsappSuccessValue);
    setWhatsappFailedValue(whatsappFailedValue);
    setWhatsappRegards(whatsappRegards);
  };

  const readData = useCallback(() => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.get(`/configurations/whatsapp-api`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILED) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          const {
            whatsappEndpoint,
            whatsappBody,
            whatsappRequestMethod,
            whatsappResponseType,
            whatsappStatusKey,
            whatsappSuccessValue,
            whatsappFailedValue,
            whatsappRegards,
          } = data;
          setWhatsappEndpoint(whatsappEndpoint);
          setWhatsappBody(whatsappBody);
          setWhatsappRequestMethod(whatsappRequestMethod);
          setWhatsappResponseType(whatsappResponseType);
          setWhatsappStatusKey(whatsappStatusKey);
          setWhatsappSuccessValue(whatsappSuccessValue);
          setWhatsappFailedValue(whatsappFailedValue);
          setWhatsappRegards(whatsappRegards);
          setOriginalFormData({
            whatsappEndpoint,
            whatsappBody,
            whatsappRequestMethod,
            whatsappResponseType,
            whatsappStatusKey,
            whatsappSuccessValue,
            whatsappFailedValue,
            whatsappRegards,
          });
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILED);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    const formData = {
      whatsappEndpoint,
      whatsappBody,
      whatsappRequestMethod,
      whatsappResponseType,
      whatsappStatusKey,
      whatsappSuccessValue,
      whatsappFailedValue,
      whatsappRegards,
    };
    API.put(`/configurations/whatsapp-api`, formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setOriginalFormData(formData);
          setTimeout(() => {
            resetResponseData();
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILED);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const addText = (setMethod, value) => {
    setMethod((currentValue) => currentValue + value);
  };

  useEffect(() => {
    readData();
  }, [readData]);

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method="POST" action="#" onSubmit={onSubmit}>
          <Fieldset>
            <Label>API Endpoint</Label>
            <TextInput
              value={whatsappEndpoint}
              onChange={setWhatsappEndpoint}
              placeholder="Please enter api endpoint"
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Request Body</Label>
            <TextInput
              value={whatsappBody}
              onChange={setWhatsappBody}
              placeholder="Please enter request body"
              disabled={isLoading}
            />
            <StyledMicroButton
              disabled={isLoading}
              type="button"
              onClick={() => addText(setWhatsappBody, "{{{NUMBER}}}")}
            >
              Mobile Number
            </StyledMicroButton>
            <StyledMicroButton
              disabled={isLoading}
              type="button"
              onClick={() => addText(setWhatsappBody, "{{{MESSAGE}}}")}
            >
              Message
            </StyledMicroButton>
          </Fieldset>
          <Grid columns={"calc(50% - 10px) calc(50% - 10px)"}>
            <GridItem>
              <Fieldset>
                <Label>Request Method</Label>
                <Dropdown
                  value={whatsappRequestMethod}
                  onChange={setWhatsappRequestMethod}
                  placeholder="Please select request method"
                  disabled={isLoading}
                  options={[
                    {
                      title: "-- SELECT API REQUEST METHOD --",
                      value: "",
                    },
                    {
                      title: "POST",
                      value: "POST",
                    },
                    {
                      title: "GET",
                      value: "GET",
                    },
                  ]}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Response Type</Label>
                <Dropdown
                  value={whatsappResponseType}
                  onChange={setWhatsappResponseType}
                  placeholder="Please select response type"
                  disabled={isLoading}
                  options={[
                    {
                      title: "-- SELECT API RESPONSE TYPE --",
                      value: "",
                    },
                    {
                      title: "JSON",
                      value: "JSON",
                    },
                    {
                      title: "XML",
                      value: "XML",
                    },
                  ]}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid
            columns={
              "calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)"
            }
          >
            <GridItem>
              <Fieldset>
                <Label>Status Key</Label>
                <TextInput
                  value={whatsappStatusKey}
                  onChange={setWhatsappStatusKey}
                  placeholder="Please enter status key"
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Success Value</Label>
                <TextInput
                  value={whatsappSuccessValue}
                  onChange={setWhatsappSuccessValue}
                  placeholder="Please enter success value"
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Failure Value</Label>
                <TextInput
                  value={whatsappFailedValue}
                  onChange={setWhatsappFailedValue}
                  placeholder="Please enter failure value"
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Fieldset>
            <Label>Company Name ( Registered Firm Name )</Label>
            <TextInput
              value={whatsappRegards}
              onChange={setWhatsappRegards}
              placeholder="Please enter company name"
              disabled={isLoading}
            />
          </Fieldset>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <UpdateButton disabled={isLoading} type="submit">
              Update
            </UpdateButton>
            <ResetButton
              disabled={isLoading}
              type="button"
              onClick={resetForm}
            >
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(WhatsappAPIConfiguration);
