import React, { useState, useEffect, useCallback } from "react";
import { withAppHOC } from "../../hoc";
import PageHeading from "../../components/PageHeader/PageHeader";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import NoData from "../../components/NoData/NoData";
import {
  MessageBox,
  Form,
  Fieldset,
  Button,
  ResetButton,
  Dropdown,
  TextInput,
  Label,
} from "../../components/FormElements";
import { TableBuilder } from "../../components/TableElements";
import { StyledFiltersContainer } from "../../components/Styled";
import API from "../../api";
import { API_RESPONSE_TYPES } from "../../constants";
import { formatCurrency } from "../../utils";
import moment from "moment";

const Daybook = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [daybookData, setDaybookData] = useState([]);
  const [users, setUsers] = useState([]);
  const [usersId, setUsersId] = useState("");
  const [profileType, setProfileType] = useState("");
  const [transactionType, setTransactionType] = useState("");
  const [fromDate, setFromDate] = useState(moment().format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
  const [dataProfileType, setDataProfileType] = useState("");

  const resetForm = () => {
    setUsersId("");
    setTransactionType("");
    setFromDate(moment().format("YYYY-MM-DD"));
    setToDate(moment().format("YYYY-MM-DD"));
  };

  const readUsers = () => {
    API.get("/accounts-management/users").then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setUsers(data);
      }
    });
  };

  const readData = useCallback(
    (
      usersId = "",
      profileType = "",
      transactionType = "",
      fromDate = "",
      toDate = ""
    ) => {
      setDataProfileType(profileType);
      setIsLoading(true);
      setResponseStatus("");
      setResponseMessage("");
      API.get(
        `/reports/daybook?usersId=${usersId}&profileType=${profileType}&transactionType=${transactionType}&fromDate=${fromDate}&toDate=${toDate}`
      )
        .then((response) => {
          const { status, message, data } = response.data;
          if (status === API_RESPONSE_TYPES.FAILED) {
            setResponseStatus(status);
            setResponseMessage(message);
          }
          setDaybookData(data);
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILED);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    []
  );

  const filterData = (e) => {
    e.preventDefault();
    readData(usersId, profileType, transactionType, fromDate, toDate);
  };

  useEffect(() => {
    readUsers();
  }, []);

  const userOptions = [{ title: "-- SELECT USER --", value: "" }];
  if (users && users.length > 0) {
    users.forEach(({ id, fullName, mobileNumber }) =>
      userOptions.push({
        title: `${fullName} ( ${mobileNumber} )`,
        value: id,
      })
    );
  }

  return (
    <>
      <PageHeading {...props} canReloadProp={false} />
      <StyledFiltersContainer className="show">
        <Form method="POST" action="#" onSubmit={filterData}>
          <Fieldset>
            <Label>User</Label>
            <Dropdown
              placeholder="User"
              value={usersId}
              onChange={setUsersId}
              options={userOptions}
              disabled={isLoading}
              searchable
            />
          </Fieldset>
          <Fieldset>
            <Label>Profile Type</Label>
            <Dropdown
              placeholder="Profile Type"
              value={profileType}
              onChange={setProfileType}
              options={[
                { title: "-- Select Profile Type --", value: "" },
                { title: "Buyer", value: "Buyer" },
                { title: "Seller", value: "Seller" },
              ]}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Transaction Type</Label>
            <Dropdown
              placeholder="Transaction Type"
              value={transactionType}
              onChange={setTransactionType}
              options={[
                { title: "-- Select Transaction Type --", value: "" },
                { title: "GST", value: "GST" },
                { title: "Non-GST", value: "Non-GST" },
              ]}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>From Date</Label>
            <TextInput
              type="date"
              value={fromDate}
              onChange={setFromDate}
              placeholder="From Date"
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>To Date</Label>
            <TextInput
              type="date"
              value={toDate}
              onChange={setToDate}
              placeholder="To Date"
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Button disabled={isLoading} type="submit">
              Search
            </Button>
            <ResetButton disabled={isLoading} type="button" onClick={resetForm}>
              Clear
            </ResetButton>
          </Fieldset>
        </Form>
      </StyledFiltersContainer>
      <LoadingSpinner
        isLoading={
          responseStatus === "" && daybookData?.length === 0 && isLoading
        }
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILED &&
          !isLoading &&
          daybookData?.length === 0
        }
        message={`No data found`}
      />
      <MessageBox status={responseStatus} message={responseMessage} />
      {daybookData?.length > 0 && (
        <TableBuilder
          isLoading={daybookData?.length !== 0 && isLoading}
          tableHeadings={
            dataProfileType === "Buyer"
              ? [
                  {
                    title: "Date",
                    dataSelector: "reportDate",
                    dataType: "string",
                  },
                  {
                    title: "Opening Balance",
                    dataSelector: "openingBalance",
                    dataType: "string",
                    CellRenderer: (value) => formatCurrency(value),
                  },
                  {
                    title: "Purchase",
                    dataSelector: "purchase",
                    dataType: "string",
                    CellRenderer: (value) => formatCurrency(value),
                  },
                  {
                    title: "Recharge",
                    dataSelector: "recharge",
                    dataType: "string",
                    CellRenderer: (value) => formatCurrency(value),
                  },
                  {
                    title: "Commission",
                    dataSelector: "commission",
                    dataType: "string",
                    CellRenderer: (value) => formatCurrency(value),
                  },
                  {
                    title: "Refund",
                    dataSelector: "refund",
                    dataType: "string",
                    CellRenderer: (value) => formatCurrency(value),
                  },
                  {
                    title: "Closing Balance",
                    dataSelector: "closingBalance",
                    dataType: "string",
                    CellRenderer: (value) => formatCurrency(value),
                  },
                ]
              : [
                  {
                    title: "Date",
                    dataSelector: "reportDate",
                    dataType: "string",
                  },
                  {
                    title: "Opening Balance",
                    dataSelector: "openingBalance",
                    dataType: "string",
                    CellRenderer: (value) => formatCurrency(value),
                  },
                  {
                    title: "Recharge",
                    dataSelector: "recharge",
                    dataType: "string",
                    CellRenderer: (value) => formatCurrency(value),
                  },
                  {
                    title: "Commission",
                    dataSelector: "commission",
                    dataType: "string",
                    CellRenderer: (value) => formatCurrency(value),
                  },
                  {
                    title: "Refund",
                    dataSelector: "refund",
                    dataType: "string",
                    CellRenderer: (value) => formatCurrency(value),
                  },
                  {
                    title: "Withdraw",
                    dataSelector: "withdraw",
                    dataType: "string",
                    CellRenderer: (value) => formatCurrency(value),
                  },
                  {
                    title: "Closing Balance",
                    dataSelector: "closingBalance",
                    dataType: "string",
                    CellRenderer: (value) => formatCurrency(value),
                  },
                ]
          }
          tableData={daybookData}
        />
      )}
    </>
  );
};

export default withAppHOC(Daybook);
