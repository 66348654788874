import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { withAppHOC } from "../../../hoc";
import PageHeading from "../../../components/PageHeader/PageHeader";
import OverlayLoader from "../../../components/OverlayLoader/OverlayLoader";
import { Grid, GridItem } from "../../../components/Grid";
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  Dropdown,
  MessageBox,
  UpdateButton,
  ResetButton,
  NumberInput,
} from "../../../components/FormElements";
import API from "../../../api";
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from "../../../constants";
import { getReturnUrl } from "../../../utils";

const AdminOperatorMarginUpdate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [operators, setOperators] = useState([]);
  const [operatorsId, setOperatorsId] = useState("");
  const [categoriesId, setCategoriesId] = useState("");
  const [minMargin, setMinMargin] = useState("");
  const [maxMargin, setMaxMargin] = useState("");
  const [originalFormData, setOriginalFormData] = useState({});
  const { id } = useParams();

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus("");
    setResponseMessage("");
  };

  const resetForm = () => {
    const { operatorsId, categoriesId, minMargin, maxMargin } =
      originalFormData;
    setOperatorsId(operatorsId);
    setCategoriesId(categoriesId);
    setMinMargin(minMargin);
    setMaxMargin(maxMargin);
  };

  const readOperators = useCallback(() => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.get(
      "/recharge-configurations/operators?pageNumber=1&recordsPerPage=100000"
    )
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILED) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          setOperators(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILED);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const readData = useCallback(() => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.get(`/recharge-configurations/admin-operator-margins/${id}`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILED) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          const { operatorsId, categoriesId, minMargin, maxMargin } = data[0];
          setOperatorsId(operatorsId);
          setCategoriesId(categoriesId);
          setMinMargin(minMargin);
          setMaxMargin(maxMargin);
          setOriginalFormData({
            operatorsId,
            categoriesId,
            minMargin,
            maxMargin,
          });
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILED);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    const formData = {
      operatorsId,
      categoriesId,
      minMargin,
      maxMargin,
    };
    API.put(`/recharge-configurations/admin-operator-margins/${id}`, formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setOriginalFormData(formData);
          setTimeout(() => {
            resetResponseData();
            window.location.href = getReturnUrl(PERMISSION_TYPES.UPDATE);
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILED);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    readData();
    readOperators();
  }, [readData, readOperators]);

  const operatorsOptions = [{ title: "-- SELECT OPERATOR --", value: "" }];
  operators.forEach(({ id, operatorName }) =>
    operatorsOptions.push({
      title: operatorName,
      value: id,
    })
  );

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method="POST" action="#" onSubmit={onSubmit}>
          <Grid
            columns={
              "calc(25% - 15px) calc(25% - 15px) calc(25% - 15px) calc(25% - 15px)"
            }
          >
            <GridItem>
              <Fieldset>
                <Label required>Operator</Label>
                <Dropdown
                  placeholder="Please select operator"
                  value={operatorsId}
                  onChange={setOperatorsId}
                  options={operatorsOptions}
                  disabled={isLoading}
                  searchable
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Category</Label>
                <Dropdown
                  placeholder="Please select category"
                  value={categoriesId}
                  onChange={setCategoriesId}
                  options={[
                    {
                      title: "-- SELECT CATEGORY --",
                      value: "",
                    },
                    {
                      title: "All",
                      value: 1,
                    },
                    {
                      title: "Roffer",
                      value: 2,
                    },
                    {
                      title: "Non Roffer",
                      value: 3,
                    },
                  ]}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Min Margin</Label>
                <NumberInput
                  value={minMargin}
                  onChange={setMinMargin}
                  placeholder="Please enter min margin"
                  maxLength={100}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Max Margin</Label>
                <NumberInput
                  value={maxMargin}
                  onChange={setMaxMargin}
                  placeholder="Please enter max margin"
                  maxLength={100}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <UpdateButton disabled={isLoading} type="submit">
              Update
            </UpdateButton>
            <ResetButton disabled={isLoading} type="button" onClick={resetForm}>
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(AdminOperatorMarginUpdate);
