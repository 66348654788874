import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { withAppHOC } from "../../../hoc";
import PageHeading from "../../../components/PageHeader/PageHeader";
import OverlayLoader from "../../../components/OverlayLoader/OverlayLoader";
import { Grid, GridItem } from "../../../components/Grid";
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  NumberInput,
  Dropdown,
  MessageBox,
  UpdateButton,
  ResetButton,
} from "../../../components/FormElements";
import API from "../../../api";
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from "../../../constants";
import { camelCaseToString, getReturnUrl } from "../../../utils";

const StaffAccountsUpdate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [roles, setRoles] = useState([]);
  const [accountsRolesId, setAccountsRolesId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [accountsStatus, setAccountsStatus] = useState("");
  const [originalFormData, setOriginalFormData] = useState({});
  const { id } = useParams();

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus("");
    setResponseMessage("");
  };

  const resetForm = () => {
    const {
      accountsRolesId,
      firstName,
      lastName,
      mobileNumber,
      accountsStatus,
    } = originalFormData;
    setAccountsRolesId(accountsRolesId);
    setFirstName(firstName);
    setLastName(lastName);
    setMobileNumber(mobileNumber);
    setAccountsStatus(accountsStatus);
  };

  const readRoles = useCallback(() => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.get(
      `/accounts-management/roles-and-permissions?pageNumber=1&recordsPerPage=1000`
    )
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILED) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          setRoles(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILED);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const readData = useCallback(() => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.get(`/accounts-management/accounts/${id}`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILED) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          const {
            accountsRolesId,
            firstName,
            lastName,
            mobileNumber,
            accountsStatus,
          } = data[0];
          setAccountsRolesId(accountsRolesId);
          setFirstName(firstName);
          setLastName(lastName);
          setMobileNumber(mobileNumber);
          setAccountsStatus(accountsStatus);
          setOriginalFormData({
            accountsRolesId,
            firstName,
            lastName,
            mobileNumber,
            accountsStatus,
          });
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILED);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    const formData = {
      accountsRolesId,
      firstName,
      lastName,
      accountsStatus,
    };
    API.put(`/accounts-management/accounts/${id}`, formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setOriginalFormData(formData);
          setTimeout(() => {
            resetResponseData();
            window.location.href = getReturnUrl(PERMISSION_TYPES.UPDATE);
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILED);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    readRoles();
    readData();
  }, [readRoles, readData]);

  const rolesOptions = [{ title: "-- SELECT ROLE --", value: "" }];
  roles.length > 0 &&
    roles.forEach(({ id, roleName }) =>
      rolesOptions.push({
        title: camelCaseToString(roleName),
        value: id,
      })
    );

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method="POST" action="#" onSubmit={onSubmit}>
          <Fieldset>
            <Label required>Role</Label>
            <Dropdown
              placeholder="Please select accounts role"
              value={accountsRolesId}
              onChange={setAccountsRolesId}
              options={rolesOptions}
              disabled={isLoading}
            />
          </Fieldset>
          <Grid columns={"calc(50% - 10px) calc(50% - 10px)"}>
            <GridItem>
              <Fieldset>
                <Label required>First Name</Label>
                <TextInput
                  value={firstName}
                  onChange={setFirstName}
                  placeholder="Please enter first name"
                  maxLength={100}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Last Name</Label>
                <TextInput
                  value={lastName}
                  onChange={setLastName}
                  placeholder="Please enter last name"
                  maxLength={100}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Fieldset>
            <Label required>Mobile Number</Label>
            <NumberInput
              value={mobileNumber}
              onChange={setMobileNumber}
              placeholder="Please enter mobile number"
              maxLength={10}
              disabled={true}
              pattern={`[0-9]*`}
            />
          </Fieldset>
          <Fieldset>
            <Label required>Account Status</Label>
            <Dropdown
              placeholder="Please select aadhaar verification status"
              value={accountsStatus}
              onChange={setAccountsStatus}
              options={[
                {
                  title: "-- SELECT ACCOUNTS STATUS --",
                  value: "",
                },
                {
                  title: "Active",
                  value: "Active",
                },
                {
                  title: "Inactive",
                  value: "Inactive",
                },
              ]}
              disabled={isLoading}
            />
          </Fieldset>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <UpdateButton disabled={isLoading} type="submit">
              Update
            </UpdateButton>
            <ResetButton
              disabled={isLoading}
              type="button"
              onClick={resetForm}
            >
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(StaffAccountsUpdate);
