import isEmpty from "lodash/isEmpty";
import moment from "moment";
import html2pdf from "html2pdf.js";
import { ROUTE_TYPES, PERMISSION_TYPES } from "../constants";
import { ROUTES } from "../constants/routes.config";

const getPlatform = () => {
  return "Web";
};

const getDeviceType = () => {
  return navigator?.userAgentData?.mobile ? "Mobile" : "Desktop";
};

const getOperatingSystem = () => {
  return navigator?.userAgentData?.platform ?? "Unknown";
};

const shouldRedirect = (dataLength = 0, pageNumber = 1) => {
  return dataLength === 0 && parseInt(pageNumber) !== 1 ? true : false;
};

const redirectToPageOne = () => {
  window.location.href = window.location.href.split("?")?.[0];
};

const authData = {
  get: () => {
    return {
      isLoggedIn: window.localStorage.getItem("isLoggedIn") === "true" || false,
      accountType: window.localStorage.getItem("accountType") || null,
      authId: window.localStorage.getItem("authId") || null,
      authToken: window.localStorage.getItem("authToken") || null,
      accountsPermissions:
        JSON.parse(window.localStorage.getItem("accountsPermissions")) || null,
      accountData:
        JSON.parse(window.localStorage.getItem("accountData")) || null,
    };
  },
  set: (key, value) => window.localStorage.setItem(key, value),
  clear: () => window.localStorage.clear(),
};

const checkRoutePermission = ({
  title,
  routeType,
  moduleName,
  permissionRequired,
}) => {
  const { ALLOW_IF_LOGGEDIN, ALLOW_IF_NOT_LOGGEDIN } = ROUTE_TYPES;
  const { isLoggedIn, accountsPermissions } = authData.get();
  const routeTypeCheck =
    isEmpty(routeType) || (routeType === ALLOW_IF_LOGGEDIN && isLoggedIn);
  const modulePermissionCheck =
    isEmpty(moduleName) ||
    (routeType === ALLOW_IF_NOT_LOGGEDIN && isEmpty(moduleName)) ||
    (routeType === ALLOW_IF_LOGGEDIN &&
      !isEmpty(moduleName) &&
      !isEmpty(permissionRequired) &&
      (!isEmpty(accountsPermissions[moduleName])
        ? accountsPermissions[moduleName].includes(permissionRequired)
        : false));

  return routeTypeCheck && modulePermissionCheck;
};

const generateRoutes = () => {
  const routesList = [];

  (function loopRoutes(routes) {
    !isEmpty(routes) &&
      routes.forEach((route) => {
        route.isDummy !== true && route.path && routesList.push(route);
        if (!isEmpty(route.subRoutes)) {
          loopRoutes(route.subRoutes);
        }
      });
  })(ROUTES);

  return routesList;
};

const validators = {
  isNumber: (event) => {
    event = event ? event : window.event;
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  },
};

const getBase64 = (file, cb) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    cb(reader.result);
  };
};

const camelCaseToString = (text) => {
  const result = text?.replace(/([A-Z])/g, " $1");
  return result?.charAt(0)?.toUpperCase() + result?.slice(1) || "";
};

const getSearchParams = (key) => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(key);
};

const getReturnUrl = (type) => {
  let returnUrl = "/";
  const href = window.location.href;

  if (type === PERMISSION_TYPES.CREATE) {
    const exp = href.split("/create");
    returnUrl = exp[0];
  } else if (type === PERMISSION_TYPES.UPDATE) {
    const exp = href.split("/update");
    returnUrl = exp[0];
  } else if (type === "status") {
    const exp = href.split("/status");
    returnUrl = exp[0];
  }

  return returnUrl;
};

const formatCurrency = (value) => {
  const rupeeIndian = Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });

  return rupeeIndian.format(value);
};

const getPreviousDay = (date = new Date()) => {
  const previous = new Date(date.getTime());
  previous.setDate(date.getDate() - 1);

  return moment(previous).format("YYYY-MM-DD");
};

const generatePDF = (fileName) => {
  try {
    const pdfOptions = {
      html2canvas: {
        dpi: 192,
        scale: 4,
        letterRendering: true,
        useCORS: true,
      },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      pagebreak: {
        mode: ["avoid-all", "css"],
      },
    };
    const element = document.getElementById("invoice-container");
    if (element !== null) {
      const clonedElement = element.cloneNode(true);
      clonedElement.setAttribute("style", "display: block");

      html2pdf()
        .from(clonedElement)
        .set(pdfOptions)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          const totalPages = pdf.internal.getNumberOfPages();
          for (let i = 1; i <= totalPages; i += 1) {
            pdf.setPage(i);
            pdf.setFontSize(10);
            pdf.setTextColor(50);
          }
        })
        .save(fileName);
    }
  } catch (error) {
    console.error("Error Generating PDF", error);
  }
};

const generateSinglePDF = async (index) => {
  try {
    const pdfOptions = {
      html2canvas: {
        dpi: 192,
        scale: 4,
        letterRendering: true,
        useCORS: true,
      },
      jsPDF: {
        unit: "mm",
        format: "a4",
        orientation: "portrait",
      },
      pagebreak: {
        mode: ["avoid-all", "css"],
      },
    };
    const element = document.getElementById(`invoice-container-${index}`);
    if (element !== null) {
      const clonedElement = element.cloneNode(true);
      clonedElement.setAttribute("style", "display: block");

      return html2pdf()
        .from(clonedElement)
        .set(pdfOptions)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          const totalPages = pdf.internal.getNumberOfPages();
          for (let i = 1; i <= totalPages; i += 1) {
            pdf.setPage(i);
            pdf.setFontSize(10);
            pdf.setTextColor(50);
          }
        })
        .output();
    }
  } catch (error) {
    console.error("Error Generating PDF", error);
  }
};

const generateExistingInvoicePDF = (fileName, id) => {
  try {
    const pdfOptions = {
      html2canvas: {
        dpi: 192,
        scale: 4,
        letterRendering: true,
        useCORS: true,
      },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      pagebreak: {
        mode: ["avoid-all", "css"],
      },
    };
    const element = document.getElementById(`existing-invoice-container-${id}`);
    if (element !== null) {
      const clonedElement = element.cloneNode(true);
      clonedElement.setAttribute("style", "display: block");

      html2pdf()
        .from(clonedElement)
        .set(pdfOptions)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          const totalPages = pdf.internal.getNumberOfPages();
          for (let i = 1; i <= totalPages; i += 1) {
            pdf.setPage(i);
            pdf.setFontSize(10);
            pdf.setTextColor(50);
          }
        })
        .save(fileName);
    }
  } catch (error) {
    console.error("Error Generating PDF", error);
  }
};

function getAmountInWords(amount) {
  const words = [
    "",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
    "Ten",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];

  const tens = [
    "",
    "",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];

  function convertTwoDigitNumber(num) {
    if (num < 20) {
      return words[num];
    } else {
      return tens[Math.floor(num / 10)] + " " + words[num % 10];
    }
  }

  function convertThreeDigitNumber(num) {
    if (num >= 100) {
      return (
        words[Math.floor(num / 100)] +
        " Hundred " +
        convertTwoDigitNumber(num % 100)
      );
    } else {
      return convertTwoDigitNumber(num);
    }
  }

  if (isNaN(amount) || amount < 0 || amount >= 100000000) {
    return "Invalid input";
  }

  if (amount === 0) {
    return "Zero Rupees";
  }

  const crore = Math.floor(amount / 10000000);
  const lakh = Math.floor((amount % 10000000) / 100000);
  const thousand = Math.floor((amount % 100000) / 1000);
  const remainder = Math.floor(amount % 1000);

  let result = "";

  if (crore > 0) {
    result += convertThreeDigitNumber(crore) + " Crore ";
  }

  if (lakh > 0) {
    result += convertThreeDigitNumber(lakh) + " Lakh ";
  }

  if (thousand > 0) {
    result += convertThreeDigitNumber(thousand) + " Thousand ";
  }

  if (remainder > 0) {
    result += convertThreeDigitNumber(remainder);
  }
  result += " Rupees";

  // Handling decimal part
  const decimalPart = Math.round((amount % 1) * 100);
  if (decimalPart > 0) {
    result += " and " + convertTwoDigitNumber(decimalPart) + " Paise";
  }

  return result.trim();
}

export {
  getPlatform,
  getDeviceType,
  getOperatingSystem,
  shouldRedirect,
  redirectToPageOne,
  authData,
  checkRoutePermission,
  generateRoutes,
  validators,
  getBase64,
  camelCaseToString,
  getSearchParams,
  getReturnUrl,
  getAmountInWords,
  formatCurrency,
  getPreviousDay,
  generatePDF,
  generateSinglePDF,
  generateExistingInvoicePDF,
};
