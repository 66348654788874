import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { withAppHOC } from "../../../hoc";
import PageHeading from "../../../components/PageHeader/PageHeader";
import OverlayLoader from "../../../components/OverlayLoader/OverlayLoader";
import { Grid, GridItem } from "../../../components/Grid";
import Modal from "../../../components/Modal";
import { Image } from "../../../components/Image";
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  Dropdown,
  FileInput,
  MessageBox,
  UpdateButton,
  ResetButton,
} from "../../../components/FormElements";
import API from "../../../api";
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from "../../../constants";
import { getBase64, getReturnUrl } from "../../../utils";

const StyledViewIconButton = styled.span`
  font-size: 14px;
  width: calc(100% - 22px);
  height: auto;
  border: 1px solid #d0eeff;
  display: block;
  margin: 20px 0px 0px;
  background-color: #edf8ff;
  padding: 15px 10px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.5s;
  position: relative;
  &:hover {
    background-color: #d0eeff;
  }
`;

const BankAccountsUpdate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [showExistingImage, setShowExistingImage] = useState(false);
  const [bankName, setBankName] = useState("");
  const [bankLogo, setBankLogo] = useState([]);
  const [bankLogoBase64, setBankLogoBase64] = useState("");
  const [payeeName, setPayeeName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [bankStatus, setBankStatus] = useState("Active");
  const [originalFormData, setOriginalFormData] = useState({});
  const { id } = useParams();

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus("");
    setResponseMessage("");
  };

  const resetForm = () => {
    const {
      bankName,
      bankLogo,
      payeeName,
      accountNumber,
      ifscCode,
      bankStatus,
    } = originalFormData;
    setBankName(bankName);
    setBankLogo([]);
    setBankLogoBase64(bankLogo);
    setPayeeName(payeeName);
    setAccountNumber(accountNumber);
    setIfscCode(ifscCode);
    setBankStatus(bankStatus);
  };

  const readData = useCallback(() => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.get(`/configurations/bank-accounts/${id}`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILED) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          const {
            bankName,
            bankLogo,
            payeeName,
            accountNumber,
            ifscCode,
            bankStatus,
          } = data[0];
          setBankName(bankName);
          setBankLogoBase64(bankLogo);
          setPayeeName(payeeName);
          setAccountNumber(accountNumber);
          setIfscCode(ifscCode);
          setBankStatus(bankStatus);
          setOriginalFormData({
            bankName,
            bankLogo,
            payeeName,
            accountNumber,
            ifscCode,
            bankStatus,
          });
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILED);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    const formData = {
      bankName,
      bankLogo: bankLogoBase64,
      payeeName,
      accountNumber,
      ifscCode,
      bankStatus,
    };
    API.put(`/configurations/bank-accounts/${id}`, formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setOriginalFormData(formData);
          setTimeout(() => {
            resetResponseData();
            window.location.href = getReturnUrl(PERMISSION_TYPES.UPDATE);
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILED);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    readData();
  }, [readData]);

  useEffect(() => {
    if (bankLogo?.length > 0) {
      getBase64(bankLogo[0], setBankLogoBase64);
    }
  }, [bankLogo]);

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method="POST" action="#" onSubmit={onSubmit}>
          <Grid
            columns={
              "calc(25% - 15px) calc(25% - 15px) calc(25% - 15px) calc(25% - 15px)"
            }
          >
            <GridItem>
              <Fieldset>
                <Label required>Bank Name</Label>
                <TextInput
                  value={bankName}
                  onChange={setBankName}
                  placeholder="Please enter bank name"
                  maxLength={100}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Payee Name</Label>
                <TextInput
                  value={payeeName}
                  onChange={setPayeeName}
                  placeholder="Please enter payee name"
                  maxLength={100}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Account Number</Label>
                <TextInput
                  value={accountNumber}
                  onChange={setAccountNumber}
                  placeholder="Please enter account number"
                  maxLength={200}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>IFSC Code</Label>
                <TextInput
                  value={ifscCode}
                  onChange={setIfscCode}
                  placeholder="Please enter ifsc code"
                  maxLength={100}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Fieldset>
            <Label>Bank Logo</Label>
            <FileInput
              files={bankLogo}
              onChange={setBankLogo}
              accept="image/*"
              disabled={isLoading}
            />
            {originalFormData?.bankLogo && (
              <StyledViewIconButton onClick={() => setShowExistingImage(true)}>
                Click here to view existing file
              </StyledViewIconButton>
            )}
          </Fieldset>
          <Fieldset>
            <Label required>Bank Status</Label>
            <Dropdown
              placeholder="Please select bank status"
              value={bankStatus}
              onChange={setBankStatus}
              options={[
                {
                  title: "-- SELECT BANK STATUS --",
                  value: "",
                },
                {
                  title: "Active",
                  value: "Active",
                },
                {
                  title: "Inactive",
                  value: "Inactive",
                },
              ]}
              disabled={isLoading}
            />
          </Fieldset>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <UpdateButton disabled={isLoading} type="submit">
              Update
            </UpdateButton>
            <ResetButton disabled={isLoading} type="button" onClick={resetForm}>
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
        <Modal
          modalStatus={showExistingImage}
          setModalStatus={setShowExistingImage}
          hideCloseButton
        >
          <Image
            width={150}
            source={originalFormData?.bankLogo}
            alt={bankName}
          />
        </Modal>
      </FormContainer>
    </>
  );
};

export default withAppHOC(BankAccountsUpdate);
