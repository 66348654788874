import React, { useState } from "react";
import styled from "styled-components";
import Logo from "../components/Logo/Logo";
import Header from "../components/Header/Header";
import Navigation from "../components/Navigation/Navigation";
import Footer from "../components/Footer/Footer";
import API from "../api";
import { authData } from "../utils";

const StyledMain = styled.main`
  width: 95%;
  max-width: 1800px;
  height: auto;
  margin: 0 auto;
  padding: 0px;
`;

const StyledAuthHeader = styled.section`
  width: 100%;
  height: auto;
  margin-bottom: 30px;
  @media (max-width: 1000px) {
    margin-bottom: 10px;
  }
`;

const StyledAuthFormSection = styled.section`
  width: 450px;
  height: auto;
  margin: 0px auto;
  padding: 0px;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const withAuthHOC = (WrappedComponent) => {
  return (props) => {
    const { appName, appLogo } = props.appData;

    return (
      <>
        <StyledAuthFormSection>
          <StyledAuthHeader>
            <Logo src={appLogo} alt={appName} type="auth" />
          </StyledAuthHeader>
          <WrappedComponent {...props} />
        </StyledAuthFormSection>
      </>
    );
  };
};

const withAppHOC = (WrappedComponent) => {
  return (props) => {
    const { appName, appLogo } = props.appData;
    const [showNav, setShowNav] = useState(false);

    const onClickLogout = (e) => {
      e.preventDefault();
      const { authToken } = authData.get();
      API.delete(`/authentication/sessions/?sessionToken=${authToken}`);
      authData.clear();
      window.location.href = "/login";
    };

    const onClickNavigationIcon = () => {
      setShowNav((showNav) => !showNav);
    };

    return (
      <>
        <Header
          onClickLogout={onClickLogout}
          appLogo={appLogo}
          appName={appName}
          showNav={showNav}
          setShowNav={onClickNavigationIcon}
        />
        <Navigation showNav={showNav} />
        <StyledMain>
          <WrappedComponent {...props} />
        </StyledMain>
        <Footer appName={appName} />
      </>
    );
  };
};

export { withAuthHOC, withAppHOC };
