import React, { useState } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { withAuthHOC } from '../../hoc';
import {
  Form,
  Fieldset,
  Label,
  NumberInput,
  PasswordInput,
  OTPInput,
  Button,
  MessageBox,
} from '../../components/FormElements';
import API from '../../api';
import OverlayLoader from '../../components/OverlayLoader/OverlayLoader';
import { API_RESPONSE_TYPES } from '../../constants';

const StyledAuthArea = styled.section`
  width: 100%;
  height: auto;
  background-color: #ffffff;
  border-radius: 30px;
  padding: 0px;
  margin: 0px;
  box-shadow: 0px 0px 50px -10px #b9b9b9;
  position: relative;
  @media (max-width: 900px) {
    width: calc(100% - 40px);
    min-width: auto;
    height: auto;
    padding: 0px;
    margin: 0 auto;
    position: relative;
    top: 0px;
    left: 0px;
    transform: none;
  }
  @media (max-width: 1000px) {
    width: 92%;
    min-width: auto;
    height: auto;
    padding: 0px;
    margin: 0 auto;
    position: relative;
    top: 0;
    left: 0;
    transform: none;
  }
`;

const StyledBody = styled.section`
  width: calc(100% - 60px);
  height: auto;
  margin: 0px;
  padding: 30px;
  @media (max-width: 1000px) {
    width: calc(100% - 40px);
    padding: 20px;
  }
`;

const StyledAuthHeading = styled.h1`
  font-size: 20px;
  font-weight: normal;
  text-align: center;
  margin: 0px 0px 30px 0px;
  padding: 0px;
`;

const StyleLink = styled(NavLink)`
  font-size: 14px;
  color: #0095ff;
  position: absolute;
  top: 11.5px;
  right: 0;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const StyledSpanAsButton = styled.span`
  font-size: 14px;
  color: #0095ff;
  position: absolute;
  top: 15.5px;
  right: 0;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const StyledPara = styled.p`
  font-size: 14px;
  text-align: center;
  line-height: 30px;
`;

const StyledSpan = styled.span`
  color: forestgreen;
`;

const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [reponseMessage, setResponseMessage] = useState('');
  const [routeStage, setRouteStage] = useState('ForgotPassword');
  const [mobileNumber, setMobileNumber] = useState('');
  const [requestToken, setRequestToken] = useState('');
  const [oneTimePassword, setOneTimePassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const resetForm = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
    setRouteStage('ForgotPassword');
    setRequestToken('');
    setOneTimePassword('');
    setNewPassword('');
    setConfirmNewPassword('');
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    if (routeStage === 'ForgotPassword') {
      API.post('/authentication/forgot-password', { mobileNumber })
        .then((response) => {
          const { status, message, token } = response.data;
          setResponseStatus(status);
          setResponseMessage(message);
          setRequestToken(token);
          if (status === API_RESPONSE_TYPES.SUCCESS) {
            setRouteStage('OTPVerification');
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILED);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (routeStage === 'OTPVerification') {
      const formData = {
        requestType: 'Reset Password',
        requestToken,
        mobileNumber,
        oneTimePassword: oneTimePassword
          ? oneTimePassword.join('')
          : oneTimePassword,
        newPassword,
        confirmNewPassword,
      };
      API.post('/authentication/verify-otp', formData)
        .then((response) => {
          const { status, message, token } = response.data;
          setResponseStatus(status);
          setResponseMessage(message);
          setRequestToken(token);
          if (status === API_RESPONSE_TYPES.SUCCESS) {
            setMobileNumber('');
            setRouteStage('ForgotPassword');
            setTimeout(() => {
              resetForm();
              window.location.href = '/login';
            }, 5000);
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILED);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const onClickResendOTP = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    const formData = {
      requestType: 'Reset Password',
      mobileNumber,
      requestToken,
    };
    API.post('/authentication/resend-otp', formData)
      .then((response) => {
        const { status, message, token } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        setRequestToken(token);
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILED);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <StyledAuthArea>
        <StyledBody>
          <StyledAuthHeading>
            {routeStage === 'ForgotPassword'
              ? `Forgot Password ?`
              : `Verify & Reset Password`}
          </StyledAuthHeading>
          <Form action='#' method='POST' onSubmit={onSubmit}>
            {routeStage === 'ForgotPassword' && (
              <Fieldset>
                <Label required>Mobile Number</Label>
                <NumberInput
                  placeholder='Please enter your mobile number'
                  value={mobileNumber}
                  onChange={setMobileNumber}
                  maxLength={10}
                  autoComplete='off'
                  disabled={isLoading}
                  pattern={`[0-9]*`}
                />
              </Fieldset>
            )}
            {routeStage === 'OTPVerification' && (
              <>
                <StyledPara>
                  Please enter your OTP sent to{' '}
                  <StyledSpan>{mobileNumber}</StyledSpan>
                </StyledPara>
                <Fieldset>
                  <OTPInput
                    values={oneTimePassword}
                    onChange={setOneTimePassword}
                    length={6}
                    alignInputs='center'
                    disabled={isLoading}
                  />
                </Fieldset>
                <Fieldset>
                  <Label required>Password</Label>
                  <PasswordInput
                    placeholder='Please enter your password'
                    value={newPassword}
                    onChange={setNewPassword}
                    autoComplete='off'
                    disabled={isLoading}
                  />
                </Fieldset>
                <Fieldset>
                  <Label required>Confirm Password</Label>
                  <PasswordInput
                    placeholder='Please re-enter your password'
                    value={confirmNewPassword}
                    onChange={setConfirmNewPassword}
                    autoComplete='off'
                    disabled={isLoading}
                  />
                </Fieldset>
              </>
            )}
            {responseStatus && (
              <Fieldset>
                <MessageBox status={responseStatus} message={reponseMessage} />
              </Fieldset>
            )}
            <Fieldset>
              <Button disabled={isLoading}>
                {routeStage === 'ForgotPassword'
                  ? `Send OTP`
                  : `Verify & Proceed`}
              </Button>
              {routeStage === 'OTPVerification' && (
                <StyledSpanAsButton onClick={onClickResendOTP}>
                  Resend OTP
                </StyledSpanAsButton>
              )}
              {routeStage === 'ForgotPassword' && (
                <StyleLink to='/login' title='Login'>
                  &larr; Back to Login
                </StyleLink>
              )}
            </Fieldset>
          </Form>
        </StyledBody>
        <OverlayLoader showLoader={isLoading} />
      </StyledAuthArea>
    </>
  );
};

export default withAuthHOC(ForgotPassword);
