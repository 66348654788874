import React, { useState, useEffect, useCallback } from "react";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { withAppHOC } from "../../../hoc";
import PageHeading from "../../../components/PageHeader/PageHeader";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import NoData from "../../../components/NoData/NoData";
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  ResetButton,
  MessageBox,
  Dropdown,
  CustomButton,
  Button,
  CreateButton,
} from "../../../components/FormElements";
import { TableBuilder } from "../../../components/TableElements";
import Modal from "../../../components/Modal";
import OverlayLoader from "../../../components/OverlayLoader/OverlayLoader";
import {
  StyledSpan,
  StyledModalContainer,
  StyledFiltersContainer,
} from "../../../components/Styled";
import StatusHighliter from "../../../components/StatusHighliter/StatusHighliter";
import Pagination from "../../../components/Pagination/Pagination";
import API from "../../../api";
import { API_RESPONSE_TYPES } from "../../../constants";
import {
  getSearchParams,
  shouldRedirect,
  redirectToPageOne,
} from "../../../utils";
import FiltersButton from "../../../components/FiltersButton/FiltersButton";
import { Image } from "../../../components/Image";

const UsersKYCList = (props) => {
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams("pageNumber") || 1,
    recordsPerPage: getSearchParams("recordsPerPage") || 30,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [users, setUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [fMobileNumber, setFmobileNumber] = useState("");
  const [fApprovalStatus, setFapprovalStatus] = useState("Submitted");
  const [approvalStatus, setApprovalStatus] = useState("");
  const [remark, setRemark] = useState("");
  const [modalData, setModalData] = useState(null);

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus("");
    setResponseMessage("");
  };

  const resetFilters = () => {
    setFmobileNumber("");
    setFapprovalStatus("");
    readData();
  };

  const readData = useCallback(
    (fMobileNumber = "", fApprovalStatus = "") => {
      setIsLoading(true);
      setResponseStatus("");
      setResponseMessage("");
      setUsers([]);
      API.get(
        `/accounts-management/users/users-kyc?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&mobileNumber=${fMobileNumber}&approvalStatus=${fApprovalStatus}`
      )
        .then((response) => {
          const { status, message, data, pageInfo } = response.data;
          if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
            redirectToPageOne();
          } else {
            if (status === API_RESPONSE_TYPES.FAILED) {
              setResponseStatus(status);
              setResponseMessage(message);
            } else {
              setTotalRecords(pageInfo.totalRecords);
            }
            setUsers(data);
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILED);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [paginationData]
  );

  const reloadData = () => {
    readData(fMobileNumber, fApprovalStatus);
  };

  const updateData = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.put(`/accounts-management/users/users-kyc/${modalData.id}`, {
      approvalStatus,
      remark,
    })
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          reloadData();
          showModal(false);
          setModalData(null);
          setRemark("");
          setApprovalStatus("");
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILED);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const filterData = (e) => {
    e.preventDefault();
    setIsFiltering(true);
  };

  useEffect(() => {
    if (isFiltering) {
      setPaginationData((paginationData) => ({
        pageNumber: 1,
        recordsPerPage: paginationData.recordsPerPage,
      }));
      setIsFiltering((isFiltering) => !isFiltering);
    }
  }, [isFiltering]);

  useEffect(() => {
    if (!isFiltering) {
      readData(fMobileNumber, fApprovalStatus);
    }
  }, [paginationData, readData]);

  const [showFilters, setShowFilters] = useState(false);
  const onClickShowFilters = () => {
    setShowFilters((showFilters) => !showFilters);
  };

  return (
    <>
      <PageHeading {...props} reloadData={reloadData} />
      <FiltersButton onClick={onClickShowFilters} value={showFilters} />
      <StyledFiltersContainer className={showFilters ? "show" : ""}>
        <Form method="POST" action="#" onSubmit={filterData}>
          <Fieldset>
            <Label>Mobile Number</Label>
            <TextInput
              value={fMobileNumber}
              onChange={setFmobileNumber}
              placeholder="Mobile Number"
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Approval Status</Label>
            <Dropdown
              placeholder="Approval Status"
              value={fApprovalStatus}
              onChange={setFapprovalStatus}
              options={[
                { title: "-- SELECT APPROVAL STATUS --", value: "" },
                { title: "Pending", value: "Pending" },
                { title: "Submitted", value: "Submitted" },
                { title: "Approved", value: "Approved" },
                { title: "Rejected", value: "Rejected" },
              ]}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Button disabled={isLoading} type="submit">
              Filter
            </Button>
            <ResetButton
              disabled={isLoading}
              type="button"
              onClick={resetFilters}
            >
              Clear
            </ResetButton>
          </Fieldset>
        </Form>
      </StyledFiltersContainer>
      <LoadingSpinner
        isLoading={
          responseStatus === "" &&
          !showModal &&
          users?.length === 0 &&
          isLoading
        }
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILED &&
          !isLoading &&
          users?.length === 0
        }
        message={`No records found`}
      />
      {!showModal && responseStatus === API_RESPONSE_TYPES.FAILED && (
        <MessageBox status={responseStatus} message={responseMessage} />
      )}
      {users?.length > 0 && (
        <>
          <TableBuilder
            isLoading={!showModal && users?.length !== 0 && isLoading}
            tableHeadings={[
              {
                title: "",
                dataSelector: "id",
                align: "center",
                sticky: true,
                canSort: false,
                width: "100px",
                CellRenderer: (value, item) => (
                  <>
                    <CustomButton
                      icon={faEye}
                      onClick={() => {
                        setShowModal(true);
                        setModalData(item);
                      }}
                      style={{ color: "gray" }}
                    />
                  </>
                ),
              },
              {
                title: "User",
                dataSelector: "fullName",
                dataType: "string",
                CellRenderer: (value, item) => (
                  <>
                    {value}
                    <StyledSpan>{item.mobileNumber}</StyledSpan>
                  </>
                ),
              },
              {
                title: "PAN Details",
                dataSelector: "panNumber",
                dataType: "string",
                CellRenderer: (value, item) => (
                  <>
                    {value || "N/A"}
                    <StyledSpan>{item.panName || "N/A"}</StyledSpan>
                  </>
                ),
              },
              {
                title: "Aadhaar Details",
                dataSelector: "aadhaarNumber",
                dataType: "string",
                CellRenderer: (value, item) => (
                  <>
                    {value || "N/A"}
                    <StyledSpan>{item.aadhaarName || "N/A"}</StyledSpan>
                  </>
                ),
              },
              {
                title: "GST Details",
                dataSelector: "gstNumber",
                dataType: "string",
                CellRenderer: (value, item) => (
                  <>
                    {value || "N/A"}
                    <StyledSpan>{item.gstName || "N/A"}</StyledSpan>
                  </>
                ),
              },
              {
                title: "Status",
                dataSelector: "kycStatus",
                dataType: "string",
                align: "center",
                CellRenderer: (value) =>
                  value && (
                    <StatusHighliter
                      className={
                        value === "Approved"
                          ? "green"
                          : value === "Rejected"
                          ? "red"
                          : value === "Pending"
                          ? "yellow"
                          : "blue"
                      }
                    >
                      {value}
                    </StatusHighliter>
                  ),
              },
              {
                title: "Remark",
                dataSelector: "remark",
                dataType: "string",
                CellRenderer: (value) => value || "N/A",
              },
            ]}
            tableData={users}
          />
          <Pagination
            totalRecords={totalRecords}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
          />
          <Modal
            modalStatus={showModal}
            setModalStatus={() => {
              resetResponseData();
              setShowModal(false);
              setModalData(null);
              setRemark("");
              setApprovalStatus("");
            }}
            hideCloseButton
          >
            <StyledModalContainer width={"1200px"}>
              <h3>Users KYC Details</h3>
              <FormContainer
                style={{ overflowY: "scroll", maxHeight: "500px" }}
              >
                <Form method="POST" action="#" onSubmit={updateData}>
                  {modalData && (
                    <>
                      <Fieldset>
                        <Label>
                          PAN Details : {modalData?.panName},{" "}
                          {modalData?.panNumber}
                        </Label>
                      </Fieldset>
                      <Image source={modalData?.panFile} />
                      <Fieldset>
                        <Label>
                          Aadhaar Details : {modalData?.aadhaarName},{" "}
                          {modalData?.aadhaarNumber}
                        </Label>
                      </Fieldset>
                      <Image source={modalData?.aadhaarFile} />
                      <Fieldset>
                        <Label>
                          GST Details : {modalData?.gstName},{" "}
                          {modalData?.gstNumber}
                        </Label>
                      </Fieldset>
                      <Image source={modalData?.gstFile} />
                    </>
                  )}
                  {modalData?.kycStatus === "Submitted" && (
                    <>
                      <Fieldset>
                        <Label>Approval Status</Label>
                        <Dropdown
                          placeholder="Approval Status"
                          value={approvalStatus}
                          onChange={setApprovalStatus}
                          options={[
                            {
                              title: "-- SELECT APPROVAL STATUS --",
                              value: "",
                            },
                            { title: "Approved", value: "Approved" },
                            { title: "Rejected", value: "Rejected" },
                          ]}
                          disabled={isLoading}
                        />
                      </Fieldset>
                      <Fieldset>
                        <Label>Remark</Label>
                        <TextInput
                          value={remark}
                          onChange={setRemark}
                          placeholder="Please enter remark"
                          disabled={showModal && isLoading}
                        />
                      </Fieldset>
                      {showModal && responseStatus && (
                        <Fieldset>
                          <MessageBox
                            status={showModal && responseStatus}
                            message={showModal && responseMessage}
                          />
                        </Fieldset>
                      )}
                      <Fieldset>
                        <CreateButton
                          disabled={showModal && isLoading}
                          type="submit"
                        >
                          Submit
                        </CreateButton>
                      </Fieldset>
                    </>
                  )}
                </Form>
                <OverlayLoader showLoader={showModal && isLoading} />
              </FormContainer>
            </StyledModalContainer>
          </Modal>
        </>
      )}
    </>
  );
};

export default withAppHOC(UsersKYCList);
