import React, { useState } from "react";
import { withAppHOC } from "../../../hoc";
import PageHeading from "../../../components/PageHeader/PageHeader";
import OverlayLoader from "../../../components/OverlayLoader/OverlayLoader";
import { Grid, GridItem } from "../../../components/Grid";
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  NumberInput,
  Dropdown,
  MessageBox,
  CreateButton,
  ResetButton,
  PasswordInput,
} from "../../../components/FormElements";
import API from "../../../api";
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from "../../../constants";
import { getReturnUrl } from "../../../utils";

const UsersCreate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [fullName, setFullname] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileNumberVerificationStatus, setMobileNumberVerificationStatus] =
    useState("Verified");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [topupStatus, setTopupStatus] = useState("Active");
  const [withdrawStatus, setWithdrawStatus] = useState("Active");
  const [userStatus, setUserStatus] = useState("Active");

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus("");
    setResponseMessage("");
  };

  const resetForm = () => {
    setFullname("");
    setMobileNumber("");
    setMobileNumberVerificationStatus("Verified");
    setPassword("");
    setConfirmPassword("");
    setTopupStatus("Active");
    setWithdrawStatus("Active");
    setUserStatus("Active");
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    const formData = {
      fullName,
      mobileNumber,
      mobileNumberVerificationStatus,
      password,
      confirmPassword,
      topupStatus,
      withdrawStatus,
      userStatus,
    };
    API.post("/accounts-management/users", formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          resetForm();
          setTimeout(() => {
            resetResponseData();
            window.location.href = getReturnUrl(PERMISSION_TYPES.CREATE);
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILED);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method="POST" action="#" onSubmit={onSubmit}>
          <Fieldset>
            <Label required>Full Name</Label>
            <TextInput
              value={fullName}
              onChange={setFullname}
              placeholder="Please enter full name"
              maxLength={100}
              disabled={isLoading}
            />
          </Fieldset>
          <Grid columns={"calc(50% - 10px) calc(50% - 10px)"}>
            <GridItem>
              <Fieldset>
                <Label required>Mobile Number</Label>
                <NumberInput
                  value={mobileNumber}
                  onChange={setMobileNumber}
                  placeholder="Please enter mobile number"
                  maxLength={10}
                  disabled={isLoading}
                  pattern={`[0-9]*`}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Mobile Verification Status</Label>
                <Dropdown
                  value={mobileNumberVerificationStatus}
                  onChange={setMobileNumberVerificationStatus}
                  placeholder="Please select mobile verification status"
                  options={[
                    {
                      title: "-- SELECT MOBILE VERIFICATION STATUS --",
                      value: "",
                    },
                    { title: "Pending", value: "Pending" },
                    { title: "Verified", value: "Verified" },
                  ]}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid columns={"calc(50% - 10px) calc(50% - 10px)"}>
            <GridItem>
              <Fieldset>
                <Label required>Password</Label>
                <PasswordInput
                  value={password}
                  onChange={setPassword}
                  placeholder="Please enter password"
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Confirm Password</Label>
                <PasswordInput
                  value={confirmPassword}
                  onChange={setConfirmPassword}
                  placeholder="Please re-enter password"
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid columns={"calc(50% - 10px) calc(50% - 10px)"}>
            <GridItem>
              <Fieldset>
                <Label required>Wallet Topup Status</Label>
                <Dropdown
                  placeholder="Please select wallet topup status"
                  value={topupStatus}
                  onChange={setTopupStatus}
                  options={[
                    {
                      title: "-- SELECT WALLET TOPUP STATUS --",
                      value: "",
                    },
                    {
                      title: "Active",
                      value: "Active",
                    },
                    {
                      title: "Inactive",
                      value: "Inactive",
                    },
                  ]}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Withdraw Funds Status</Label>
                <Dropdown
                  placeholder="Please select withdraw funds status"
                  value={withdrawStatus}
                  onChange={setWithdrawStatus}
                  options={[
                    {
                      title: "-- SELECT WITHDRAW FUNDS STATUS --",
                      value: "",
                    },
                    {
                      title: "Active",
                      value: "Active",
                    },
                    {
                      title: "Inactive",
                      value: "Inactive",
                    },
                  ]}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Fieldset>
            <Label required>User Status</Label>
            <Dropdown
              placeholder="Please select user status"
              value={userStatus}
              onChange={setUserStatus}
              options={[
                {
                  title: "-- SELECT USER STATUS --",
                  value: "",
                },
                {
                  title: "Active",
                  value: "Active",
                },
                {
                  title: "Inactive",
                  value: "Inactive",
                },
              ]}
              disabled={isLoading}
            />
          </Fieldset>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <CreateButton disabled={isLoading} type="submit">
              Create
            </CreateButton>
            <ResetButton disabled={isLoading} type="button" onClick={resetForm}>
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(UsersCreate);
