import React, { useState, useEffect, useCallback } from "react";
import { withAppHOC } from "../../../hoc";
import PageHeading from "../../../components/PageHeader/PageHeader";
import OverlayLoader from "../../../components/OverlayLoader/OverlayLoader";
import { Grid, GridItem } from "../../../components/Grid";
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  Dropdown,
  MessageBox,
  UpdateButton,
  ResetButton,
} from "../../../components/FormElements";
import { StyledMicroButton } from "../../../components/Styled";
import API from "../../../api";
import { API_RESPONSE_TYPES } from "../../../constants";

const SmsAPIConfiguration = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [smsEndpoint, setSmsEndpoint] = useState("");
  const [smsBody, setSmsBody] = useState("");
  const [smsRequestMethod, setSmsRequestMethod] = useState("");
  const [smsResponseType, setSmsResponseType] = useState("");
  const [smsStatusKey, setSmsStatusKey] = useState("");
  const [smsSuccessValue, setSmsSuccessValue] = useState("");
  const [smsFailedValue, setSmsFailedValue] = useState("");
  const [smsRegards, setSmsRegards] = useState("");
  const [originalFormData, setOriginalFormData] = useState({});

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus("");
    setResponseMessage("");
  };

  const resetForm = () => {
    const {
      smsEndpoint,
      smsRequestMethod,
      smsBody,
      smsResponseType,
      smsStatusKey,
      smsSuccessValue,
      smsFailedValue,
      smsRegards,
    } = originalFormData;
    setSmsEndpoint(smsEndpoint);
    setSmsRequestMethod(smsRequestMethod);
    setSmsBody(smsBody);
    setSmsResponseType(smsResponseType);
    setSmsStatusKey(smsStatusKey);
    setSmsSuccessValue(smsSuccessValue);
    setSmsFailedValue(smsFailedValue);
    setSmsRegards(smsRegards);
  };

  const readData = useCallback(() => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.get(`/configurations/sms-api`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILED) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          const {
            smsEndpoint,
            smsRequestMethod,
            smsBody,
            smsResponseType,
            smsStatusKey,
            smsSuccessValue,
            smsFailedValue,
            smsRegards,
          } = data;
          setSmsEndpoint(smsEndpoint);
          setSmsRequestMethod(smsRequestMethod);
          setSmsBody(smsBody);
          setSmsResponseType(smsResponseType);
          setSmsStatusKey(smsStatusKey);
          setSmsSuccessValue(smsSuccessValue);
          setSmsFailedValue(smsFailedValue);
          setSmsRegards(smsRegards);
          setOriginalFormData({
            smsEndpoint,
            smsRequestMethod,
            smsBody,
            smsResponseType,
            smsStatusKey,
            smsSuccessValue,
            smsFailedValue,
            smsRegards,
          });
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILED);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    const formData = {
      smsEndpoint,
      smsRequestMethod,
      smsBody,
      smsResponseType,
      smsStatusKey,
      smsSuccessValue,
      smsFailedValue,
      smsRegards,
    };
    API.put(`/configurations/sms-api`, formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setOriginalFormData(formData);
          setTimeout(() => {
            resetResponseData();
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILED);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const addText = (setMethod, value) => {
    setMethod((currentValue) => currentValue + value);
  };

  useEffect(() => {
    readData();
  }, [readData]);

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method="POST" action="#" onSubmit={onSubmit}>
          <Fieldset>
            <Label>API Endpoint</Label>
            <TextInput
              value={smsEndpoint}
              onChange={setSmsEndpoint}
              placeholder="Please enter api endpoint"
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Request Body</Label>
            <TextInput
              value={smsBody}
              onChange={setSmsBody}
              placeholder="Please enter request body"
              disabled={isLoading}
            />
            <StyledMicroButton
              disabled={isLoading}
              type="button"
              onClick={() => addText(setSmsBody, "{{{NUMBER}}}")}
            >
              Mobile Number
            </StyledMicroButton>
            <StyledMicroButton
              disabled={isLoading}
              type="button"
              onClick={() => addText(setSmsBody, "{{{MESSAGE}}}")}
            >
              Message
            </StyledMicroButton>
          </Fieldset>
          <Grid columns={"calc(50% - 10px) calc(50% - 10px)"}>
            <GridItem>
              <Fieldset>
                <Label>Request Method</Label>
                <Dropdown
                  value={smsRequestMethod}
                  onChange={setSmsRequestMethod}
                  placeholder="Please select request method"
                  disabled={isLoading}
                  options={[
                    {
                      title: "-- SELECT API REQUEST METHOD --",
                      value: "",
                    },
                    {
                      title: "POST",
                      value: "POST",
                    },
                    {
                      title: "GET",
                      value: "GET",
                    },
                  ]}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Response Type</Label>
                <Dropdown
                  value={smsResponseType}
                  onChange={setSmsResponseType}
                  placeholder="Please select response type"
                  disabled={isLoading}
                  options={[
                    {
                      title: "-- SELECT API RESPONSE TYPE --",
                      value: "",
                    },
                    {
                      title: "JSON",
                      value: "JSON",
                    },
                    {
                      title: "XML",
                      value: "XML",
                    },
                  ]}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid
            columns={
              "calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)"
            }
          >
            <GridItem>
              <Fieldset>
                <Label>Status Key</Label>
                <TextInput
                  value={smsStatusKey}
                  onChange={setSmsStatusKey}
                  placeholder="Please enter status key"
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Success Value</Label>
                <TextInput
                  value={smsSuccessValue}
                  onChange={setSmsSuccessValue}
                  placeholder="Please enter success value"
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Failure Value</Label>
                <TextInput
                  value={smsFailedValue}
                  onChange={setSmsFailedValue}
                  placeholder="Please enter failure value"
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Fieldset>
            <Label>Company Name ( Registered Firm Name )</Label>
            <TextInput
              value={smsRegards}
              onChange={setSmsRegards}
              placeholder="Please enter company name"
              disabled={isLoading}
            />
          </Fieldset>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <UpdateButton disabled={isLoading} type="submit">
              Update
            </UpdateButton>
            <ResetButton
              disabled={isLoading}
              type="button"
              onClick={resetForm}
            >
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(SmsAPIConfiguration);
