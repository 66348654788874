import React, { useState, useEffect, useCallback } from 'react';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { withAppHOC } from '../../hoc';
import PageHeading from '../../components/PageHeader/PageHeader';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import NoData from '../../components/NoData/NoData';
import { MessageBox, CustomConfirmButton } from '../../components/FormElements';
import { TableBuilder } from '../../components/TableElements';
import Pagination from '../../components/Pagination/Pagination';
import API from '../../api';
import { API_RESPONSE_TYPES } from '../../constants';
import {
  authData,
  getSearchParams,
  shouldRedirect,
  redirectToPageOne,
} from '../../utils';

const Sessions = (props) => {
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams('pageNumber') || 1,
    recordsPerPage: getSearchParams('recordsPerPage') || 10,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [sessions, setSessions] = useState([]);

  const readData = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(
      `/authentication/sessions/?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}`
    )
      .then((response) => {
        const { status, message, data, pageInfo } = response.data;
        if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
          redirectToPageOne();
        } else {
          if (status === API_RESPONSE_TYPES.FAILED) {
            setResponseStatus(status);
            setResponseMessage(message);
          } else {
            setSessions(data);
            setTotalRecords(pageInfo.totalRecords);
          }
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILED);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [paginationData]);

  const deleteData = (sessionToken, logoutAll = false) => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.delete(
      `/authentication/sessions/?sessionToken=${sessionToken}&pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}`
    )
      .then((response) => {
        const { status, message, data } = response.data;
        if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
          redirectToPageOne();
        } else {
          if (status === API_RESPONSE_TYPES.FAILED) {
            setResponseStatus(status);
            setResponseMessage(message);
          } else {
            if (!logoutAll) {
              readData();
            }
          }
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILED);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteAllData = () => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.delete(`/authentication/all-sessions`)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          authData.clear();
          window.location.href = '/login';
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILED);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const reloadData = () => {
    readData();
  };

  useEffect(() => {
    readData();
  }, [paginationData, readData]);

  return (
    <>
      <PageHeading
        {...props}
        reloadData={reloadData}
        showLogoutAllSessions={true}
        logoutAllSessions={deleteAllData}
      />
      <LoadingSpinner
        isLoading={responseStatus === '' && sessions?.length === 0 && isLoading}
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILED &&
          !isLoading &&
          sessions?.length === 0
        }
        message={`No sessions found`}
      />
      {responseStatus === API_RESPONSE_TYPES.FAILED && (
        <MessageBox status={responseStatus} message={responseMessage} />
      )}
      {sessions?.length > 0 && (
        <>
          <TableBuilder
            isLoading={sessions?.length !== 0 && isLoading}
            tableHeadings={[
              {
                title: '',
                dataSelector: 'sessionToken',
                sticky: true,
                canSort: false,
                width: '100px',
                CellRenderer: (value) => (
                  <CustomConfirmButton
                    onClick={() => deleteData(value)}
                    message='Once logged out you wont be able to perform any action with that session.'
                    title='Logout Session'
                    icon={faSignOutAlt}
                    proceedButtonText='Logout'
                  />
                ),
              },
              {
                title: 'Date & Time',
                dataSelector: 'createdOn',
                dataType: 'date',
                canSort: false,
              },
              {
                title: 'Platform',
                dataSelector: 'platform',
                dataType: 'string',
                canSort: false,
              },
              {
                title: 'Device',
                dataSelector: 'deviceType',
                dataType: 'string',
                canSort: false,
              },
              {
                title: 'Operating System',
                dataSelector: 'operatingSystem',
                dataType: 'string',
                canSort: false,
              },
            ]}
            tableData={sessions}
          />
          <Pagination
            totalRecords={totalRecords}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
          />
        </>
      )}
    </>
  );
};

export default withAppHOC(Sessions);
